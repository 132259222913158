import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Card } from 'antd';
import FormControl from '~/components/Form/FormControl';

const modules = {
  toolbar: [
    // Fontes e tamanhos
    [{ font: [] }],
    [{ size: ['small', false, 'large', 'huge'] }],

    // Cabeçalhos
    [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }, { header: false }],

    // Estilos de texto
    ['bold', 'italic', 'underline', 'strike'], // Negrito, Itálico, Sublinhado, Tachado
    [{ script: 'sub' }, { script: 'super' }], // Subscrito e Sobrescrito

    // Listas e indentação
    [{ list: 'ordered' }, { list: 'bullet' }], // Listas ordenadas e não ordenadas
    [{ indent: '-1' }, { indent: '+1' }], // Indentação

    // Alinhamento
    [{ align: [] }],

    // Cores e fundos
    [{ color: [] }, { background: [] }], // Cores do texto e fundo

    // Links, imagens e vídeos
    ['link', 'image', 'video'],

    // Limpar formatações
    ['clean'],
  ],
};

export default function DescriptionModal({ values, setFieldValue, t, fluidPermissions }) {
  const handleEditorChange = (field, content) => {
    setFieldValue(field, content);
  };

  return (
    <>
      <Card title={t('fields:event.noteInternal')} style={{ width: '100%' }}>
        <FormControl field="note_internal" required>
          <ReactQuill
            value={values.note_internal}
            onChange={(content) => handleEditorChange('note_internal', content)}
            theme="snow"
            placeholder="Observações..."
            modules={modules}
            style={{ height: '200px', marginBottom: '16px' }}
            disabled={!fluidPermissions()}
          />
        </FormControl>
      </Card>
      <Card title={t('fields:event.notePublic')} style={{ width: '100%' }}>
        <FormControl field="note_public" required>
          <ReactQuill
            value={values.note_public}
            onChange={(content) => handleEditorChange('note_public', content)}
            theme="snow"
            placeholder="Observações..."
            modules={modules}
            style={{ height: '200px', marginBottom: '16px' }}
            disabled={!fluidPermissions()}
          />
        </FormControl>
      </Card>
      <Card title={t('fields:event.noteConsigned')} style={{ width: '100%' }}>
        <FormControl field="note_consigned" required>
          <ReactQuill
            value={values.note_consigned}
            onChange={(content) => handleEditorChange('note_consigned', content)}
            theme="snow"
            placeholder="Observações..."
            modules={modules}
            style={{ height: '200px', marginBottom: '16px' }}
            disabled={!fluidPermissions()}
          />
        </FormControl>
      </Card>
    </>
  );
}
