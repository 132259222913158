import { Tabs } from 'antd';
import styled from 'styled-components';
import ColButton from '~/components/ColButton';

export const ColButtons = styled(ColButton)`
  justify-content: space-between !important;
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
  }
`;
