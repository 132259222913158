import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'financialexpenseitems';

// PAGINATION
export const getFinancialExpenseItems = async (params, route = '') => {
  try {
    const { data } = await api.get(`/${endPoint}/${route}`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

// FIND ALL
export const findAllInvoices = async (filters) => {
  try {
    const { data } = await api.post(`/${endPoint}/findAll`, filters);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE
export const createFinancialExpenseItems = async (values) => {
  try {
    const { data } = await api.post(`/${endPoint}`, values);
    message.success(i18next.t(`messages:${endPoint}.success`));
    return data;
  } catch (error) {
    throw error;
  }
};

// UPDATE
export const updateFinancialExpenseItems = async (id, values) => {
  try {
    const { data } = await api.put(`/${endPoint}/{${id}}`, values);
    message.success(i18next.t(`messages:${endPoint}.successEdit`));
    return data;
  } catch (error) {
    throw error;
  }
};

// UPDATE ALL
export const updateAllInvoice = async (values) => {
  try {
    const { data } = await api.put(`/${endPoint}/updateAll`, values);
    message.success(i18next.t(`messages:${endPoint}.successEdit`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE
export const deleteInvoice = async (id) => {
  try {
    const { data } = await api.delete(`/${endPoint}/delete/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successDelete`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// SEND INVOICE TO CLIENT
export const sendInvoice = async (id) => {
  try {
    const { data } = await api.post(`/mail/sendInvoice/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successSendInvoice`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

getFinancialExpenseItems.propTypes = {
  params: PropTypes.object.isRequired,
};

findAllInvoices.propTypes = {
  filters: PropTypes.object.isRequired,
};

createFinancialExpenseItems.propTypes = {
  values: PropTypes.object.isRequired,
};

updateFinancialExpenseItems.propTypes = {
  values: PropTypes.object.isRequired,
};

updateAllInvoice.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteInvoice.propTypes = {
  id: PropTypes.string.isRequired,
};

sendInvoice.propTypes = {
  id: PropTypes.string.isRequired,
};
