import { useEffect, useState } from 'react';

export default function IsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768); // 768px é um breakpoint comum para mobile
    };
    // Verificar no carregamento inicial
    checkIfMobile();
    // Adicionar listener para mudanças de tamanho
    window.addEventListener('resize', checkIfMobile);
    // Limpar listener ao desmontar
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return isMobile;
}
