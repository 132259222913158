import { message, Modal, Spin, Card, Button } from 'antd';
import { FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import { FieldArray, Formik } from 'formik';
import { Input } from 'formik-antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import { SelectAntd } from '~/components/Pagination/styles';
import Row from '~/components/Row';
import { getItems } from '~/services/hooks/items';
import { createBatches, updateBatches } from '~/services/hooks/batches';
import DateComponent from '~/components/DateComponent';
import InputCurrency from '~/components/Form/InputCurrency';
import { useCheckAuth } from '~/components/Can/checkAuth';

const initial = {
  description: null,
  created_by_id: null,
  batches_items: [],
};

export default function BatchesModal({ visible, onClose, batchesEdit, isMobile }) {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const [loading, setLoading] = useState(false);
  const [fetchedItems, setFetchedItems] = useState([]);
  const [initialValues, setInitialValues] = useState(initial);

  const fetchItems = async () => {
    setLoading(true);
    try {
      const response = await getItems('', 'no-paginated');
      setFetchedItems(response);
    } catch (error) {
      console.log(error);
      message.error(t('messages:items.errorGet') + error.message);
    }
    setLoading(false);
  };

  const handleSave = async (values) => {
    setLoading(true);
    try {
      if (batchesEdit) {
        await updateBatches(values.id, values);
        message.success(t('messages:batches.successPut'));
      } else {
        await createBatches(values);
        message.success(t('messages:batches.successPost'));
      }
      onClose();
    } catch (error) {
      console.log(error);
      message.error(t('messages:batches.errorSave') + error.message);
    }
    setLoading(false);
  };

  const fluidPermissions = () => {
    if (batchesEdit) {
      return authCheck('@lots/edit');
    } else {
      return authCheck('@lots/create');
    }
  };

  useEffect(() => {
    if (visible) {
      if (batchesEdit) {
        setInitialValues(batchesEdit);
      }
      fetchItems();
    }
  }, [visible]);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSave}
    >
      {({ isSubmitting, submitForm, values, setFieldValue }) => (
        <Modal
          title={t(batchesEdit ? 'screens:batches.titlePut' : 'screens:batches.titlePost') + (values.description ?? '')}
          onCancel={onClose}
          style={{ maxWidth: isMobile ? '100%' : '1500px' }}
          afterClose={() => setInitialValues(initial)}
          open={visible}
          centered
          width={isMobile ? '100%' : '1300px'}
          footer={<ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} />}
        >
          <Spin spinning={loading}>
            <Card>
              <Row style={{ marginBottom: '10px' }}>
                <FormControl cols={{ xs: 24 }} field="description" label={t('fields:batch.description')}>
                  <Input.TextArea name="description" rows={4} disabled={!fluidPermissions()} />
                </FormControl>
              </Row>

              <FieldArray
                name="batches_items"
                render={(arrayHelpers) => (
                  <div>
                    {values.batches_items.map((batchItem, index) => (
                      <Card
                        key={index}
                        style={{ marginBottom: '16px' }}
                        title={batchItem.item_name ?? ''}
                        extra={
                          <Button
                            color="danger"
                            title={t('screens:default.delete')}
                            onClick={() => arrayHelpers.remove(index)}
                            disabled={!fluidPermissions()}
                          >
                            <FaRegTimesCircle color="red" />
                          </Button>
                        }
                      >
                        <Row>
                          <FormControl
                            cols={{ xs: 24 }}
                            field={`batches_items.${index}.item_id`}
                            label={t('fields:batch.productType')}
                          >
                            <SelectAntd
                              style={{ width: '100%' }}
                              placeholder="Selecione um produto"
                              value={batchItem.item_id}
                              onSelect={(value, options) => {
                                setFieldValue(`batches_items.${index}.item_id`, value);
                                setFieldValue(`batches_items.${index}.item_name`, options.children);
                              }}
                              disabled={!fluidPermissions()}
                            >
                              <SelectAntd.Option value="">{t('fields:batch.select')}</SelectAntd.Option>
                              {Array.isArray(fetchedItems) &&
                                fetchedItems.map((item) => (
                                  <SelectAntd.Option key={item.id} value={item.id}>
                                    {item.name}
                                  </SelectAntd.Option>
                                ))}
                            </SelectAntd>
                          </FormControl>

                          <FormControl
                            cols={{ xs: 24 }}
                            field={`batches_items.${index}.input_amount`}
                            label={t('fields:batch.inputAmount')}
                          >
                            <Input
                              name={`batches_items.${index}.input_amount`}
                              type="number"
                              disabled={!fluidPermissions()}
                            />
                          </FormControl>

                          <FormControl
                            cols={{ xs: 24 }}
                            field={`batches_items.${index}.cost_price`}
                            label={t('fields:batch.costPrice')}
                          >
                            <InputCurrency name={`batches_items.${index}.cost_price`} disabled={!fluidPermissions()} />
                          </FormControl>

                          <FormControl
                            cols={{ xs: 24 }}
                            field={`batches_items.${index}.due_date`}
                            label={t('fields:batch.dueDate')}
                          >
                            <DateComponent
                              value={values.batches_items[index].due_date}
                              onChange={(date) => setFieldValue(`batches_items.${index}.due_date`, date)}
                              disabled={!fluidPermissions()}
                            />
                          </FormControl>

                          <FormControl
                            cols={{ xs: 24 }}
                            field={`batches_items.${index}.provider`}
                            label={t('fields:batch.provider')}
                          >
                            <Input name={`batches_items.${index}.provider`} disabled={!fluidPermissions()} />
                          </FormControl>
                        </Row>
                      </Card>
                    ))}
                    <Row style={{ marginTop: 16, marginLeft: 0 }}>
                      <Button
                        color="green"
                        onClick={() =>
                          arrayHelpers.push({
                            item_id: null,
                            item_name: null,
                            input_amount: null,
                            cost_price: null,
                            due_date: null,
                            provider: null,
                          })
                        }
                        disabled={!fluidPermissions()}
                      >
                        <FaPlusCircle color="green" />
                      </Button>
                    </Row>
                  </div>
                )}
              />
            </Card>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
