import { Popconfirm, Tag } from 'antd';
import React from 'react';
import { FaEye, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import Button from '~/components/Button';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import { TableActions } from '~/components/Table';
import IsMobile from '~/services/mobileCheck';

export const tableColumns = (t, handleEdit, handleDelete) => {
  const isMobile = IsMobile();

  if (isMobile) {
    return [
      {
        dataIndex: 'summary',
        key: 'summary',
        render: (text, record) => (
          <div>
            <strong>{t('screens:default.name')}:</strong> {record.name}
            <br />
            <strong>{t('screens:expenses.type')}:</strong>{' '}
            {record.expense_type === 0 ? (
              <Tag color="blue">FIXA</Tag>
            ) : record.expense_type === 1 ? (
              <Tag color="cyan">FIXA / VARIÁVEL</Tag>
            ) : (
              <Tag color="green">VARIÁVEL</Tag>
            )}
            <br />
            <TableActions align="center" justify="flex" isMobile={isMobile}>
              <Can permission="@expenses/edit">
                <Button size="small" title="Editar" onClick={() => handleEdit(record)}>
                  <FaPencilAlt />
                </Button>
              </Can>
              <Cannot permission="@xpenses/edit">
                <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record)}>
                  <FaEye />
                </Button>
              </Cannot>
              <Can permission="@xpenses/delete">
                <Popconfirm
                  title={t('messages:confirmDelete')}
                  okText={t('messages:yes')}
                  onConfirm={() => handleDelete(record.id)}
                  cancelText={t('messages:no')}
                >
                  <Button size="small" title="Excluir">
                    <FaRegTrashAlt />
                  </Button>
                </Popconfirm>
              </Can>
            </TableActions>
          </div>
        ),
      },
    ];
  }

  return [
    {
      title: t('screens:default.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('screens:expenses.type'),
      dataIndex: 'expense_type',
      key: 'expense_type',
      width: '300px',
      render: (text, record) => {
        if (record.expense_type === 0) {
          return <Tag color="blue">FIXA</Tag>;
        }
        if (record.expense_type === 1) {
          return <Tag color="cyan">FIXA / VARIÁVEL</Tag>;
        }
        return <Tag color="green">VARIÁVEL</Tag>;
      },
    },
    {
      title: t('screens:default.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (
        <TableActions align="center" justify="center">
          <Can permission="@expenses/edit">
            <Button size="small" title="Editar" onClick={() => handleEdit(record)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@xpenses/edit">
            <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record)}>
              <FaEye />
            </Button>
          </Cannot>
          <Can permission="@xpenses/delete">
            <Popconfirm
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          </Can>
        </TableActions>
      ),
    },
  ];
};

export const registerTableColumns = (t, handleEdit) => {
  return [
    {
      title: t('screens:itemExpenses.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('screens:itemExpenses.jan'),
      dataIndex: 'jan',
      key: 'jan',
      render: (text, record) => {
        return record?.montly_expenses?.Jan?.total ?? '0.00';
      },
    },
    {
      title: t('screens:itemExpenses.feb'),
      dataIndex: 'feb',
      key: 'feb',
      render: (text, record) => record?.montly_expenses?.Feb?.total ?? '0.00',
    },
    {
      title: t('screens:itemExpenses.mar'),
      dataIndex: 'mar',
      key: 'mar',
      render: (text, record) => record?.montly_expenses?.Mer?.total ?? '0.00',
    },
    {
      title: t('screens:itemExpenses.apr'),
      dataIndex: 'apr',
      key: 'apr',
      render: (text, record) => record?.montly_expenses?.Apr?.total ?? '0.00',
    },
    {
      title: t('screens:itemExpenses.may'),
      dataIndex: 'may',
      key: 'may',
      render: (text, record) => record?.montly_expenses?.May?.total ?? '0.00',
    },
    {
      title: t('screens:itemExpenses.jun'),
      dataIndex: 'jun',
      key: 'jun',
      render: (text, record) => record?.montly_expenses?.Jun?.total ?? '0.00',
    },
    {
      title: t('screens:itemExpenses.jul'),
      dataIndex: 'jul',
      key: 'jul',
      render: (text, record) => record?.montly_expenses?.Jul?.total ?? '0.00',
    },
    {
      title: t('screens:itemExpenses.aug'),
      dataIndex: 'aug',
      key: 'aug',
      render: (text, record) => record?.montly_expenses?.Aug?.total ?? '0.00',
    },
    {
      title: t('screens:itemExpenses.sep'),
      dataIndex: 'sep',
      key: 'sep',
      render: (text, record) => record?.montly_expenses?.Sep?.total ?? '0.00',
    },
    {
      title: t('screens:itemExpenses.oct'),
      dataIndex: 'oct',
      key: 'oct',
      render: (text, record) => record?.montly_expenses?.Oct?.total ?? '0.00',
    },
    {
      title: t('screens:itemExpenses.nov'),
      dataIndex: 'nov',
      key: 'nov',
      render: (text, record) => record?.montly_expenses?.Nov?.total ?? '0.00',
    },
    {
      title: t('screens:itemExpenses.dec'),
      dataIndex: 'dec',
      key: 'dec',
      render: (text, record) => record?.montly_expenses?.Nov?.total ?? '0.00',
    },
    {
      title: t('screens:itemExpenses.total'),
      dataIndex: 'total',
      key: 'total',
      render: (text, record) => record?.montly_expenses?.total ?? '0.00',
    },
    {
      title: t('screens:default.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '80px',
      align: 'center',
      render: (text, record) => (
        <TableActions align="center" justify="center">
          <Can permission="@registerExpenses/edit">
            <Button size="small" title="Editar" onClick={() => handleEdit(record.id)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@registerExpenses/edit">
            <Button size="small" title="Visualizar" onClick={() => handleEdit(record.id)}>
              <FaEye />
            </Button>
          </Cannot>
        </TableActions>
      ),
    },
  ];
};
