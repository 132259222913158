import { message, Modal, Spin, Button, Row as AntRow, Col } from 'antd';
import { Formik, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { SelectAntd } from '~/components/Pagination/styles';
import { FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import * as Yup from 'yup';
import { getFinancialExpenseItems } from '~/services/hooks/financialExpenseItems';
import { getFinancialExpenses } from '~/services/hooks/financialExpenses';
import DateComponent from '~/components/DateComponent';
import InputCurrency from '~/components/Form/InputCurrency';
import { Input } from 'formik-antd';
import {
  createFinancialExpenseItemExpenses,
  getFinancialExpenseItemExpenses,
  updateFinancialExpenseItemExpenses,
} from '~/services/hooks/financialExpenseItemExpenses';
import { useCheckAuth } from '~/components/Can/checkAuth';

const initial = {
  financial_expense_item_expenses: [],
};

export default function FinancialExpenseItemExpensesModal({
  visible,
  onClose,
  itemExpense,
  financialExId,
  financialExItemId,
  year,
  isMobile,
}) {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(initial);
  const [financialExItems, setFinancialExItems] = useState([]);
  const [financialExpenses, setFinancialExpenses] = useState([]);
  const [selectedFinancialEx, setSelectedFinancialEx] = useState(null);
  const [selectedFinancialExItem, setSelectedFinancialExItem] = useState(null);

  const fetchFinancialExpense = async () => {
    try {
      const data = await getFinancialExpenses('', 'no-paginated');
      setFinancialExpenses(data);
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao carregar as despesas. Erro: ') + error.message);
    }
  };

  const fetchFinancialExpenseItemExpenses = async () => {
    try {
      const params = {
        params: {
          financial_expense_item_id: financialExItemId,
          year,
        },
      };
      const data = await getFinancialExpenseItemExpenses(params, 'no-paginated');
      setInitialValues({ financial_expense_item_expenses: data });
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao carregar as despesas. Erro: ') + error.message);
    }
  };

  const fetchFinancialExpenseItems = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          financial_expense_id: selectedFinancialEx,
        },
      };
      const data = await getFinancialExpenseItems(params, 'no-paginated');
      setFinancialExItems(data);
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao carregar as despesas. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const saveFinancialExpense = async (values) => {
    setLoading(true);
    try {
      if (!!financialExId && !!financialExItemId) {
        values.year = year;
        await updateFinancialExpenseItemExpenses(financialExItemId, values);
      } else {
        await createFinancialExpenseItemExpenses(values);
      }
      onClose();
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao salvar a despesa. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const fluidPermissions = () => {
    if (!!financialExItemId) {
      return authCheck('@registerExpenses/edit');
    } else {
      return authCheck('@registerExpenses/create');
    }
  };

  const validationSchema = Yup.object().shape({
    financial_expense_item_expenses: Yup.array()
      .of(
        Yup.object().shape({
          spent_at: Yup.date().required('Data do gasto é obrigatória'),
          price: Yup.string().required('Valor é obrigatório'),
          description: Yup.string().nullable(),
        })
      )
      .required(),
  });

  useEffect(() => {
    if (visible) {
      if (!!financialExId && !!financialExItemId) {
        fetchFinancialExpenseItemExpenses();
      } else {
        setInitialValues(initial);
      }
      fetchFinancialExpense();
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      if (selectedFinancialEx) {
        fetchFinancialExpenseItems();
      }
      if (!!financialExId && !!financialExItemId) {
        setSelectedFinancialEx(financialExId);
        setSelectedFinancialExItem(financialExItemId);
        fetchFinancialExpenseItems();
      }
    }
  }, [visible, financialExId, financialExItemId, selectedFinancialEx]);

  return (
    <Formik
      onSubmit={saveFinancialExpense}
      validationSchema={validationSchema}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
    >
      {({ isSubmitting, submitForm, errors, values, setFieldValue }) => (
        <Modal
          title={(itemExpense ? t('screens:expenses.editTitle') : t('screens:expenses.newTitle')) + values.name}
          onCancel={onClose}
          style={{ maxWidth: isMobile ? '100%' : '700px' }}
          afterClose={() => setInitialValues(initial)}
          open={visible}
          loading={isSubmitting}
          centered
          width="1500px"
          footer={<ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} />}
        >
          <Spin spinning={loading}>
            <Row>
              <FormControl
                cols={{ xs: 12 }}
                field="financial_expense_id"
                error={errors.financial_expense_id}
                required
                label={t('fields:feie.financialEx')}
              >
                <SelectAntd
                  placeholder="SELECIONE"
                  value={!!financialExId && !!financialExItemId ? financialExId : selectedFinancialEx || null}
                  onSelect={(value) => {
                    setSelectedFinancialEx(value);
                    setFieldValue('financial_expense_item_expenses', []);
                  }}
                  loading={loading}
                  disabled={!!financialExId && !!financialExItemId}
                >
                  <SelectAntd.Option key="empty" value="" />
                  {financialExpenses?.map((item) => (
                    <SelectAntd.Option key={item.id} value={item.id}>
                      {item.name}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </FormControl>
              <FormControl
                cols={{ xs: 12 }}
                field="financial_expense_item_id"
                error={errors.financial_expense_item_id}
                required
                label={t('fields:feie.financialExItem')}
              >
                <SelectAntd
                  placeholder="SELECIONE"
                  value={!!financialExId && !!financialExItemId ? financialExItemId : selectedFinancialExItem || null}
                  onSelect={(value) => {
                    setSelectedFinancialExItem(value);
                    setFieldValue('financial_expense_item_expenses', []);
                  }}
                  loading={loading}
                  disabled={!selectedFinancialEx || (!!financialExId && !!financialExItemId)}
                >
                  <SelectAntd.Option key="empty" value="" />
                  {financialExItems?.map((item) => (
                    <SelectAntd.Option key={item.id} value={item.id}>
                      {item.name}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </FormControl>
            </Row>

            <FieldArray name="financial_expense_item_expenses">
              {({ push, remove }) => (
                <>
                  {values?.financial_expense_item_expenses?.map((financialExpenseItem, index) => (
                    <AntRow
                      key={index}
                      gutter={16}
                      align="middle"
                      style={{ border: '1px solid #d9d9d9', borderRadius: 10, padding: 20, marginBottom: 10 }}
                    >
                      <Col xs={12}>
                        <FormControl
                          cols={{ xs: 24 }}
                          field={`financial_expense_item_expenses.${index}.spent_at`}
                          error={errors?.financial_expense_items?.[index]?.spent_at}
                          required
                          label={t('fields:feie.spentAt')}
                        >
                          <DateComponent
                            value={values?.financial_expense_item_expenses?.[index]?.spent_at}
                            onChange={(date) =>
                              setFieldValue(`financial_expense_item_expenses.${index}.spent_at`, date)
                            }
                            disabled={!fluidPermissions()}
                          />
                        </FormControl>
                      </Col>
                      <Col xs={12}>
                        <FormControl
                          cols={{ xs: 24 }}
                          field={`financial_expense_item_expenses.${index}.price`}
                          error={errors?.financial_expense_items?.[index]?.price}
                          required
                          label={t('fields:feie.price')}
                        >
                          <InputCurrency
                            currency="R$"
                            name={`financial_expense_item_expenses.${index}.price`}
                            disabled={!fluidPermissions()}
                          />
                        </FormControl>
                      </Col>
                      <Col xs={20}>
                        <FormControl
                          cols={{ xs: 24 }}
                          field={`financial_expense_item_expenses.${index}.description`}
                          error={errors?.financial_expense_items?.[index]?.description}
                          required
                          label={t('fields:feie.description')}
                        >
                          <Input.TextArea
                            disabled={!fluidPermissions()}
                            name={`financial_expense_item_expenses.${index}.description`}
                          />
                        </FormControl>
                      </Col>
                      <Col xs={4}>
                        <Button
                          color="danger"
                          title="Remover item de despesa"
                          onClick={() => remove(index)}
                          style={{ marginTop: 10 }}
                          disabled={!fluidPermissions()}
                        >
                          <FaRegTimesCircle color="red" />
                        </Button>
                      </Col>
                    </AntRow>
                  ))}
                  <Row>
                    <Button
                      color="primary"
                      title="Adicionar item de despesa"
                      onClick={() =>
                        push({
                          financial_expense_item_id: selectedFinancialExItem,
                          price: '',
                          spent_at: '',
                          description: '',
                        })
                      }
                      style={{ marginTop: '16px', marginLeft: 16 }}
                      disabled={!selectedFinancialEx || !selectedFinancialExItem || !fluidPermissions()}
                    >
                      <FaPlusCircle color="green" />
                    </Button>
                  </Row>
                </>
              )}
            </FieldArray>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
