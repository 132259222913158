import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, message, Tag } from 'antd';
import { FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import Title from 'antd/es/typography/Title';
import FormControl from '~/components/Form/FormControl';
import { Input } from 'formik-antd';
import EmployeeModal from './employeeModal';
import DateComponent from '~/components/DateComponent';

export default function TeamModal({ values, setFieldValue, setLoading, t, errors, fluidPermissions, isMobile }) {
  const [employeeVisible, setEmployeeVisible] = useState(false);
  const [sectorId, setSectorId] = useState(null);
  const [sectorIndex, setSectorIndex] = useState(null);
  const [operationalId, setOperationalId] = useState(null);

  const handleEmployeeModal = (sectorId, operationalId, sectorIndex) => {
    setSectorId(sectorId);
    setSectorIndex(sectorIndex);
    setOperationalId(operationalId);
    setEmployeeVisible(true);
  };

  // const handleAddEmployee = (sectorId, operationalId) => {
  //   setLoading(true);
  //   try {
  //     values.team.push({
  //       event_id: values.id,
  //       employee_id: null,
  //       operational_id: operationalId,
  //       sector_id: sectorId,
  //       departure_time: null,
  //       extra: 0,
  //       departure_at: '',
  //     });
  //     setFieldValue(values);
  //   } catch (error) {
  //     console.log(error);
  //     message.error(`Erro ao adicionar despesa. Error: ${error}`);
  //   }
  //   setLoading(false);
  // };

  const handleRemoveEmployee = (index) => {
    setLoading(true);
    try {
      values.team.splice(index, 1);
      setFieldValue(values);
    } catch (error) {
      console.log(error);
      message.error(`Erro ao deletar funcionário. Error: ${error}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    const updatedTeam = values.team.filter((employee) =>
      values.sectors.some((sector) => sector.id === employee.sector_id)
    );

    if (updatedTeam.length !== values.team.length) {
      setFieldValue('team', updatedTeam);
      message.info('Funcionários com setor inválido foram removidos.');
    }
  }, [values.sectors, values.team, setFieldValue]);

  return (
    <div>
      {values?.sectors?.map((sector, sectorIndex) => (
        <Card key={sectorIndex} title={`Setor: ${sector.name}`} style={{ marginBottom: 16 }}>
          {sector?.sector_operationals?.map((sectorOperational, sectorOperationalIndex) => {
            const assignedEmployees =
              values.team?.filter((employee) =>
                sector.id
                  ? employee.sector_id === sector.id && employee.operational_id === sectorOperational.operationals.id
                  : employee.sector_index === sectorIndex &&
                    employee.operational_id === sectorOperational.operationals.id
              ).length || 0;

            const remainingEmployees = sectorOperational.amount - assignedEmployees;

            return (
              <div key={sectorOperationalIndex}>
                <Row
                  gutter={[16, 16]}
                  align="middle"
                  justify="space-between"
                  style={{ flexWrap: 'wrap', marginBottom: 10 }}
                >
                  <Col xs={24} md={16}>
                    <Row gutter={[8, 8]} align="middle">
                      <Col xs={24} md={12}>
                        <Title level={4}>{sectorOperational.operationals.name}</Title>
                      </Col>
                      <Col xs={12} md={6}>
                        <Tag color={remainingEmployees === 0 ? 'green' : 'red'}>
                          {remainingEmployees === 0 ? (
                            <span>
                              <strong>Todos</strong> funcionários foram designados
                            </span>
                          ) : (
                            <span>
                              Funcionários restantes: <strong>{remainingEmployees}</strong>
                            </span>
                          )}
                        </Tag>
                      </Col>
                      <Col xs={12} md={6}>
                        <Tag>
                          Valor da paga: <strong>{sectorOperational.price_unit}</strong>
                        </Tag>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24} md={4} style={{ textAlign: 'right' }}>
                    <Button
                      type="primary"
                      block
                      onClick={() => handleEmployeeModal(sector.id, sectorOperational.operationals.id, sectorIndex)}
                      disabled={remainingEmployees <= 0 || !fluidPermissions()}
                    >
                      <FaPlusCircle /> Adicionar Funcionário
                    </Button>
                  </Col>
                </Row>

                {values.team?.map(
                  (employee, employeeIndex) =>
                    (sector.id ? employee.sector_id === sector.id : employee.sector_index === sectorIndex) &&
                    employee.operational_id === sectorOperational.operationals.id && (
                      <Row
                        key={employeeIndex}
                        gutter={[8, 8]}
                        align="middle"
                        style={{
                          background: '#f5f5f5',
                          padding: '10px',
                          borderRadius: '5px',
                          marginBottom: 8,
                        }}
                      >
                        <Col xs={24} md={10}>
                          <FormControl
                            label={t('Nome')}
                            field={`team.${employeeIndex}.employee_name`}
                            error={errors?.team?.[employeeIndex]?.employee_name}
                            required
                          >
                            <Input name={`team.${employeeIndex}.employee_name`} disabled />
                          </FormControl>
                        </Col>

                        <Col xs={12} md={8}>
                          <FormControl
                            label={t('Horário de saída')}
                            field={`team.${employeeIndex}.departure_at`}
                            error={errors?.team?.[employeeIndex]?.departure_at}
                            required
                          >
                            <DateComponent
                              value={employee.departure_at ?? null}
                              showTime
                              onChange={(date) => {
                                setFieldValue(`team.${employeeIndex}.departure_at`, date);
                              }}
                              disabled={!fluidPermissions()}
                            />
                          </FormControl>
                        </Col>

                        <Col xs={12} md={6} style={{ textAlign: 'right' }}>
                          <Button
                            type="danger"
                            onClick={() => handleRemoveEmployee(employeeIndex)}
                            disabled={!fluidPermissions()}
                          >
                            <FaRegTimesCircle />
                          </Button>
                        </Col>
                      </Row>
                    )
                )}
              </div>
            );
          })}
        </Card>
      ))}

      <EmployeeModal
        visible={employeeVisible}
        onClose={() => setEmployeeVisible(false)}
        t={t}
        values={values}
        sectorId={sectorId}
        sectorIndex={sectorIndex}
        operationalId={operationalId}
        setFieldValue={setFieldValue}
        fluidPermissions={fluidPermissions}
        isMobile={isMobile}
      />
    </div>
  );
}
