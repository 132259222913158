import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { Col, Input, message, Select } from 'antd';
import Button from '~/components/Button';
import Box from '~/components/Box';
import { TableHeader, Table } from '~/components/Table';
import errorHandler from '~/Utils/errorHandler';
import Pagination from '~/components/Pagination';
import RoleForm from './form';
import PermissionForm from './permissions';
import { deleteRoles, getRoles } from '~/services/hooks/roles';
import { tableColumns } from './table';
import IsMobile from '~/services/mobileCheck';

export default function Roles() {
  const { t } = useTranslation();
  const isMobile = IsMobile();
  const [loading, setLoading] = useState(false);
  const [showRoleForm, setShowRoleForm] = useState(false);
  const [showPermissionForm, setShowPermissionForm] = useState(false);
  const [recordList, setRecordList] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});

  const fetchRecords = async (page) => {
    setLoading(true);
    try {
      const params = {
        params: {
          search: searchTerm,
          page,
          perPage,
        },
      };
      const { data } = await getRoles(params, '');
      setRecordList(data);
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleRole = (role = false) => {
    if (role) {
      setSelectedRecord(role);
      setShowRoleForm(true);
    } else {
      setShowRoleForm(true);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await deleteRoles(id);
      fetchRecords();
      message.success(t('messages:success'));
    } catch (error) {
      console.log(error);
      error.message(`Não foi possível deletar o Grupo de Usuários. Erro: ${error.message}`);
    }
    setLoading(false);
  };

  const handlePermissions = (record) => {
    setSelectedRecord(record);
    setShowPermissionForm(true);
  };

  useEffect(() => {
    fetchRecords(page);
  }, [page, searchTerm]);

  useEffect(() => {
    if (!showRoleForm && !showPermissionForm) {
      fetchRecords(page);
      setSelectedRecord(null);
    }
  }, [showRoleForm, showPermissionForm]);

  return (
    <Box>
      <Col
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', marginRight: '5px', marginBottom: 40 }}
      >
        <Button color="primary" onClick={handleRole}>
          <FaPlus />
          {t('Adicionar grupo')}
        </Button>
      </Col>
      <TableHeader>
        <Input.Search onSearch={(value) => setSearchTerm(value)} className="search-field" />
      </TableHeader>
      <Table
        rowKey="id"
        loading={loading}
        dataSource={recordList}
        columns={tableColumns(t, handleDelete, handleRole, handlePermissions, isMobile)}
        pagination={false}
      />
      <Pagination
        isMobile={isMobile}
        onChange={(value) => {
          setPage(value);
        }}
        meta={meta}
      />
      <Select
        name="number"
        onChange={(value) => {
          setPerPage(value);
        }}
        value={perPage}
        style={{ width: '70px' }}
      >
        <Select.Option value={10}>10</Select.Option>
        <Select.Option value={20}>20</Select.Option>
        <Select.Option value={30}>30</Select.Option>
        <Select.Option value={40}>40</Select.Option>
        <Select.Option value={50}>50</Select.Option>
        <Select.Option value={100}>100</Select.Option>
      </Select>
      <RoleForm visible={showRoleForm} role={selectedRecord} onClose={() => setShowRoleForm(false)} />
      <PermissionForm
        visible={showPermissionForm}
        roleID={selectedRecord?.id}
        roleName={selectedRecord?.name}
        rolePermissions={selectedRecord ? selectedRecord.permissions : null}
        onClose={() => setShowPermissionForm(false)}
      />
    </Box>
  );
}
