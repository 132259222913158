import React, { useEffect, useState } from 'react';
import Box from '~/components/Box';
import { Table, TableHeader } from '~/components/Table';
import { Col, Input, message } from 'antd';
import Pagination from '~/components/Pagination';
import Global from '~/styles/global';
import { tableColumns } from './table';
import { useTranslation } from 'react-i18next';
import Button from '~/components/Button';
import BatchesModal from './form';
import { deleteBatches, getBatches } from '~/services/hooks/batches';
import { useCheckAuth } from '~/components/Can/checkAuth';
import ColButton from '~/components/ColButton';
import IsMobile from '~/services/mobileCheck';

export default function Batches() {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const isMobile = IsMobile();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState({});
  const [search, setSearch] = useState('');
  const [ModalVisible, setModalVisible] = useState(false);
  const [batchesEdit, setBatchesEdit] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          page,
          perPage,
          search,
        },
      };
      const { data, meta } = await getBatches(params);
      setData(data);
      setMeta(meta);
    } catch (error) {
      console.log(error);
      message.error(t('messages:batches.errorGet') + error.message);
    }
    setLoading(false);
  };

  const handleEdit = (record) => {
    setBatchesEdit(record);
    setModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteBatches(id);
      message.success(t('messages:batches.successDel'));
      fetchData();
    } catch (error) {
      console.log(error);
      message.error(t('messages:batches.errorDel') + error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, search]);

  return (
    <Box>
      <ColButton>
        <Button color="primary" onClick={() => handleEdit(null)} disabled={!authCheck('@lots/create')}>
          {t('screens:batches.addBatch')}
        </Button>
      </ColButton>
      <TableHeader>
        <Col style={{ display: 'flex' }}>
          <Input.Search
            onSearch={(value) => {
              setSearch(value);
              setPage(1);
            }}
            className="search-field"
            placeholder={t('screens:default.search')}
          />
        </Col>
      </TableHeader>
      <Table
        pagination={false}
        rowKey="id"
        loading={loading}
        dataSource={data}
        columns={tableColumns(handleDelete, handleEdit, isMobile)}
        bordered
      />
      <Pagination
        isMobile={isMobile}
        onChange={(value) => {
          setPage(value);
        }}
        meta={meta}
        perPage={perPage}
        setPerPage={setPerPage}
      />
      <Global />
      <BatchesModal
        visible={ModalVisible}
        batchesEdit={batchesEdit}
        isMobile={isMobile}
        onClose={() => {
          setModalVisible(false);
          setBatchesEdit(null);
          fetchData();
        }}
      />
    </Box>
  );
}
