import styled from 'styled-components';
import { Pagination, Select } from 'antd';
import { Select as SelecFormik } from 'formik-antd';

export const Container = styled(Pagination)`
  position: relative;
  display: flex;
  float: none;
  justify-content: flex-end;
  margin-top: 15px;

  @media (max-width: 767px) {
    justify-content: center;
    margin: 30px 0 15px 0;
    .ant-pagination-total-text {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      top: -30px;
    }

    .ant-pagination {
      margin-left: 20px;
      font-size: 16px;
    }
  }

  .ant-pagination-item-link {
    svg {
      color: #2e394b !important;
    }

    &:hover {
      background-color: rgba(255, 187, 0, 0.1) !important;
    }
  }

  .ant-pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-pagination-item-active {
    border-color: rgba(46, 57, 75, 0.67) !important;

    &:hover {
      background-color: rgba(255, 187, 0, 0.1) !important;
    }
  }

  .ant-pagination-item:hover {
    border-color: rgba(46, 57, 75, 0.67) !important;
    background-color: rgba(255, 187, 0, 0.1) !important;

    a {
      color: rgba(46, 57, 75, 0.67) !important;
    }
  }

  .ant-pagination-item-active a {
    color: rgba(46, 57, 75, 0.67) !important; /* Para alterar também a cor do número */
  }

  .ant-select-selector {
    border-color: #2e394b !important;
  }

  .ant-pagination-total-text {
    position: absolute;
    left: 0;
    font-weight: bold;
  }
`;

export const SelectAntd = styled(Select)`
  .ant-select-selector {
    border-color: initial;

    &:hover,
    &:focus,
    &:focus-within {
      border-color: #2e394b !important;
    }
  }
`;

export const SelectAntdFormik = styled(SelecFormik)`
  .ant-select-selector {
    border-color: initial;

    &:hover,
    &:focus,
    &:focus-within {
      border-color: #2e394b !important;
    }
  }
`;
