import { takeLatest, call, put, all } from 'redux-saga/effects';
import { message } from 'antd';
import i18next from 'i18next';
import { signInSuccess, signFailure, signOutSuccess } from './actions';
// import { updateProfile } from 'store/modules/user/actions';
import api from 'services/api';
import { navigate } from '~/services/navigate';
import { getFirstAllowedRoute } from '~/routes/routesWithPermissions';

export function* signIn({ payload }) {
  try {
    var data = {
      email: payload.username,
      password: payload.password,
    };

    var client_id = 'client';
    var client_secret = '123';
    var basicAuth = 'Basic ' + btoa(client_id + ':' + client_secret);

    const response = yield call(api.post, '/auth', data, { headers: { Authorization: basicAuth } });

    const { token, user, version, permissions, roles } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user, version, permissions, roles));

    navigate(getFirstAllowedRoute(permissions));
  } catch (error) {
    console.log(error);
    message.error(i18next.t('messages:authError') + error.message);
    yield put(signFailure());
  }
}

export function* signOut() {
  try {
    yield call(api.post, 'auth/logout');
  } catch (err) {}

  yield put(signOutSuccess());
  navigate('/');
}

export function* tokenExpired() {
  try {
    yield call(api.post, 'auth/logout');
  } catch (err) {}

  navigate('/');
  message.success('Sua sessão expirou! Faça o login novamente.');
}

function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT_REQUEST', signOut),
  takeLatest('@auth/TOKEN_EXPIRED', tokenExpired),
]);
