import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';

import { Footer } from './styles';
import { Space } from 'antd';

export function ModalFooter({ loading, onCancel, onOk, okText, cancelText, cancelColor, okColor, showOk }) {
  return (
    <Footer>
      <Space>
        <Button loading={loading} color={cancelColor} onClick={onCancel}>
          {cancelText}
        </Button>
        {showOk ? (
          <Button loading={loading} color={okColor} onClick={onOk}>
            {okText}
          </Button>
        ) : null}
      </Space>
    </Footer>
  );
}

ModalFooter.propTypes = {
  loading: PropTypes.bool,
  onCancel: PropTypes.oneOfType([PropTypes.func]),
  onOK: PropTypes.oneOf([PropTypes.func]),
  cancelText: PropTypes.oneOfType([PropTypes.any]),
  okText: PropTypes.oneOfType([PropTypes.any]),
  cancelColor: PropTypes.string,
  okColor: PropTypes.string,
  showOk: PropTypes.bool,
};

ModalFooter.defaultProps = {
  loading: false,
  cancelText: 'Cancelar',
  okText: 'Salvar',
  cancelColor: 'clear',
  okColor: 'primary',
  showOk: true,
};
