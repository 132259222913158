import { message, Modal, Spin, Tabs } from 'antd';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalFooter } from '~/components/Modal';
import { getCustomers } from '~/services/hooks/customers';
import { createEvents, updateEvents } from '~/services/hooks/events';
import { getUsers } from '~/services/hooks/users';
import errorHandler from '~/Utils/errorHandler';
import { getStates } from '~/services/hooks/states';
import axios from 'axios';
import * as Yup from 'yup';
import { useCheckAuth } from '~/components/Can/checkAuth';
import { EventModal } from './eventModal';
import { OperationalModal } from './operationalModal';
import ServiceModal from './serviceModal';
import ExpenseModal from './expenseModal';
import DescriptionModal from './descriptionModal';
import TeamModal from './teamsModal';
import { ConsignedsModal } from './consignedsModal';

const initial = {
  name: '',
  starts_at: '',
  ends_at: '',
  adress_street: '',
  adress_number: '',
  adress_cep: '',
  adress_reference: '',
  adress_complement: '',
  estimated_people: '',
  status: null,
  note_internal: '',
  note_public: '',
  description: '',
  city_id: null,
  sectors: [],
  services_events: [],
  event_expenses: [],
  team: [],
  consigneds: [],
  protocol: '',
  note_consigned: '',
  price_discount: '',
  created_by_id: null,
  updated_by_id: null,
  customer_id: null,
  note_after_event: '',
  seller_id: null,
  payment_status: null,
  stock_write_offs: null,
};

export default function FormEvents({ visible, event, onClose, isMobile }) {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(initial);
  const [customers, setCustomers] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [state, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState({});
  const [cityEnable, setCityEnable] = useState(event?.city ? true : false);

  const fetchEvent = async () => {
    setLoading(true);
    try {
      if (event) {
        setInitialValues(event);

        if (initialValues?.city && initialValues.city.id) {
          const cityFromBackend = state.flatMap((s) => s.cities).find((c) => c.id === values.city.id);

          if (cityFromBackend) {
            const stateForCity = state.find((s) => s.id === cityFromBackend.state_id);
            setSelectedState(stateForCity);
            setCityEnable(true);
            setFieldValue('city_id', cityFromBackend.id);
          }
        }
      } else {
        setInitialValues(initial);
      }
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchAll = async () => {
    setLoading(true);
    try {
      const dataCustomers = await getCustomers('', 'no-paginated');
      const dataSellers = await getUsers('', 'no-paginated');
      const dataStates = await getStates('', 'no-paginated');
      setCustomers(dataCustomers);
      setSellers(dataSellers);
      setStates(dataStates);
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchAddress = async (cep, values) => {
    setLoading(true);
    try {
      const { data } = await axios.get(`http://viacep.com.br/ws/${cep}/json/`);

      setInitialValues(() => {
        // Atualiza somente os campos vazios
        const updatedValues = { ...values };
        updatedValues.adress_cep = cep;

        if (!values.neighborhood || values.neighborhood === '') {
          updatedValues.neighborhood = data.bairro;
        }

        if (!values.adress_street || values.adress_street === '') {
          updatedValues.adress_street = data.logradouro;
        }

        if (!values.adress_number || values.adress_number === '') {
          updatedValues.adress_number = data.numero || ''; // Se 'numero' não existir na API, define como vazio
        }

        if (!values.city_id || values.city_id === null) {
          const stateMatch = state.find((s) => s.acronym === data.uf);
          if (stateMatch) {
            setSelectedState(stateMatch);
            const cityMatch = stateMatch.cities.find(
              (city) => city.name.toLowerCase() === data.localidade.toLowerCase()
            );
            if (cityMatch) {
              updatedValues.city_id = cityMatch.id;
              setCityEnable(true);
            }
          }
        }

        return updatedValues;
      });
    } catch (error) {
      message.error(`Não foi possível resgatar endereço pelo CEP. Error: ${error}`);
    }
    setLoading(false);
  };

  const saveEvent = async (values) => {
    setLoading(true);
    try {
      const sectors = values.sectors || [];
      let totalOperationals = 0;

      sectors.forEach((sector) => {
        if (sector.sector_operationals) {
          sector.sector_operationals.forEach((sector_operational) => {
            totalOperationals += sector_operational.amount || 0;
          });
        }
      });

      const teamCount = values.team.length;
      if (teamCount < totalOperationals) {
        message.error('Todos os funcionários devem ser preenchidos');
        return;
      }

      if (event) {
        await updateEvents(event.id, values);
      } else {
        await createEvents(values);
      }
      message.success('Evento salvo com sucesso!');
      onClose();
    } catch (error) {
      console.log(error);
      message.error('Não foi possível salvar o evento. Erro: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const fluidPermissions = () => {
    if (event) {
      return authCheck('@events/edit');
    } else {
      return authCheck('@events/create');
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    starts_at: Yup.date().required('Data de início é obrigatória'),
    ends_at: Yup.date().required('Data de término é obrigatória'),
    adress_cep: Yup.string().required('CEP é obrigatório'),
    estimated_people: Yup.number()
      .required('Número estimado de pessoas é obrigatório')
      .positive('Deve ser um número positivo'),
    status: Yup.string().required('Status é obrigatório'),
    city_id: Yup.number(),
    sectors: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Nome do setor é obrigatório'),
        sector_operationals: Yup.array()
          .of(
            Yup.object().shape({
              operationals: Yup.object()
                .shape({
                  id: Yup.number()
                    .required('O Operacional é obrigatório')
                    .typeError('O Operacional deve ser um número'),
                })
                .required('O operacional é obrigatório'),
              amount: Yup.number()
                .nullable()
                .required('Quantidade é obrigatória')
                .positive('Quantidade deve ser um número positivo'),
              price_unit: Yup.string().required('Preço unitário é obrigatório'),
              price_total: Yup.string().required('Preço total é obrigatório'),
            })
          )
          .nullable(),
      })
    ),
    services_events: Yup.array()
      .of(
        Yup.object().shape({
          service: Yup.object()
            .shape({
              id: Yup.number().required('O serviço é obrigatório').typeError('O ID do serviço deve ser um número'),
            })
            .required('O serviço é obrigatório')
            .typeError('O serviço deve ser um objeto'),
          amount: Yup.number().required('Quantidade é obrigatória').positive('Quantidade deve ser um número positivo'),
          price_unit: Yup.string().required('Preço unitário é obrigatório'),
        })
      )
      .nullable(),
    event_expenses: Yup.array()
      .of(
        Yup.object().shape({
          expense: Yup.object()
            .shape({
              id: Yup.number().required('A despesa é obrigatória').typeError('O ID da despesa deve ser um número'),
            })
            .required('A despesa é obrigatória')
            .typeError('A despesa deve ser um objeto'),
          amount: Yup.number().required('Quantidade é obrigatória').positive('Quantidade deve ser um número positivo'),
          price_unit: Yup.string().required('Preço unitário é obrigatório'),
        })
      )
      .nullable(),
    team: Yup.array().of(
      Yup.object().shape({
        departure_at: Yup.string().required('Hora de saída é obrigatória'),
      })
    ),
    customer_id: Yup.number().required('Cliente é obrigatório'),
    seller_id: Yup.number().required('Vendedor é obrigatório'),
    payment_status: Yup.string().required('Status de pagamento é obrigatório'),
  });

  useEffect(() => {
    if (visible) {
      fetchEvent();
      fetchAll();
    }
  }, [visible, event]);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      // validate={validateForm}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={saveEvent}
    >
      {({ errors, isSubmitting, submitForm, resetForm, setFieldValue, values }) => (
        <Modal
          title={
            event
              ? t('screens:events.modal.editTitle') + ' - ' + values.name
              : t('screens:events.modal.newTitle') + ' - ' + values.name
          }
          onCancel={onClose}
          style={{ maxWidth: isMobile ? '100%' : '1700px' }}
          afterClose={() => {
            resetForm();
            setInitialValues(initial);
            setCustomers([]);
            setSellers([]);
            setStates([]);
            setSelectedState({});
          }}
          open={visible}
          centered
          width={isMobile ? '100%' : '1700px'}
          footer={<ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} />}
        >
          <Spin spinning={loading}>
            <Tabs
              defaultActiveKey="1"
              size="large"
              // style={{
              //   marginBottom: 32,
              // }}
              items={[
                {
                  label: 'Geral',
                  key: 'Event',
                  children: (
                    <EventModal
                      t={t}
                      event={event}
                      loading={loading}
                      customers={customers}
                      values={values}
                      setFieldValue={setFieldValue}
                      fetchAddress={fetchAddress}
                      state={state}
                      selectedState={selectedState}
                      setSelectedState={setSelectedState}
                      setCityEnable={setCityEnable}
                      cityEnable={cityEnable}
                      sellers={sellers}
                      errors={errors}
                      fluidPermissions={fluidPermissions}
                      isMobile={isMobile}
                    />
                  ),
                },
                {
                  label: 'Operacional/Setores',
                  key: 'operational',
                  children: (
                    <OperationalModal
                      t={t}
                      values={values}
                      setFieldValue={setFieldValue}
                      setLoading={setLoading}
                      loading={loading}
                      visible={visible}
                      errors={errors}
                      fluidPermissions={fluidPermissions}
                    />
                  ),
                },
                {
                  label: 'Serviços',
                  key: 'services',
                  children: (
                    <ServiceModal
                      t={t}
                      values={values}
                      setFieldValue={setFieldValue}
                      setLoading={setLoading}
                      loading={loading}
                      visible={visible}
                      errors={errors}
                      fluidPermissions={fluidPermissions}
                    />
                  ),
                },
                {
                  label: 'Outras despesas',
                  key: 'expenses',
                  children: (
                    <ExpenseModal
                      t={t}
                      values={values}
                      setFieldValue={setFieldValue}
                      setLoading={setLoading}
                      loading={loading}
                      visible={visible}
                      errors={errors}
                      fluidPermissions={fluidPermissions}
                    />
                  ),
                },
                {
                  label: 'Observações',
                  key: 'descriptions',
                  children: (
                    <DescriptionModal
                      t={t}
                      values={values}
                      setFieldValue={setFieldValue}
                      setLoading={setLoading}
                      loading={loading}
                      visible={visible}
                      errors={errors}
                      fluidPermissions={fluidPermissions}
                    />
                  ),
                },
                {
                  label: 'Equipe',
                  key: 'team',
                  children: (
                    <TeamModal
                      t={t}
                      values={values}
                      setFieldValue={setFieldValue}
                      setLoading={setLoading}
                      loading={loading}
                      visible={visible}
                      errors={errors}
                      fluidPermissions={fluidPermissions}
                      isMobile={isMobile}
                    />
                  ),
                },
                {
                  label: 'Items',
                  key: 'items',
                  children: (
                    <ConsignedsModal
                      t={t}
                      values={values}
                      setFieldValue={setFieldValue}
                      setLoading={setLoading}
                      loading={loading}
                      visible={visible}
                      errors={errors}
                      fluidPermissions={fluidPermissions}
                      isMobile={isMobile}
                    />
                  ),
                },
              ]}
            />
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
