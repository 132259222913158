import React, { useEffect, useState } from 'react';
import { SelectAntd } from '~/components/Pagination/styles';
import Row from '~/components/Row';
import { getExpenses } from '~/services/hooks/expenses';
import { InputNumber } from 'formik-antd';
import InputCurrency from '~/components/Form/InputCurrency';
import { Button, Col } from 'antd';
import { FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import FormControl from '~/components/Form/FormControl';

export default function ExpenseModal({
  visible,
  values,
  setFieldValue,
  loading,
  setLoading,
  t,
  errors,
  fluidPermissions,
}) {
  const [expenses, setExpenses] = useState([]);

  const fetchExpenses = async () => {
    setLoading(true);
    try {
      const expenses = await getExpenses('', 'no-paginated');
      setExpenses(expenses);
    } catch (error) {
      console.log(error);
      message.error(`Fala ao carregar despesas.
        Erro: ${error}`);
    }
    setLoading(false);
  };

  const handleAddExpense = () => {
    setLoading(true);
    try {
      values.event_expenses.push({
        event_id: values.id,
        amount: 0,
        price_unit: 0,
        price_total: 0,
        expense: { id: null },
      });
      setFieldValue(values);
    } catch (error) {
      console.log(error);
      message.error(`Erro ao adicionar despesa. Error: ${error}`);
    }
    setLoading(false);
  };

  const handleRemoveExpense = (index) => {
    setLoading(true);
    try {
      values.event_expenses.splice(index, 1);
      setFieldValue(values);
    } catch (error) {
      console.log(error);
      message.error(`Erro ao deletar despesa. Error: ${error}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchExpenses();
    }
  }, [visible]);

  return (
    <>
      {values?.event_expenses?.map((event_expense, eventExpenseIndex) => (
        <>
          <Row
            key={eventExpenseIndex}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}
          >
            <FormControl
              label={t('Nome')}
              field={`event_expenses.${eventExpenseIndex}.expense.id`}
              error={errors?.event_expenses?.[eventExpenseIndex]?.expense?.id}
              required
              cols={{ sm: 6, xs: 24 }}
            >
              {/* <Button onClick={() => console.log(event_expense)}>Log</Button> */}
              <SelectAntd
                placeholder="SELECIONE"
                value={event_expense?.expense?.id ?? ''}
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                onSelect={(value) => {
                  setFieldValue(`event_expenses.${eventExpenseIndex}.expense.id`, value);
                }}
                loading={loading}
                disabled={!fluidPermissions()}
              >
                {expenses?.map((item) => (
                  <SelectAntd.Option key={item.id} value={item.id}>
                    {item.name}
                  </SelectAntd.Option>
                ))}
              </SelectAntd>
            </FormControl>
            <FormControl
              label={t('Quantidade')}
              field={`event_expenses.${eventExpenseIndex}.amount`}
              error={errors?.event_expenses?.[eventExpenseIndex]?.amount}
              required
              cols={{ sm: 6, xs: 8 }}
            >
              <InputNumber name={`event_expenses.${eventExpenseIndex}.amount`} disabled={!fluidPermissions()} />
            </FormControl>
            <FormControl
              label={t('Valor')}
              field={`event_expenses.${eventExpenseIndex}.price_unit`}
              error={errors?.event_expenses?.[eventExpenseIndex]?.price_unit}
              required
              cols={{ sm: 6, xs: 12 }}
            >
              <InputCurrency
                currency="R$"
                name={`event_expenses.${eventExpenseIndex}.price_unit`}
                disabled={!fluidPermissions()}
              />
            </FormControl>
            <Button
              color="danger"
              title="Remover operacional"
              onClick={() => handleRemoveExpense(eventExpenseIndex)}
              style={{ marginTop: 10 }}
              disabled={!fluidPermissions()}
            >
              <FaRegTimesCircle color="red" />
            </Button>
          </Row>
          <Col style={{ backgroundColor: '#b8b8b8', marginTop: 10, marginBottom: 20, width: '90%' }} />
        </>
      ))}
      <Row>
        <Col span={21}>
          <Button color="primary" onClick={() => handleAddExpense()} disabled={!fluidPermissions()}>
            <FaPlusCircle color="green" />
          </Button>
        </Col>
      </Row>
    </>
  );
}
