import * as yup from 'yup';
import i18next from 'i18next';

const translation = {
  mixed: {
    default: () => `${i18next.t('fields:invalidField')}`,
    required: () => `${i18next.t('fields:requiredField')}`,
    oneOf: ({ path, values }) =>
      `${i18next.t(`fields:${path}`)} ${i18next.t('fields:mustBeOneOfTheFollowingValues')}: ${values}`,
    notOneOf: ({ path, values }) =>
      `${i18next.t(`fields:${path}`)} ${i18next.t('fields:cannotBeOneOfTheFollowingValues')}: ${values}`,
    notType: ({ path, type }) =>
      `${i18next.t(`fields:${path}`)} ${i18next.t('fields:mustBeTheType')} ${i18next.t(`fields:types.${type}`)}`,
  },
  string: {
    length: ({ path, length }) =>
      `${i18next.t(`fields:${path}`)} ${i18next.t('fields:mustHaveExactly')} ${length} ${i18next.t('fields:characters')}`,
    min: ({ path, min }) =>
      `${i18next.t(`fields:${path}`)} ${i18next.t('fields:mustHaveAtLeast')} ${min} ${i18next.t('fields:characters')}`,
    max: ({ path, max }) =>
      `${i18next.t(`fields:${path}`)} ${i18next.t('fields:mustHaveAtMost')} ${max} ${i18next.t('fields:characters')}`,
    email: () => `${i18next.t('fields:invalidEmailFormat')}`,
    url: ({ path }) => `${i18next.t(`fields:${path}`)} ${i18next.t('fields:mustHaveAValidURLFormat')}`,
    trim: ({ path }) =>
      `${i18next.t(`fields:${path}`)} ${i18next.t('fields:mustNotContainSpacesAtTheBeginningOrAtTheEnd')}.`,
    lowercase: ({ path }) => `${i18next.t(`fields:${path}`)} ${i18next.t('fields:mustBeCapitalized')}`,
    uppercase: ({ path }) => `${i18next.t(`fields:${path}`)} ${i18next.t('fields:mustBeInLowercase')}`,
  },
  number: {
    min: ({ path, min }) => `${i18next.t(`fields:${path}`)} ${i18next.t('fields:mustBeAtLeast')} ${min}`,
    max: ({ path, max }) => `${i18next.t(`fields:${path}`)} ${i18next.t('fields:mustBeAtMost')} ${max}`,
    lessThan: ({ path, less }) => `${i18next.t(`fields:${path}`)} ${i18next.t('fields:mustBeLessThan')} ${less}`,
    moreThan: ({ path, more }) => `${i18next.t(`fields:${path}`)} ${i18next.t('fields:mustBeGreaterThan')} ${more}`,
    notEqual: ({ path, notEqual }) =>
      `${i18next.t(`fields:${path}`)} ${i18next.t('fields:cannotBeEqualTo')} ${notEqual}`,
    positive: ({ path }) => `${i18next.t(`fields:${path}`)} ${i18next.t('mustBeAPositiveNumber')}`,
    negative: ({ path }) => `${i18next.t(`fields:${path}`)} ${i18next.t('mustBeANegativeNumber')}`,
    integer: ({ path }) => `${i18next.t(`fields:${path}`)} ${i18next.t('mustBeAnInteger')}`,
  },
  date: {
    min: ({ path, min }) => `${i18next.t(`fields:${path}`)} ${i18next.t('mustBeGreaterThanTheDate')} ${min}`,
    // max: ({ path, max }) => `${i18next.t(`fields:${path}`)} deve ser menor que a data ${max}`,
    max: () => `${i18next.t('theReleaseDateCannotBeLaterThanTheCurrentDate')}`,
  },
  array: {
    min: ({ path, min }) =>
      `${i18next.t(`fields:${path}`)} ${i18next.t('fields:mustHaveAtLeast')} ${min} ${i18next.t('fields:items')}`,
    max: ({ path, max }) =>
      `${i18next.t(`fields:${path}`)} ${i18next.t('fields:mustHaveAtMost')} ${max} ${i18next.t('fields:items')}`,
  },
};

yup.setLocale(translation);

export default yup;
