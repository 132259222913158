import React, { useEffect, useState } from 'react';
import { Modal, Spin, message } from 'antd';
import { Formik } from 'formik';
import { Form, Switch } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Table, TableActions } from '~/components/Table';
import { ModalFooter } from '~/components/Modal';
import { getRoles } from '~/services/hooks/roles';
import { updateRolesUsers } from '~/services/hooks/rolesUsers';

export default function UserRoles({ visible, onClose, userData, isMobile }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [recordList, setRecordList] = useState([]);
  const [rolesList, setRolesList] = useState({});

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = { params: { all: true } };
      const data = await getRoles(params, '');
      setRecordList(data);
    } catch (error) {
      message.error(`Não foi possível buscar os grupos de usuário. Erro: ${error.message}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) fetchData();
    else setRolesList({});
  }, [visible]);

  useEffect(() => {
    if (!recordList.length || !userData?.roles) return;

    const roles = recordList.reduce((acc, item) => {
      acc[item.slug] = userData.roles.includes(item.slug);
      return acc;
    }, {});

    setRolesList(roles);
  }, [recordList, userData]);

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      const permissions = Object.keys(values).filter((perm) => values[perm] === true);
      await updateRolesUsers(permissions, userData.id);
      message.success(t('messages:success'));
      onClose();
    } catch (error) {
      setErrors({ general: `Não foi possível alterar os grupos de permissão. Erro: ${error.message}` });
      message.error(`Erro: ${error.message}`);
    }
    setLoading(false);
  };

  const tableColumns = [
    {
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <span>{record.name}</span>,
    },
    {
      dataIndex: 'active',
      key: 'slug',
      width: '45px',
      render: (text, record) => (
        <TableActions key={`actions.${record.id}`}>
          <Switch size="small" name={record.slug} />
        </TableActions>
      ),
    },
  ];

  return (
    <Formik initialValues={rolesList} enableReinitialize onSubmit={handleSave}>
      {({ isSubmitting, resetForm, submitForm }) => (
        <Modal
          visible={visible}
          onCancel={onClose}
          afterClose={resetForm}
          confirmLoading={loading || isSubmitting}
          title={t('Grupos de permissões - ') + userData?.name}
          footer={<ModalFooter onOk={submitForm} onCancel={onClose} />}
          width={isMobile ? '100%' : '20%'}
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Table
                showHeader={false}
                pagination={false}
                rowKey="id"
                size="small"
                columns={tableColumns}
                loading={loading || isSubmitting}
                dataSource={recordList}
              />
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}

UserRoles.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};
