import React, { useState, useEffect } from 'react';
import { Input } from 'formik-antd';
import { getIn, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { Container } from './styles';

export default function InputCurrency({ currency, name, onChange, ...props }) {
  const { values, setFieldValue } = useFormikContext();
  const formikValues = getIn(values, name);
  const [displayValue, setDisplayValue] = useState('0,00');

  useEffect(() => {
    if (formikValues) {
      handleChange(formikValues);
    }
  }, [formikValues]);

  const formatValue = (inputValue) => {
    const cleanedValue = inputValue.replace(/[\D.]/g, '');
    const numericValue = parseFloat(cleanedValue) / 100 || 0;
    return numericValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const handleChange = (e) => {
    const inputValue = e;
    const cleanedValue = inputValue.replace(/\D/g, '');
    const formattedValue = formatValue(cleanedValue);

    setDisplayValue(formattedValue);
    setFieldValue(name, formattedValue);

    if (onChange) {
      onChange(formattedValue);
    }
  };

  const handleFocus = (e) => {
    const length = e.target.value.length;
    e.target.setSelectionRange(length, length);
  };

  const handleKeyUp = (e) => {
    const length = e.target.value.length;
    e.target.setSelectionRange(length, length);
  };

  return (
    <Container className="input-currency">
      {currency && <span className="currency">{currency}</span>}
      <Input
        {...props}
        name={name}
        value={displayValue}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={handleFocus}
        onKeyUp={handleKeyUp}
      />
    </Container>
  );
}

InputCurrency.propTypes = {
  currency: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
};

InputCurrency.defaultProps = {
  currency: 'R$',
};
