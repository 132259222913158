import { Popconfirm, Tag } from 'antd';
import i18next from 'i18next';
import React from 'react';
import { FaEye, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import Button from '~/components/Button';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import Strong from '~/components/Strong';
import { TableActions } from '~/components/Table';
import IsMobile from '~/services/mobileCheck';

export const tableColumns = (handleDelete, handleEdit) => {
  const isMobile = IsMobile();

  if (isMobile) {
    return [
      {
        dataIndex: 'summary',
        key: 'summary',
        render: (text, record) => (
          <div>
            <Strong>{i18next.t('screens:Operadora')}:</Strong> {record?.name}
            <br />
            <Strong>{i18next.t('screens:Tipo')}:</Strong>{' '}
            {Array.isArray(record.cards) ? (
              record.cards.map((card, index) => (
                <Tag key={index} color={card.card_type === 1 ? 'blue' : card.card_type === 0 ? 'green' : 'cyan'}>
                  {card.card_type === 1 ? 'CRÉDITO' : card.card_type === 0 ? 'DÉBITO' : 'PIX'}
                </Tag>
              ))
            ) : (
              <span>Sem informações</span>
            )}
            <br />
            <TableActions align="center" justify="flex" isMobile={isMobile}>
              <Can permission="@cards/edit">
                <Button size="small" title="Editar" onClick={() => handleEdit(record)}>
                  <FaPencilAlt />
                </Button>
              </Can>
              <Cannot permission="@cards/edit">
                <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record)}>
                  <FaEye />
                </Button>
              </Cannot>
              <Can permission="@cards/delete">
                <Popconfirm
                  title={i18next.t('messages:confirmDelete')}
                  okText={i18next.t('messages:yes')}
                  onConfirm={() => handleDelete(record.id)}
                  cancelText={i18next.t('messages:no')}
                >
                  <Button size="small" title="Excluir">
                    <FaRegTrashAlt />
                  </Button>
                </Popconfirm>
              </Can>
            </TableActions>
          </div>
        ),
      },
    ];
  }

  return [
    {
      title: i18next.t('screens:Operadora'),
      dataIndex: 'card_operator',
      key: 'card_operator',
      render: (text, record) => record?.name,
    },
    {
      title: i18next.t('screens:Tipo'),
      dataIndex: 'card_type',
      key: 'card_type',
      width: '300px',
      render: (text, record) => {
        if (Array.isArray(record.cards)) {
          return record.cards.map((card, index) => {
            if (card.card_type === 1) {
              return (
                <Tag key={index} color="blue">
                  CRÉDITO
                </Tag>
              );
            }
            if (card.card_type === 0) {
              return (
                <Tag key={index} color="green">
                  DÉBITO
                </Tag>
              );
            }
            if (card.card_type === 2) {
              return (
                <Tag key={index} color="cyan">
                  PIX
                </Tag>
              );
            }
            return null;
          });
        } else {
          return <span>Sem informações</span>;
        }
      },
    },
    {
      title: i18next.t('screens:default.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (
        <TableActions align="center" justify="center">
          <Can permission="@cards/edit">
            <Button size="small" title="Editar" onClick={() => handleEdit(record)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@cards/edit">
            <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record)}>
              <FaEye />
            </Button>
          </Cannot>
          <Can permission="@cards/delete">
            <Popconfirm
              title={i18next.t('messages:confirmDelete')}
              okText={i18next.t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={i18next.t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          </Can>
        </TableActions>
      ),
    },
  ];
};
