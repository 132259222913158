import { Input, InputNumber } from 'formik-antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegFilePdf } from 'react-icons/fa';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import InputCurrency from '~/components/Form/InputCurrency';
import Row from '~/components/Row';

export default function Client({ handlePdf, values, loading }) {
  const { t } = useTranslation();

  return (
    <>
      <Row
        style={{
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'end',
          marginBottom: 20,
        }}
      >
        <Button
          color="primary"
          icon={<FaRegFilePdf />}
          onClick={() => handlePdf(values, 'client-report')}
          title={t('Baixar relatório (cliente)')}
          loading={loading}
        />
      </Row>
      <Row>
        <FormControl cols={{ sm: 6, xs: 12 }} label={t('Taxa de antecipação (%)')} field="anticipation_fee">
          <InputNumber name="anticipation_fee" />
        </FormControl>
        <FormControl cols={{ sm: 6, xs: 12 }} label={t('Taxa débito do cliente (%)')} field="debit_fee">
          <InputNumber name="debit_fee" />
        </FormControl>
        <FormControl cols={{ sm: 6, xs: 12 }} label={t('Taxa crédito do cliente (%)')} field="credit_fee">
          <InputNumber name="credit_fee" />
        </FormControl>
        <FormControl cols={{ sm: 6, xs: 12 }} label={t('Taxa pix do cliente (%)')} field="pix_fee">
          <InputNumber name="pix_fee" />
        </FormControl>
      </Row>
      <Row style={{ marginBottom: 20, marginTop: 20 }}>
        <h2>{t('Valores')}</h2>
      </Row>
      <Row>
        <FormControl cols={{ sm: 8, xs: 12 }} label={t('Débito bruto (R$)')} field="debit_raw_price">
          <InputCurrency name="debit_raw_price" readOnly />
        </FormControl>
        <FormControl cols={{ sm: 8, xs: 12 }} label={t('Taxa - ') + values.debit_fee + '%'} field="debit_fee_price">
          <InputCurrency name="debit_fee_price" readOnly />
        </FormControl>
        <FormControl cols={{ sm: 8, xs: 24 }} label={t('Valor líquido débito')} field="debit_final_price">
          <InputCurrency name="debit_final_price" readOnly />
        </FormControl>
      </Row>
      <Row>
        <FormControl cols={{ sm: 8, xs: 12 }} label={t('Crédito bruto (R$)')} field="credit_raw_price">
          <InputCurrency name="credit_raw_price" readOnly />
        </FormControl>
        <FormControl cols={{ sm: 8, xs: 12 }} label={t('Taxa - ') + values.credit_fee + '%'} field="credit_fee_price">
          <InputCurrency name="credit_fee_price" readOnly />
        </FormControl>
        <FormControl cols={{ sm: 8, xs: 24 }} label={t('Valor líquido crédito')} field="credit_final_price">
          <InputCurrency name="credit_final_price" readOnly />
        </FormControl>
      </Row>
      <Row>
        <FormControl cols={{ sm: 8, xs: 12 }} label={t('Pix bruto (R$)')} field="pix_raw_price">
          <InputCurrency name="pix_raw_price" readOnly />
        </FormControl>
        <FormControl cols={{ sm: 8, xs: 12 }} label={t('Taxa - ') + values.pix_fee + '%'} field="pix_fee_price">
          <InputCurrency name="pix_fee_price" readOnly />
        </FormControl>
        <FormControl cols={{ sm: 8, xs: 24 }} label={t('Valor líquido pix')} field="pix_final_price">
          <InputCurrency name="pix_final_price" readOnly />
        </FormControl>
      </Row>
      <Row style={{ marginBottom: 20, marginTop: 20 }}>
        <h2>{t('Total')}</h2>
      </Row>
      <Row>
        <FormControl cols={{ sm: 12, xs: 12 }} label={t('Total bruto débito + crédito + pix')} field="total_raw_price">
          <InputCurrency name="total_raw_price" readOnly />
        </FormControl>
        <FormControl
          cols={{ sm: 12, xs: 12 }}
          label={t('Total líquido débito + crédito + pix')}
          field="total_liquid_client"
        >
          <InputCurrency name="total_liquid_client" readOnly />
        </FormControl>
      </Row>
      <Row style={{ marginBottom: 20, marginTop: 20 }}>
        <h2>{t('Pagamentos')}</h2>
      </Row>
      <Row>
        {values.event_financial_payments?.map((efp, index) => (
          <>
            <FormControl cols={{ sm: 12, xs: 12 }} label={t('Descrição')}>
              <Input readOnly name={`event_financial_payments.${index}.name`} />
            </FormControl>
            <FormControl cols={{ sm: 12, xs: 12 }} label={t('Valor (R$)')}>
              <InputCurrency readOnly value={efp.price} name={`event_financial_payments.${index}.price`} />
            </FormControl>
          </>
        ))}
      </Row>
      <Row>
        <FormControl cols={{ sm: 24, xs: 24 }} label={t('Total de pagamentos')} field="payments_price">
          <InputCurrency readOnly name={`payments_price`} />
        </FormControl>
      </Row>
      <Row style={{ marginBottom: 20, marginTop: 20 }}>
        <h2>{t('Total final')}</h2>
      </Row>
      <Row>
        <FormControl cols={{ sm: 24, xs: 24 }} field="customer_final_price">
          <InputCurrency readOnly name={`customer_final_price`} />
        </FormControl>
      </Row>
    </>
  );
}
