import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getFirstAllowedRoute } from './routesWithPermissions';

export default function PrivateRoute({ element, requiredPermission }) {
  const { signed: authenticated } = useSelector((state) => state?.auth);
  const { permissions: permissionList } = useSelector((state) => state.user);

  // Se não estiver autenticado, redireciona para login
  if (!authenticated) {
    return <Navigate to="/" />;
  }

  // Se for super admin, pode acessar tudo
  if (permissionList?.includes('@superAdmin')) {
    return element;
  }

  // Se não tiver a permissão necessária, redireciona para a primeira página permitida
  if (!permissionList?.includes(requiredPermission)) {
    const firstAllowedRoute = getFirstAllowedRoute(permissionList);
    return <Navigate to={firstAllowedRoute} />;
  }

  return element;
}
