import { Input, Select } from 'formik-antd';
import React from 'react';
import DateComponent from '~/components/DateComponent';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';

export const renderQuery = (
  queryIndex,
  values,
  setFieldValue,
  selectCards,
  states,
  selectedState,
  operationals,
  customers,
  handleStateChange,
  fluidPermissions,
  items
) => {
  switch (values?.query?.[queryIndex]?.type) {
    case 'low_stock_events':
    case 'low_stock_geral':
      return (
        <>
          <Row>
            <FormControl field={`query.${queryIndex}.items`} label="Items" cols={{ sm: 8, xs: 24 }}>
              <Select
                name={`query.${queryIndex}.items`}
                options={items?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                mode="multiple"
                disabled={!fluidPermissions()}
              />
            </FormControl>
            <FormControl field={`query.${queryIndex}.initial_date`} label="Data Inicial" cols={{ sm: 8, xs: 24 }}>
              <DateComponent
                value={values?.query?.[queryIndex]?.initial_date}
                onChange={(value) => {
                  setFieldValue(`query.${queryIndex}.initial_date`, value);
                }}
                disabled={!fluidPermissions()}
              />
            </FormControl>
            <FormControl field={`query.${queryIndex}.final_date`} label="Data Final" cols={{ sm: 8, xs: 24 }}>
              <DateComponent
                value={values?.query?.[queryIndex]?.final_date}
                onChange={(value) => {
                  setFieldValue(`query.${queryIndex}.final_date`, value);
                }}
                disabled={!fluidPermissions()}
              />
            </FormControl>
          </Row>
        </>
      );
    case 'general_balance':
      return (
        <Row>
          <FormControl field={`query.${queryIndex}.vision_type`} label="Tipo de Visão" cols={{ sm: 12, xs: 24 }}>
            <Select
              name={`query.${queryIndex}.vision_type`}
              options={[
                { label: 'Anual', value: 'Anual' },
                { label: 'Mensal', value: 'Mensal' },
              ]}
              disabled={!fluidPermissions()}
            />
          </FormControl>
          <FormControl field={`query.${queryIndex}.year`} label="Ano" cols={{ sm: 12, xs: 24 }}>
            <Select
              name={`query.${queryIndex}.year`}
              options={[
                { label: '2018', value: '2018' },
                { label: '2019', value: '2019' },
                { label: '2020', value: '2020' },
                { label: '2021', value: '2021' },
                { label: '2022', value: '2022' },
                { label: '2023', value: '2023' },
                { label: '2024', value: '2024' },
                { label: '2025', value: '2025' },
              ]}
              disabled={!fluidPermissions()}
            />
          </FormControl>
        </Row>
      );
    case 'expenses':
      return (
        <Row>
          <FormControl field={`query.${queryIndex}.items`} label="Items" cols={{ sm: 12, xs: 24 }}>
            <Select
              name={`query.${queryIndex}.items`}
              options={items?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              mode="multiple"
              disabled={!fluidPermissions()}
            />
          </FormControl>
          <FormControl field={`query.${queryIndex}.year`} label="Ano" cols={{ sm: 12, xs: 24 }}>
            <Select
              name={`query.${queryIndex}.year`}
              options={[
                { label: '2018', value: '2018' },
                { label: '2019', value: '2019' },
                { label: '2020', value: '2020' },
                { label: '2021', value: '2021' },
                { label: '2022', value: '2022' },
                { label: '2023', value: '2023' },
                { label: '2024', value: '2024' },
                { label: '2025', value: '2025' },
              ]}
              disabled={!fluidPermissions()}
            />
          </FormControl>
        </Row>
      );
    case 'extra_expenses':
    case 'patrimonies':
    case 'extra_receipts':
      return (
        <Row>
          <FormControl field={`query.${queryIndex}.initial_date`} label="Data Inicial" cols={{ sm: 8, xs: 24 }}>
            <DateComponent
              value={values?.query?.[queryIndex]?.initial_date}
              onChange={(value) => {
                setFieldValue(`query.${queryIndex}.initial_date`, value);
              }}
              disabled={!fluidPermissions()}
            />
          </FormControl>
          <FormControl field={`query.${queryIndex}.final_date`} label="Data Final" cols={{ sm: 8, xs: 24 }}>
            <DateComponent
              value={values?.query?.[queryIndex]?.final_date}
              onChange={(value) => {
                setFieldValue(`query.${queryIndex}.final_date`, value);
              }}
              disabled={!fluidPermissions()}
            />
          </FormControl>
          <FormControl field={`query.${queryIndex}.search`} label="Buscar" cols={{ sm: 8, xs: 24 }}>
            <Input name={`query.${queryIndex}.search`} disabled={!fluidPermissions()} />
          </FormControl>
        </Row>
      );
    case 'card_billing_events_client':
      return (
        <Row>
          <FormControl field={`query.${queryIndex}.initial_date`} label="Data Inicial" cols={{ sm: 6, xs: 24 }}>
            <DateComponent
              value={values?.query?.[queryIndex]?.initial_date}
              onChange={(value) => {
                setFieldValue(`query.${queryIndex}.initial_date`, value);
              }}
              disabled={!fluidPermissions()}
            />
          </FormControl>
          <FormControl field={`query.${queryIndex}.final_date`} label="Data Final" cols={{ sm: 6, xs: 24 }}>
            <DateComponent
              value={values?.query?.[queryIndex]?.final_date}
              onChange={(value) => {
                setFieldValue(`query.${queryIndex}.final_date`, value);
              }}
              disabled={!fluidPermissions()}
            />
          </FormControl>
          <FormControl field={`query.${queryIndex}.search`} label="Buscar" cols={{ sm: 6, xs: 24 }}>
            <Input name={`query.${queryIndex}.search`} disabled={!fluidPermissions()} />
          </FormControl>
          <FormControl field={`query.${queryIndex}.modality`} label="Modalidade" cols={{ sm: 6, xs: 24 }}>
            <Select
              name={`query.${queryIndex}.modality`}
              mode="multiple"
              options={[
                { label: 'Crédito', value: 'Crédito' },
                { label: 'Débito', value: 'Débito' },
                { label: 'Pix', value: 'Pix' },
              ]}
              disabled={!fluidPermissions()}
            />
          </FormControl>
        </Row>
      );
    case 'card_billing_events_real':
      return (
        <>
          <Row>
            <FormControl field={`query.${queryIndex}.initial_date`} label="Data Inicial" cols={{ sm: 8, xs: 24 }}>
              <DateComponent
                value={values?.query?.[queryIndex]?.initial_date}
                onChange={(value) => {
                  setFieldValue(`query.${queryIndex}.initial_date`, value);
                }}
                disabled={!fluidPermissions()}
              />
            </FormControl>
            <FormControl field={`query.${queryIndex}.final_date`} label="Data Final" cols={{ sm: 8, xs: 24 }}>
              <DateComponent
                value={values?.query?.[queryIndex]?.final_date}
                onChange={(value) => {
                  setFieldValue(`query.${queryIndex}.final_date`, value);
                }}
                disabled={!fluidPermissions()}
              />
            </FormControl>
            <FormControl field={`query.${queryIndex}.search`} label="Buscar" cols={{ sm: 8, xs: 24 }}>
              <Input name={`query.${queryIndex}.search`} disabled={!fluidPermissions()} />
            </FormControl>
          </Row>
          <Row>
            <FormControl
              field={`query.${queryIndex}.card_operator`}
              label="Operadora de Cartão"
              cols={{ sm: 12, xs: 24 }}
            >
              <Select
                name={`query.${queryIndex}.card_operator`}
                options={selectCards?.map((card) => ({
                  label: card.name,
                  value: card.id,
                }))}
                disabled={!fluidPermissions()}
              />
            </FormControl>
            <FormControl field={`query.${queryIndex}.modality`} label="Modalidade" cols={{ sm: 12, xs: 24 }}>
              <Select
                name={`query.${queryIndex}.modality`}
                mode="multiple"
                options={[
                  { label: 'Crédito', value: 'Crédito' },
                  { label: 'Débito', value: 'Débito' },
                  { label: 'Pix', value: 'Pix' },
                ]}
                disabled={!fluidPermissions()}
              />
            </FormControl>
          </Row>
        </>
      );
    case 'employees':
      return (
        <Row>
          <FormControl field={`query.${queryIndex}.status`} label="Status" cols={{ sm: 6, xs: 24 }}>
            <Select
              name={`query.${queryIndex}.status`}
              options={[
                { label: 'Ativo', value: 'Ativo' },
                { label: 'Inativo', value: 'Inativo' },
              ]}
              disabled={!fluidPermissions()}
            />
          </FormControl>
          <FormControl field={`query.${queryIndex}.state`} label="Estado" cols={{ sm: 6, xs: 24 }}>
            <Select
              name={`query.${queryIndex}.state`}
              options={states.map((s) => ({ label: s.name, value: s.id }))}
              onChange={(value) => {
                handleStateChange(value);
                // Atualizar o valor do campo
                setFieldValue(`query.${queryIndex}.state`, value);
                // Limpar a cidade selecionada quando o estado muda
                setFieldValue(`query.${queryIndex}.city`, '');
              }}
              disabled={!fluidPermissions()}
            />
          </FormControl>
          <FormControl field={`query.${queryIndex}.city`} label="Cidade" cols={{ sm: 6, xs: 24 }}>
            <Select
              name={`query.${queryIndex}.city`}
              showSearch
              filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
              disabled={!selectedState || !fluidPermissions()}
              options={
                selectedState && selectedState.cities
                  ? selectedState.cities.map((city) => ({
                      label: city.name,
                      value: city.id,
                    }))
                  : []
              }
            />
          </FormControl>
          <FormControl field={`query.${queryIndex}.operational_id`} label="Operacional" cols={{ sm: 6, xs: 24 }}>
            <Select
              name={`query.${queryIndex}.operational_id`}
              options={operationals.map((oper) => ({
                label: oper.name,
                value: oper.id,
              }))}
              disabled={!fluidPermissions()}
            />
          </FormControl>
        </Row>
      );
    case 'events_receipts':
      return (
        <>
          <Row>
            <FormControl field={`query.${queryIndex}.initial_date`} label="Data Inicial" cols={{ sm: 12, xs: 24 }}>
              <DateComponent
                value={values?.query?.[queryIndex].initial_date}
                onChange={(value) => {
                  setFieldValue(`query.${queryIndex}.initial_date`, value);
                }}
                disabled={!fluidPermissions()}
              />
            </FormControl>
            <FormControl field={`query.${queryIndex}.final_date`} label="Data Final" cols={{ sm: 12, xs: 24 }}>
              <DateComponent
                value={values?.query?.[queryIndex]?.final_date}
                onChange={(value) => {
                  setFieldValue(`query.${queryIndex}.final_date`, value);
                }}
                disabled={!fluidPermissions()}
              />
            </FormControl>
          </Row>
          <Row>
            <FormControl field={`query.${queryIndex}.search`} label="Buscar" cols={{ sm: 12, xs: 24 }}>
              <Input name={`query.${queryIndex}.search`} disabled={!fluidPermissions()} />
            </FormControl>
            <FormControl field={`query.${queryIndex}.customer_id`} label="Cliente" cols={{ sm: 12, xs: 24 }}>
              <Select
                name={`query.${queryIndex}.customer_id`}
                options={customers.map((customer) => ({
                  label: customer.name,
                  value: customer.id,
                }))}
                disabled={!fluidPermissions()}
              />
            </FormControl>
          </Row>
        </>
      );
    default:
      return null;
  }
};

export const createFilter = (contextName, setFieldValue, values, index) => {
  let queryValues = {};

  switch (contextName) {
    case 'low_stock_events':
      queryValues = { type: 'low_stock_events', context_index: index, initial_date: null, final_date: null, items: [] };
      break;
    case 'low_stock_geral':
      queryValues = { type: 'low_stock_geral', context_index: index, initial_date: null, final_date: null, items: [] };
      break;
    case 'general_balance':
      queryValues = { type: 'general_balance', context_index: index, vision_type: 'Mensal', year: '2025' };
      break;
    case 'expenses':
      queryValues = { type: 'expenses', context_index: index, year: '2025', items: [] };
      break;
    case 'extra_expenses':
      queryValues = { type: 'extra_expenses', context_index: index, initial_date: null, final_date: null, search: '' };
      break;
    case 'patrimonies':
      queryValues = { type: 'patrimonies', context_index: index, initial_date: null, final_date: null, search: '' };
      break;
    case 'extra_receipts':
      queryValues = { type: 'extra_receipts', context_index: index, initial_date: null, final_date: null, search: '' };
      break;
    case 'card_billing_events_client':
      queryValues = {
        type: 'card_billing_events_client',
        context_index: index,
        initial_date: null,
        final_date: null,
        search: '',
        modality: null,
      };
      break;
    case 'card_billing_events_real':
      queryValues = {
        type: 'card_billing_events_real',
        context_index: index,
        initial_date: null,
        final_date: null,
        search: '',
        card_operator: null,
      };
      break;
    case 'employees':
      queryValues = {
        type: 'employees',
        context_index: index,
        status: null,
        state: null,
        city: null,
        operational_id: null,
      };
      break;
    case 'events_receipts':
      queryValues = {
        type: 'events_receipts',
        context_index: index,
        initial_date: null,
        final_date: null,
        search: '',
        customer_id: null,
      };
      break;
    default:
      queryValues = {};
  }

  const updatedQueries = values.query?.filter((query) => query.context_index !== index) || [];

  setFieldValue('query', [...updatedQueries, queryValues]);
};
