import React from 'react';
import { Field } from 'formik';
import { Input } from 'antd';
import InputMask from 'react-input-mask';

const MaskedInput = ({ name, mask, onChange, disabled, ...props }) => {
  // Definindo o tamanho máximo para cada máscara
  const getMaxLength = (mask) => {
    return mask.replace(/[^9]/g, '').length; // Conta apenas os números na máscara
  };

  return (
    <Field name={name}>
      {({ field, form }) => {
        const handleChange = (e) => {
          // Remove os caracteres não numéricos
          const rawValue = e.target.value.replace(/[^\d]/g, '');

          // Limita o valor ao tamanho máximo
          const maxLength = getMaxLength(mask);
          const limitedValue = rawValue.slice(0, maxLength); // Garante que o valor não ultrapasse o limite

          // Atualiza o valor do Formik
          field.onChange({
            target: {
              name,
              value: limitedValue, // Armazena apenas o valor limitado
            },
          });

          // Chama o onChange passado para o componente, passando o valor sem máscara
          if (onChange) {
            onChange(limitedValue);
          }
        };

        return (
          <div>
            <InputMask
              mask={mask}
              value={field.value || ''}
              onChange={handleChange} // Passa o valor limitado para o Formik
              onBlur={field.onBlur}
            >
              {(inputProps) => (
                <Input
                  {...inputProps}
                  {...props}
                  status={form.touched[name] && form.errors[name] ? 'error' : ''}
                  help={form.touched[name] && form.errors[name]}
                  style={
                    disabled && {
                      backgroundColor: '#f5f5f5',
                      borderColor: '#d9d9d9',
                      color: 'rgba(0, 0, 0, 0.25)',
                      pointerEvents: 'none',
                      cursor: 'not-allowed !important',
                    }
                  }
                />
              )}
            </InputMask>
          </div>
        );
      }}
    </Field>
  );
};

export default MaskedInput;
