import React, { useState } from 'react';
import { Layout, Drawer, Button } from 'antd';
import PropTypes from 'prop-types';
import SideMenu from '~/pages/_partials/SideMenu';
import Footer from '~/pages/_partials/Footer';
import { Container, Content } from './styles';
import Header from '~/pages/_partials/Header';
import Logo from '~/components/Logo';
import { useLocation } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import IsMobile from '~/services/mobileCheck';

const { Sider } = Layout;

export default function DefaulLayout({ breadcrumb, children }) {
  const isMobile = IsMobile();
  const [collapsed, setCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const location = useLocation();
  const hiddenMenuRoutes = ['/', '/404'];
  const shouldShowMenu = !hiddenMenuRoutes.includes(location.pathname);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  return shouldShowMenu ? (
    <Container
      id="fullLayout"
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Header paddingCollapsed={collapsed}>
        {isMobile ? (
          <Logo logoName="logo-nobre2.png" width={'30%'} height="auto" style={{ marginLeft: -47 }} />
        ) : (
          <Logo
            logoName={collapsed ? 'logo-nobre2.png' : 'logo-pequeno-nobre.png'}
            width={collapsed ? '35px' : '100%'}
            height="auto"
          />
        )}
      </Header>

      <Layout style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        {/* Sider para desktop */}
        {!isMobile && (
          <Sider
            theme="light"
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
            width={200}
            style={{
              height: 'auto',
              position: 'fixed',
              left: 0,
              top: 50,
              bottom: 0,
              overflow: 'auto',
              scrollbarWidth: 'none',
            }}
          >
            <SideMenu />
          </Sider>
        )}

        {/* Drawer para mobile */}
        {isMobile && (
          <>
            <Button
              type="text"
              icon={<MenuOutlined />}
              onClick={toggleDrawer}
              style={{
                marginBottom: -40,
                fontSize: '16px',
                width: 64,
                height: 64,
                marginRight: '10px',
              }}
            />
            <Drawer
              title={<Logo logoName="logo-pequeno-nobre.png" width="70%" height="auto" />}
              placement="left"
              closable={false}
              onClose={toggleDrawer}
              visible={drawerVisible}
              bodyStyle={{ padding: 0 }}
              width={200}
              style={{ backgroundColor: '#2e394b', color: '#ffbb00', img: { color: '#ffbb00' } }}
            >
              <SideMenu />
            </Drawer>
          </>
        )}

        {/* Layout principal */}
        <Layout
          style={{
            marginLeft: isMobile ? 0 : collapsed ? 80 : 200,
            flex: 1,
            height: isMobile ? 'auto' : '100%',
            overflow: 'hidden',
          }}
        >
          {breadcrumb}
          <Content
            style={{
              flex: 1,
              overflowY: 'auto',
              height: '100vh',
              padding: '20px',
              margin: 0,
            }}
          >
            {children}
          </Content>
          <Footer />
        </Layout>
      </Layout>
    </Container>
  ) : (
    children
  );
}

DefaulLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
};
