import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'reports';

// GET
export const getReports = async (params, route = '') => {
  try {
    const { data } = await api.get(`/${endPoint}/${route}`, params);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

export const getReportsDownload = async (params, route = '') => {
  try {
    const response = await api.get(`/${endPoint}/${route}`, {
      params,
      responseType: 'blob',
    });

    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;

    const fileName = `relatorios_${formattedDate}.zip`;

    const blob = new Blob([response.data], { type: 'application/zip' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE
export const createReports = async (values) => {
  try {
    const { data } = await api.post(`/${endPoint}`, values);
    message.success(i18next.t(`messages:${endPoint}.success`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE
export const updateReports = async (id, values) => {
  try {
    const { data } = await api.put(`/${endPoint}/${id}`, values);
    message.success(i18next.t(`messages:${endPoint}.successEdit`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE
export const deleteReports = async (id) => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successDelete`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

getReports.propTypes = {
  params: PropTypes.object.isRequired,
};

getReportsDownload.propTypes = {
  params: PropTypes.object.isRequired,
};

createReports.propTypes = {
  values: PropTypes.object.isRequired,
};

updateReports.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteReports.propTypes = {
  id: PropTypes.string.isRequired,
};
