import api from '~/services/api';
import PropTypes from 'prop-types';

const endPoint = 'stockwriteoffs';

// GET
export const getStockWriteOffs = async (params) => {
  try {
    const { data } = await api.get(`/${endPoint}`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

// CREATE
export const createStockWriteOffs = async (values) => {
  try {
    const { data } = await api.post(`/${endPoint}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// UPDATE
export const updateStockWriteOffs = async (id, values) => {
  try {
    const { data } = await api.put(`/${endPoint}/${id}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// DELETE
export const deleteStockWriteOffs = async (id) => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

getStockWriteOffs.propTypes = {
  params: PropTypes.object.isRequired,
};

createStockWriteOffs.propTypes = {
  values: PropTypes.object.isRequired,
};

updateStockWriteOffs.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
};

deleteStockWriteOffs.propTypes = {
  id: PropTypes.string.isRequired,
};
