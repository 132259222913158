import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Modal, Spin, message } from 'antd';
import { Form, Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { createRoles, updateRoles } from '~/services/hooks/roles';

export default function RolesForm({ visible, onClose, role }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState(null);

  const fetchRecordData = () => {
    setLoading(true);
    if (role?.id) {
      setRecordData(role);
    } else {
      setRecordData({});
    }
    setLoading(false);
  };

  const handleSave = async (values) => {
    setLoading(true);
    try {
      if (values.id) {
        await updateRoles(values.id, values);
      } else {
        await createRoles(values);
      }
      message.success(t('messages:success'));
      onClose();
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao salvar a grupo de usuários: ') + error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchRecordData();
    }
  }, [visible]);

  const roleSchema = Yup.object().shape({
    name: Yup.string().min(3).required(),
    slug: Yup.string().required(),
  });

  return (
    <Formik initialValues={recordData} enableReinitialize onSubmit={handleSave} validationSchema={roleSchema}>
      {({ errors, isSubmitting, resetForm, submitForm, values }) => (
        <Form>
          <Input type="hidden" name="id" />
          <Spin spinning={loading || isSubmitting}>
            <Modal
              visible={visible}
              title={t(role ? 'Editando grupo - ' : 'Criando grupo - ') + (values?.name ?? '')}
              afterClose={resetForm}
              onCancel={onClose}
              footer={<ModalFooter onOk={submitForm} onCancel={onClose} />}
            >
              <Row>
                <FormControl label={t('Nome')} cols={{ sm: 12, xs: 24 }} field="name" error={errors.name} required>
                  <Input name="name" />
                </FormControl>
                <FormControl label={t('Slug')} cols={{ sm: 12, xs: 24 }} field="slug" error={errors.slug} required>
                  <Input name="slug" />
                </FormControl>
              </Row>
            </Modal>
          </Spin>
        </Form>
      )}
    </Formik>
  );
}

RolesForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  role: PropTypes.objectOf(),
};

RolesForm.defaultProps = {
  role: null,
};
