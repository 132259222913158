import React, { useState, useEffect } from 'react';
import { Card, Col, Modal, Spin, Row, Typography, Divider, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ModalFooter } from '~/components/Modal';
import Button from '~/components/Button';
import { FaWhatsapp } from 'react-icons/fa';
import { createEvents, getEvents } from '~/services/hooks/events';
import { LuCheck, LuCheckCheck, LuClock } from 'react-icons/lu';
import IsMobile from '~/services/mobileCheck';
import Strong from '~/components/Strong';

export default function InviteModal({ visible, event, onClose }) {
  const { t } = useTranslation();
  const isMobile = IsMobile();
  const [loading, setLoading] = useState(false);
  const [teamsEmployees, setTeamsEmployees] = useState([]);

  const fetchTeamsEmployees = async () => {
    try {
      const { data } = await getEvents('', `teams-employees/${event.id}`);
      setTeamsEmployees(data);
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao carregar os funcionários'));
    }
  };

  const handleInviteCustomers = async () => {
    setLoading(true);
    try {
      createEvents(event, 'send-messages');
      message.success(t('Todos os convites foram enviados'));
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao convidar colaboradores: ') + error.message);
    }
    setLoading(false);
  };

  const getCardStyle = (status, response) => {
    if (status) {
      if (response === 0) {
        return { backgroundColor: '#FFF4E6', border: '2px solid #FF6F00' };
      } else if (response === 1) {
        return { backgroundColor: '#E6FFED', border: '2px solid #28A745' };
      } else if (response === 2) {
        return { backgroundColor: '#FFEBEB', border: '2px solid #DC3545' };
      }
    }
    return {};
  };

  const getStatusIcon = (status) => {
    if (status === 'sent') return <LuCheck size={16} color="#888" />;
    if (status === 'delivered') return <LuCheckCheck size={16} color="#888" />;
    if (status === 'read') return <LuCheckCheck size={16} color="#0A74DA" />;
    return <LuClock />;
  };

  const groupedBySectorAndOperational = teamsEmployees?.reduce((acc, employeer) => {
    if (!acc[employeer.sector_name]) {
      acc[employeer.sector_name] = {};
    }

    if (!acc[employeer.sector_name][employeer.operational_name]) {
      acc[employeer.sector_name][employeer.operational_name] = [];
    }

    acc[employeer.sector_name][employeer.operational_name].push(employeer);

    return acc;
  }, {});

  useEffect(() => {
    let interval;

    if (visible) {
      fetchTeamsEmployees();

      interval = setInterval(() => {
        fetchTeamsEmployees();
      }, 2000);
    }

    return () => clearInterval(interval);
  }, [visible]);

  return (
    <Modal
      title={t('Convidar colaboradores') + (event?.name ? ` - ${event.name}` : '')}
      onCancel={onClose}
      open={visible}
      centered
      width="1600px"
      footer={<ModalFooter showOk={false} loading={loading} onCancel={onClose} />}
    >
      <Spin spinning={loading}>
        <Row>
          <Button loading={loading} onClick={() => handleInviteCustomers()} style={{ marginTop: 20, marginBottom: 20 }}>
            <FaWhatsapp />
            {t('Convidar colaboradores')}
          </Button>
        </Row>
        {groupedBySectorAndOperational &&
          Object.keys(groupedBySectorAndOperational).map((sector, sectorIndex) => (
            <div key={sectorIndex} style={{ marginBottom: 24 }}>
              <Typography.Title level={4} style={{ marginBottom: 12 }}>
                {t('Setor: ')} {sector}
              </Typography.Title>
              <Divider />

              {Object.keys(groupedBySectorAndOperational[sector]).map((operational, operationalIndex) => (
                <div key={operationalIndex} style={{ marginBottom: 16 }}>
                  <Typography.Title level={5}>
                    {t('Operacional: ')} {operational}
                  </Typography.Title>

                  <Row gutter={[16, 16]}>
                    {groupedBySectorAndOperational[sector][operational]
                      .sort((a, b) => a.response - b.response)
                      .map((employeer, index) => (
                        <Col key={index} xs={24} sm={12} md={8} lg={6}>
                          <Card
                            style={{
                              height: 120,
                              position: 'relative',
                              ...getCardStyle(employeer.status, employeer.response),
                            }}
                          >
                            <p style={{ fontSize: isMobile ? '12px' : '' }}>
                              <Strong style={{ fontSize: isMobile ? '12px' : '' }}>{t('Nome: ')}</Strong>
                              {employeer.employee_name}
                            </p>
                            <p style={{ fontSize: isMobile ? '12px' : '' }}>
                              <Strong style={{ fontSize: isMobile ? '12px' : '' }}>{t('Tel: ')}</Strong>
                              {employeer.employee_phone}
                            </p>
                            <p style={{ fontSize: isMobile ? '12px' : '' }}>
                              <Strong style={{ fontSize: isMobile ? '12px' : '' }}>{t('Operacional: ')}</Strong>
                              {employeer.operational_name}
                            </p>
                            <div style={{ position: 'absolute', bottom: 8, left: isMobile ? 300 : 340 }}>
                              {getStatusIcon(employeer.status)}
                            </div>
                          </Card>
                        </Col>
                      ))}
                  </Row>
                </div>
              ))}
            </div>
          ))}
      </Spin>
    </Modal>
  );
}
