import React, { useEffect, useState } from 'react';
import { getExtraExpenses, deleteExtraExpenses } from '~/services/hooks/extraExpenses';
import Box from '~/components/Box';
import { Table, TableHeader } from '~/components/Table';
import { Col, Input, message } from 'antd';
import Pagination from '~/components/Pagination';
import Global from '~/styles/global';
import { tableColumns } from './table';
import { useTranslation } from 'react-i18next';
import Button from '~/components/Button';
import ExtraExpensesModal from './form';
import { useCheckAuth } from '~/components/Can/checkAuth';
import ColButton from '~/components/ColButton';
import IsMobile from '~/services/mobileCheck';

export default function ExtraExpenses() {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const isMobile = IsMobile();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState({});
  const [search, setSearch] = useState('');
  const [extraExpensesEdit, setExtraExpensesEdit] = useState({});
  const [ModalVisible, setModalVisible] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          page,
          perPage,
          search,
        },
      };
      const data = await getExtraExpenses(params);
      setData(data.data);
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao carregar as despesas. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const handleEdit = (record) => {
    setExtraExpensesEdit(record);
    setModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteExtraExpenses(id);
      message.success(t('Despesa excluída'));
      fetchData();
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao deletar a despesa. Erro: ') + error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, search]);

  return (
    <Box>
      <ColButton>
        <Button color="primary" onClick={() => handleEdit(null)} disabled={!authCheck('@extraExpenses/create')}>
          {t('screens:extraExpenses.addExtraExpenses')}
        </Button>
      </ColButton>
      <TableHeader>
        <Col style={{ display: 'flex' }}>
          <Input.Search
            onSearch={(value) => {
              setSearch(value);
              setPage(1);
            }}
            className="search-field"
            placeholder={t('screens:default.search')}
          />
        </Col>
      </TableHeader>
      <Table
        pagination={false}
        rowKey="id"
        loading={loading}
        dataSource={data}
        columns={tableColumns(t, handleEdit, handleDelete, isMobile)}
        bordered
      />
      <Pagination
        isMobile={isMobile}
        onChange={(value) => {
          setPage(value);
        }}
        meta={meta}
        perPage={perPage}
        setPerPage={setPerPage}
      />
      <Global />
      <ExtraExpensesModal
        visible={ModalVisible}
        extraExpensesEdit={extraExpensesEdit}
        isMobile={isMobile}
        onClose={() => {
          setModalVisible(false);
          setExtraExpensesEdit(null);
          fetchData();
        }}
      />
    </Box>
  );
}
