import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Spin, Input } from 'antd';
import UserForm from './form';
import Box from '~/components/Box';
import Button from '~/components/Button';
import Pagination from '~/components/Pagination';
import { Table, TableHeader } from '~/components/Table';
import { deleteUsers, getUsers } from '~/services/hooks/users';
import { tableColumns } from './table';
import UserRoles from './roles';
import { useCheckAuth } from '~/components/Can/checkAuth';
import ColButton from '~/components/ColButton';
import IsMobile from '~/services/mobileCheck';

export default function Users() {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const isMobile = IsMobile();
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState({});
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState('');
  const [roleFormVisible, setRoleFormVisible] = useState(false);

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const params = {
        params: { page, perPage, search },
      };
      const { data, meta } = await getUsers(params, '');
      setMeta(meta);
      setTableData(data);
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao carregar os usuários. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const handleEdit = (user) => {
    setSelectedRecord(user);
    setShowForm(true);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await deleteUsers(id);
      message.success(t('Usuário excluído'));
      fetchRecords();
    } catch (error) {
      console.log(error);
      message.error(t('Não foi possível deletar o usuário. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const handleRoleForm = (record) => {
    setSelectedRecord(record);
    setRoleFormVisible(true);
  };

  useEffect(() => {
    fetchRecords();
  }, [page, perPage, search]);

  return (
    <Box>
      <Spin spinning={loading}>
        <ColButton>
          <Button color="primary" onClick={() => handleEdit(null)} disabled={!authCheck('@users/create')}>
            {t('Adicionar usuário')}
          </Button>
        </ColButton>
        <TableHeader>
          <Input.Search
            style={{ width: 300 }}
            onChange={(value) => {
              setSearch(value.target.value);
              setPage(1);
            }}
            placeholder="Pesquisar"
          />
        </TableHeader>
        <Table
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
          columns={tableColumns(handleEdit, handleDelete, handleRoleForm, isMobile)}
          dataSource={tableData}
        />
        <Pagination
          isMobile={isMobile}
          onChange={(value) => {
            setPage(value);
          }}
          meta={meta}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Spin>
      <UserForm
        user={selectedRecord}
        visible={showForm}
        disabledImage={true}
        isMobile={isMobile}
        onClose={() => setShowForm(false)}
      />
      <UserRoles
        visible={roleFormVisible}
        userData={selectedRecord}
        isMobile={isMobile}
        onClose={() => {
          setRoleFormVisible(false);
          setSelectedRecord(null);
          fetchRecords();
        }}
      />
    </Box>
  );
}
