import React from 'react';
import { Card, Button } from 'antd';
import { FieldArray } from 'formik';
import { Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import FormControl from '~/components/Form/FormControl';
import InputCurrency from '~/components/Form/InputCurrency';
import Row from '~/components/Row';

export default function FinancialClosingLosses({ values, setFieldValue }) {
  const { t } = useTranslation();

  // Função para converter string "3.430,00" para número 3430.00
  const parseBRLToFloat = (value) => {
    if (!value) return 0;
    return parseFloat(value.replace(/\./g, '').replace(',', '.'));
  };

  // Somar todos os valores corretamente
  const totalPrice = values?.event_financial_losses?.reduce((acc, curr) => {
    const price = parseBRLToFloat(curr.price);
    return acc + price;
  }, 0);

  // Formatar para BRL
  const formattedTotalPrice = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(totalPrice);

  return (
    <FieldArray
      key={'event_financial_losses'}
      name="event_financial_losses"
      render={(arrayHelpers) => (
        <>
          <h3 style={{ marginTop: 20 }}>
            {t('Total')}: {formattedTotalPrice}
          </h3>
          {values?.event_financial_losses?.map((efl, index) => (
            <Card
              key={index}
              style={{ marginBottom: 16, marginTop: 30 }}
              title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span>{efl?.name}</span>
                  <Button danger type="text" onClick={() => arrayHelpers.remove(index)}>
                    <FaRegTimesCircle size={18} />
                  </Button>
                </div>
              }
            >
              <Row>
                <FormControl
                  cols={{ sm: 12, xs: 24 }}
                  label={t('Descrição')}
                  field={`event_financial_losses.${index}.name`}
                >
                  <Input name={`event_financial_losses.${index}.name`} />
                </FormControl>
                <FormControl
                  cols={{ sm: 12, xs: 24 }}
                  label={t('Valor (R$)')}
                  field={`event_financial_losses.${index}.price`}
                >
                  <InputCurrency name={`event_financial_losses.${index}.price`} />
                </FormControl>
              </Row>
            </Card>
          ))}
          <Button
            color="primary"
            icon={<FaPlusCircle color="green" />}
            onClick={() => {
              const newLoss = {
                event_financial_id: null,
                name: '',
                price: '0,00',
              };

              const updatedLosses = [...values?.event_financial_losses, newLoss];
              setFieldValue('event_financial_losses', updatedLosses);
            }}
            style={{ marginTop: 30 }}
          />
        </>
      )}
    />
  );
}
