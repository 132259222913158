// src/App.js

import React, { Suspense } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { Spin } from 'antd';

import './config/yupConfig';
import './config/i18n';
import './config/reactotronConfig';
import 'styles/override.scss';
import theme from 'styles/theme';

import GlobalStyles from './styles/global';
import Routes from './routes';
import { store, persistor } from './store';
import DefaulLayout from './pages/_layouts/full';

function App() {
  return (
    <Suspense fallback={<Spin style={{ position: 'absolute', left: '50%', top: '50%' }} className="page-loader" />}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <DefaulLayout>
              <GlobalStyles />
              <Routes />
            </DefaulLayout>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
