import React, { useState } from 'react';
import { Avatar, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { signOutRequest } from '~/store/modules/auth/actions';
import { AntMenu, CustomAvatar } from './styles';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import UserForm from '~/pages/Users/form';
import IsMobile from '~/services/mobileCheck';

export default function HeaderMenu() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = IsMobile();
  const [showForm, setShowForm] = useState(false);
  const userName = useSelector((state) => (state.user.profile ? state.user.profile.name : null));
  const profile = useSelector((state) => (state.user.profile ? state.user.profile : null));

  function handleEdit() {
    setShowForm(true);
  }

  function handleSignOut() {
    dispatch(signOutRequest());
  }

  const renderSubMenuTitle = (label) => {
    return (
      <>
        <span>{label}</span>
      </>
    );
  };

  const renderMenu = (item) => {
    const { type } = item;

    if (type === 'divider') {
      return <AntMenu.Divider key={item.key} />;
    } else if (type === 'sub') {
      return (
        <AntMenu.SubMenu
          key={item.key}
          style={{ marginRight: 1 }}
          title={
            item?.profile ? (
              <CustomAvatar>
                {item.profile && item.profile.image ? (
                  <Avatar src={item.profile.image} />
                ) : (
                  <Avatar icon={<UserOutlined />} style={{ marginRight: 10 }} />
                )}
                {!isMobile && item.label}
              </CustomAvatar>
            ) : (
              <span>
                {item.icon}
                <span>{renderSubMenuTitle(item.label)}</span>
              </span>
            )
          }
        >
          {item.items.map((subItem) => renderMenu(subItem))}
        </AntMenu.SubMenu>
      );
    } else if (type === 'sub-item') {
      return (
        <AntMenu.Item key={item.key}>
          <span>{item.label}</span>
          <Link to={item.url} />
        </AntMenu.Item>
      );
    } else {
      return (
        <AntMenu.Item key={item.key}>
          {item.click && item.key === 'logout' ? (
            <a id="btnLogout" onClick={item.click}>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Col style={{ display: 'flex', gap: 10 }}>
                  {item.icon}
                  {item.label}
                </Col>
              </Col>
            </a>
          ) : item.click && item.key === 'profile' ? (
            <a id="btnProfile" onClick={item.click}>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Col style={{ display: 'flex', gap: 10 }}>
                  {item.icon}
                  {item.label}
                </Col>
              </Col>
            </a>
          ) : (
            <>
              {item.icon}
              <span>{item.label}</span>
              <Link to={item.url} />
            </>
          )}
        </AntMenu.Item>
      );
    }
  };

  const userMenuItems = [
    {
      type: 'sub',
      key: 'user',
      label: userName || 'Username',
      profile: profile,
      items: [
        {
          type: 'item',
          key: 'profile',
          label: t('menus:userProfile'),
          icon: <UserOutlined style={{ marginLeft: '0px' }} />,
          click: handleEdit,
        },
        {
          type: 'item',
          key: 'logout',
          label: t('menus:userLogout'),
          icon: <LogoutOutlined style={{ marginLeft: '0px' }} />,
          click: handleSignOut,
        },
      ],
    },
  ];

  return (
    <Col>
      <AntMenu id="headerMenu" mode="horizontal" theme="light">
        {userMenuItems.map((item) => renderMenu(item))}
      </AntMenu>
      <UserForm
        visible={showForm}
        disabledImage={false}
        me={true}
        isMobile={isMobile}
        onClose={() => setShowForm(false)}
      />
    </Col>
  );
}
