import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'cards';

// GET
export const getCards = async (params, route = '') => {
  try {
    const { data } = await api.get(`/${endPoint}/${route}`, params);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE
export const createCards = async (values, route = '') => {
  try {
    const { data } = await api.post(`/${endPoint}/${route}`, values);
    message.success(i18next.t(`messages:${endPoint}.success`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE
export const updateCards = async (id, values) => {
  try {
    const { data } = await api.put(`/${endPoint}/${id}`, values);
    message.success(i18next.t(`messages:${endPoint}.successEdit`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE
export const deleteCards = async (id) => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successDelete`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

getCards.propTypes = {
  values: PropTypes.object.isRequired,
};

createCards.propTypes = {
  values: PropTypes.object.isRequired,
};

updateCards.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteCards.propTypes = {
  id: PropTypes.string.isRequired,
};
