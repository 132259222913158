import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';

const endPoint = 'cardoperators';

// GET
export const getCardsOperator = async (params, route = '') => {
  try {
    const { data } = await api.get(`/${endPoint}/${route}`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

// CREATE
export const createCardsOperator = async (values, route = '') => {
  try {
    const { data } = await api.post(`/${endPoint}/${route}`, values);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE
export const updateCardsOperator = async (id, values) => {
  try {
    const { data } = await api.put(`/${endPoint}/${id}`, values);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE
export const deleteCardsOperator = async (id) => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

getCardsOperator.propTypes = {
  values: PropTypes.object.isRequired,
};

createCardsOperator.propTypes = {
  values: PropTypes.object.isRequired,
};

updateCardsOperator.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteCardsOperator.propTypes = {
  id: PropTypes.string.isRequired,
};
