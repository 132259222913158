import React, { useEffect, useState } from 'react';
import { message, Modal, Spin, Card, Button } from 'antd';
import { FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import { Formik, FieldArray } from 'formik';
import { Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { createPatrimonies, updatePatrimonies } from '~/services/hooks/patrimonies';
import DateComponent from '~/components/DateComponent';
import InputCurrency from '~/components/Form/InputCurrency';
import { useCheckAuth } from '~/components/Can/checkAuth';

const initial = {
  name: null,
  patrimony_payments: [],
};

export default function PatrimoniesModal({ visible, patrimoniesEdit, onClose, isMobile }) {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(initial);

  const savePatrimonies = async (values) => {
    setLoading(true);
    try {
      if (values.id) {
        await updatePatrimonies(values.id, values);
        message.success(t('messages:successUpdate'));
      } else {
        await createPatrimonies(values);
        message.success(t('messages:successCreate'));
      }

      onClose();
    } catch (error) {
      console.log(error);
      message.error(t('Não foi possível salvar o patrimonio. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const fluidPermissions = () => {
    if (patrimoniesEdit) {
      return authCheck('@assets/edit');
    } else {
      return authCheck('@assets/create');
    }
  };

  useEffect(() => {
    if (visible) {
      if (patrimoniesEdit?.patrimony_payments) {
        setInitialValues(patrimoniesEdit);
      }
    }
  }, [visible]);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={savePatrimonies}
    >
      {({ isSubmitting, submitForm, values, setFieldValue }) => (
        <Modal
          title={t('Patrimônios')}
          onCancel={onClose}
          afterClose={() => setInitialValues(initial)}
          style={{ maxWidth: isMobile ? '100%' : '1500px' }}
          open={visible}
          centered
          width="1300px"
          footer={<ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} />}
        >
          <Spin spinning={loading}>
            <Card>
              <Row>
                <FormControl cols={{ sm: 12, xs: 24 }} field="name" label={t('Nome')}>
                  <Input name="name" disabled={!fluidPermissions()} />
                </FormControl>
              </Row>

              <FieldArray
                name="patrimony_payments"
                render={(arrayHelpers) => (
                  <div>
                    {values.patrimony_payments &&
                      values.patrimony_payments.map((_, index) => (
                        <Card
                          key={index}
                          style={{ marginBottom: '16px' }}
                          title={`Pagamento ${index + 1}`}
                          extra={
                            <Button
                              color="danger"
                              title="Excluir"
                              onClick={() => arrayHelpers.remove(index)}
                              style={{
                                padding: '5px 10px',
                                borderRadius: '4px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              disabled={!fluidPermissions()}
                            >
                              <FaRegTimesCircle color="red" />
                            </Button>
                          }
                        >
                          <Row gutter={24}>
                            <FormControl
                              cols={{ sm: 10 }}
                              field={`patrimony_payments.${index}.description`}
                              label="Descrição"
                            >
                              <Input name={`patrimony_payments.${index}.description`} disabled={!fluidPermissions()} />
                            </FormControl>
                            <FormControl
                              cols={{ sm: 6 }}
                              field={`patrimony_payments.${index}.acquired_at`}
                              label="Data de Aquisição"
                            >
                              <DateComponent
                                value={values.patrimony_payments[index].acquired_at}
                                onChange={(date) => setFieldValue(`patrimony_payments.${index}.acquired_at`, date)}
                                disabled={!fluidPermissions()}
                              />
                            </FormControl>

                            <FormControl cols={{ sm: 8 }} field={`patrimony_payments.${index}.price`} label="Preço">
                              <InputCurrency
                                name={`patrimony_payments.${index}.price`}
                                disabled={!fluidPermissions()}
                              />
                            </FormControl>
                          </Row>
                        </Card>
                      ))}
                    <div style={{ marginTop: '16px', textAlign: 'right' }}>
                      <Button
                        color="green"
                        onClick={() => arrayHelpers.push({ description: '', acquired_at: '', price: '' })}
                        style={{
                          padding: '5px 10px',
                          borderRadius: '4px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        disabled={!fluidPermissions()}
                      >
                        <FaPlusCircle color="green" />
                      </Button>
                    </div>
                  </div>
                )}
              />
            </Card>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
