import { Layout } from 'antd';
import styled from 'styled-components';

export const Container = styled(Layout.Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  gap: ${(props) => (props.isMobile ? '183px' : '80px')};
  padding-left: ${(props) => (props.paddingCollapsed ? '23px' : '30px')};
  padding-right: ${(props) => (props.isMobile ? '' : '23px')};
`;
