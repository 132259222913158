import { Button, Card, message, Modal, Spin } from 'antd';
import { Formik, FieldArray } from 'formik';
import { Input, Select } from 'formik-antd';
import React, { useEffect, useState } from 'react';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import { FaPlusSquare, FaRegTimesCircle } from 'react-icons/fa';
import { ModalFooter } from '~/components/Modal';
import { createFilter, renderQuery } from './query';
import { getStates } from '~/services/hooks/states';
import { getOperationals } from '~/services/hooks/operationals';
import { getCustomers } from '~/services/hooks/customers';
import { createReports, updateReports } from '~/services/hooks/reports';
import { getCardsOperator } from '~/services/hooks/cardsOperator';
import { useCheckAuth } from '~/components/Can/checkAuth';
import { getItems } from '~/services/hooks/items';
import { useTranslation } from 'react-i18next';

const options = [
  { label: 'Baixas de estoque (Eventos)', value: 'low_stock_events' },
  { label: 'Baixas de estoque (Geral)', value: 'low_stock_geral' },
  { label: 'Balanço geral', value: 'general_balance' },
  { label: 'Despesas', value: 'expenses' },
  { label: 'Despesas extra', value: 'extra_expenses' },
  { label: 'Faturamento em cartão dos eventos (Cliente)', value: 'card_billing_events_client' },
  { label: 'Faturamento em cartão dos eventos (Real)', value: 'card_billing_events_real' },
  { label: 'Funcionários', value: 'employees' },
  { label: 'Patrimônio', value: 'patrimonies' },
  { label: 'Receitas extra', value: 'extra_receipts' },
  { label: 'Receitas dos eventos', value: 'events_receipts' },
];

const initial = {
  title: null,
  query: [],
  context: [],
};

export default function ReportModal({ visible, report, onClose, isMobile }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const authCheck = useCheckAuth();
  const [initialValues, setInitialValues] = useState(initial);
  const [selectCards, setSelectCards] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState({});
  const [operationals, setOperationals] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);

  const handleSave = async (values) => {
    setLoading(true);
    try {
      if (report) {
        await updateReports(report.id, values);
      } else {
        await createReports(values);
      }
    } catch (error) {
      console.log(error);
      message.error(`Erro ao salvar os relatórios: ${error.message}`);
    }
    setLoading(false);
  };

  const fetchAll = async () => {
    setLoading(true);
    try {
      const dataCards = await getCardsOperator('', 'no-paginated');
      const dataStates = await getStates('', 'no-paginated');
      const dataOperationals = await getOperationals('', 'no-paginated');
      const dataCustomers = await getCustomers('', 'no-paginated');
      const dataItems = await getItems('', 'no-paginated');
      setSelectCards(dataCards);
      setStates(dataStates);
      setOperationals(dataOperationals);
      setCustomers(dataCustomers);
      setItems(dataItems);
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao carregar dados: ') + error.message);
    }
    setLoading(false);
  };

  const handleStateChange = (value) => {
    const selected = states.find((s) => s.id === value);
    setSelectedState(selected || {});
  };

  const fluidPermissions = () => {
    if (report) {
      return authCheck('@reports/edit');
    } else {
      return authCheck('@reports/create');
    }
  };

  useEffect(() => {
    if (visible) {
      fetchAll();

      if (report) {
        setInitialValues(report);
        report?.query?.forEach((query) => {
          if (query.type === 'employees') {
            setSelectedState(states.find((s) => s.id === query.state));
          }
        });
      } else {
        setInitialValues(initial);
      }
    }
  }, [visible, report]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSave}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, values, setFieldValue, submitForm, isSubmitting }) => (
        <Modal
          afterClose={() => setInitialValues(initial)}
          width={1200}
          open={visible}
          onOk={() => handleSave(values)}
          onCancel={onClose}
          okText="Salvar"
          cancelText="Cancelar"
          footer={<ModalFooter onCancel={onClose} onOk={submitForm} loading={loading || isSubmitting} />}
        >
          <Spin spinning={loading}>
            <Row>
              <FormControl field="title" label="Título do Relatório" error={errors?.title} cols={{ xs: 12 }}>
                <Input name="title" disabled={!fluidPermissions()} />
              </FormControl>
            </Row>
            <FieldArray
              name="context"
              render={(arrayHelpers) => (
                <>
                  {values.context &&
                    values.context.map((contextValue, index) => (
                      <Card key={index} style={{ marginBottom: 10 }}>
                        <Row key={index}>
                          <FormControl
                            field={`context[${index}]`}
                            label={`Contexto ${index + 1}`}
                            error={errors?.context?.[index]}
                            cols={{ sm: 22, xs: 20 }}
                          >
                            <Select
                              name={`context[${index}]`}
                              options={options}
                              style={{ width: '100%' }}
                              onChange={(value) => {
                                setFieldValue(`context[${index}]`, value);
                                createFilter(value, setFieldValue, values, index);
                              }}
                              disabled={!fluidPermissions()}
                            />
                          </FormControl>
                          <Button
                            danger
                            onClick={() => {
                              arrayHelpers.remove(index);
                              const updatedQueries = values.query?.filter((query) => query.context_index !== index);
                              const adjustedQueries = updatedQueries.map((query, i) => ({
                                ...query,
                                context_index: i,
                              }));

                              setFieldValue('query', adjustedQueries);
                            }}
                            style={{ marginTop: isMobile ? 20 : 25, marginLeft: isMobile ? 0 : 30 }}
                            disabled={!fluidPermissions()}
                          >
                            <FaRegTimesCircle />
                          </Button>
                        </Row>
                        {renderQuery(
                          values.query?.findIndex((f) => f.type === contextValue),
                          values,
                          setFieldValue,
                          selectCards,
                          states,
                          selectedState,
                          operationals,
                          customers,
                          handleStateChange,
                          fluidPermissions,
                          items
                        )}
                      </Card>
                    ))}
                  <Row>
                    <Button
                      onClick={() => arrayHelpers.push('')}
                      style={{ marginTop: 10 }}
                      disabled={!fluidPermissions()}
                    >
                      <FaPlusSquare />
                    </Button>
                  </Row>
                </>
              )}
            />
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
