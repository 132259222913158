import React, { useEffect, useState } from 'react';
import { deleteEvents, getEvents } from '~/services/hooks/events';
import errorHandler from '~/Utils/errorHandler';
import Box from '~/components/Box';
import { Table, TableHeader } from '~/components/Table';
import { Col, Input, message } from 'antd';
import Pagination from '~/components/Pagination';
import Global from '~/styles/global';
import { tableColumns } from './table';
import { useTranslation } from 'react-i18next';
import { SelectAntd } from '~/components/Pagination/styles';
import DateComponent from '~/components/DateComponent';
import moment from 'moment';
import Button from '~/components/Button';
import { useCheckAuth } from '~/components/Can/checkAuth';
import FormEvents from './forms/modal';
import FinancialClosingModal from './financialClosing/financialClosing';
import InviteModal from './inviteModal';
import IsMobile from '~/services/mobileCheck';

export default function Events() {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const isMobile = IsMobile();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState({});
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useState({
    status: null,
    payment_status: null,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [formVisible, setFormVisible] = useState(false);
  const [editEvent, setEditEvent] = useState(null);
  const [visibleFinancialClosing, setVisibleFinancialClosing] = useState(false);
  const [event, setEvent] = useState(null);
  const [inviteFormVisible, setInviteFormVisible] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        page,
        perPage,
        search,
        starts_at: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        ends_at: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
        ...searchParams,
      };
      const data = await getEvents({ params });

      setData(data.data);
      setMeta(data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSearch = () => {
    fetchData();
  };

  const handleModal = (event) => {
    setFormVisible(true);
    setEditEvent(event);
  };

  const handleModalFinancial = (event) => {
    setVisibleFinancialClosing(true);
    setEvent(event);
  };

  const handleInvites = (event) => {
    setInviteFormVisible(true);
    setEditEvent(event);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const backMessage = await deleteEvents(id);
      message.success(backMessage);
    } catch (error) {
      console.log(error);
      message.error('Não foi possível deletar o evento. Erro: ' + error.message);
    } finally {
      setLoading(false);
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, [search, page, perPage, searchParams]);

  return (
    <Box>
      <Col
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginBottom: '30px',
        }}
      >
        <Button color="primary" onClick={() => handleModal(null)} disable={!authCheck('@events/create')}>
          Adicionar evento
        </Button>
      </Col>
      <TableHeader>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            flexWrap: isMobile ? 'wrap' : '',
          }}
        >
          <Input.Search
            onSearch={(value) => {
              setSearch(value);
              setPage(1);
            }}
            placeholder="Pesquisar"
            className="search-field"
            style={{ width: isMobile ? '48%' : '', marginBottom: isMobile ? '15px' : '' }}
          />
          <SelectAntd
            style={{ width: isMobile ? '48%' : '200px', marginBottom: isMobile ? '15px' : '' }}
            placeholder="Status"
            onSelect={(value) => {
              setSearchParams((initialValues) => ({
                ...initialValues,
                status: value,
              }));
              setPage(1);
            }}
          >
            <SelectAntd.Option value={null}>TODOS</SelectAntd.Option>
            <SelectAntd.Option value={0}>EM PREENCHIMENTO</SelectAntd.Option>
            <SelectAntd.Option value={1}>ORÇAMENTO EM ANÁLISE</SelectAntd.Option>
            <SelectAntd.Option value={2}>FINALIZADO</SelectAntd.Option>
            <SelectAntd.Option value={3}>REPROVADO</SelectAntd.Option>
            <SelectAntd.Option value={4}>APROVADO</SelectAntd.Option>
          </SelectAntd>
          <SelectAntd
            style={{ width: isMobile ? '100%' : '200px', marginBottom: isMobile ? '15px' : '' }}
            placeholder="Pagamento"
            onSelect={(value) => {
              setSearchParams((initialValues) => ({
                ...initialValues,
                payment_status: value,
              }));
              setPage(1);
            }}
          >
            <SelectAntd.Option value={null}>TODOS</SelectAntd.Option>
            <SelectAntd.Option value={0}>EM ABERTO</SelectAntd.Option>
            <SelectAntd.Option value={1}>NÃO LÍQUIDADO</SelectAntd.Option>
            <SelectAntd.Option value={2}>LÍQUIDADO</SelectAntd.Option>
          </SelectAntd>
          <Col style={{ width: isMobile ? '48%' : '', marginBottom: isMobile ? '15px' : '' }}>
            <DateComponent label="Data de Início" value={startDate} onChange={setStartDate} />
          </Col>
          <Col style={{ width: isMobile ? '48%' : '', marginBottom: isMobile ? '15px' : '' }}>
            <DateComponent
              label="Data de Fim"
              value={endDate}
              onChange={setEndDate}
              style={{ width: isMobile ? '48%' : '' }}
            />
          </Col>
          <Button color="default" onClick={handleSearch}>
            Buscar
          </Button>
        </Col>
      </TableHeader>
      <Table
        pagination={false}
        rowKey="id"
        loading={loading}
        dataSource={data}
        columns={tableColumns(t, handleModal, handleDelete, handleModalFinancial, handleInvites, isMobile)}
        bordered
      />
      <Pagination
        isMobile={isMobile}
        onChange={(value) => {
          setPage(value);
        }}
        meta={meta}
        perPage={perPage}
        setPerPage={setPerPage}
      />
      <Global />
      <FormEvents
        visible={formVisible}
        event={editEvent}
        isMobile={isMobile}
        onClose={() => {
          setFormVisible(false);
          setEditEvent(null);
          fetchData();
        }}
      />
      <FinancialClosingModal
        visible={visibleFinancialClosing}
        event={event}
        isMobile={isMobile}
        onClose={() => {
          setVisibleFinancialClosing(false);
          setEvent(null);
          fetchData();
        }}
      />
      <InviteModal
        visible={inviteFormVisible}
        event={editEvent}
        isMobile={isMobile}
        onClose={() => {
          setInviteFormVisible(false);
          setEditEvent(null);
          fetchData();
        }}
      />
    </Box>
  );
}
