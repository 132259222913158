import { message, Modal, Spin, Button, Row as AntRow, Col } from 'antd';
import { Formik, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { createFinancialExpenses, updateFinancialExpenses } from '~/services/hooks/financialExpenses';
import FormControl from '~/components/Form/FormControl';
import { Input } from 'formik-antd';
import { SelectAntd } from '~/components/Pagination/styles';
import { FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import * as Yup from 'yup';
import { useCheckAuth } from '~/components/Can/checkAuth';

const initial = {
  name: '',
  expense_type: 0,
  financial_expense_items: [],
};

export default function ExpenseModal({ visible, onClose, expense, isMobile }) {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState(initial);

  const saveFinancialExpense = async (values) => {
    setLoading(true);
    try {
      if (expense) {
        await updateFinancialExpenses(expense.id, values);
      } else {
        await createFinancialExpenses(values);
      }
      onClose();
    } catch (error) {
      message.error(`Não foi possível salvar a despesa. Erro: ${error.message}`);
    }
    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    expense_type: Yup.number().required('Tipo de despesa é obrigatório'),
    financial_expense_items: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('Nome do item de despesa é obrigatório'),
        })
      )
      .nullable(),
  });

  const fluidPermissions = () => {
    if (expense) {
      return authCheck('@expenses/edit');
    } else {
      return authCheck('@expenses/create');
    }
  };

  useEffect(() => {
    if (visible) {
      if (expense) {
        setRecord(expense);
      } else {
        setRecord(initial);
      }
    }
  }, [visible]);

  return (
    <Formik
      onSubmit={saveFinancialExpense}
      validationSchema={validationSchema}
      initialValues={record}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
    >
      {({ isSubmitting, submitForm, resetForm, errors, values, setFieldValue }) => (
        <Modal
          title={(expense ? t('screens:expenses.editTitle') : t('screens:expenses.newTitle')) + values.name}
          onCancel={onClose}
          style={{ maxWidth: isMobile ? '100%' : '700px' }}
          afterClose={resetForm}
          open={visible}
          loading={isSubmitting}
          centered
          width={isMobile ? '400px' : '700px'}
          footer={<ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} />}
        >
          <Spin spinning={loading}>
            <Row>
              <FormControl
                cols={{ xs: 16 }}
                field="name"
                error={errors?.name}
                required
                label={t('fields:expenses.name')}
              >
                <Input name="name" disabled={!fluidPermissions()} />
              </FormControl>
              <FormControl
                cols={{ xs: 8 }}
                field="expense_type"
                error={errors?.expense_type}
                required
                label={t('fields:expenses.type')}
              >
                <SelectAntd
                  placeholder="SELECIONE"
                  value={values?.expense_type}
                  onSelect={(value) => setFieldValue('expense_type', value)}
                  loading={loading}
                  disabled={!fluidPermissions()}
                >
                  <SelectAntd.Option key={0} value={0}>
                    {t('screens:expenses.fixed')}
                  </SelectAntd.Option>
                  <SelectAntd.Option key={1} value={1}>
                    {t('screens:expenses.fixedVariable')}
                  </SelectAntd.Option>
                  <SelectAntd.Option key={2} value={2}>
                    {t('screens:expenses.variable')}
                  </SelectAntd.Option>
                </SelectAntd>
              </FormControl>
            </Row>

            <FieldArray name="financial_expense_items">
              {({ push, remove }) => (
                <>
                  {values?.financial_expense_items?.map((financialExpenseItem, index) => (
                    <AntRow key={index} gutter={16} align="middle">
                      <Col xs={20}>
                        <FormControl
                          cols={{ xs: 24 }}
                          field={`financial_expense_items.${index}.name`}
                          error={errors?.financial_expense_items?.[index]?.name}
                          required
                          label={t('fields:expenses.name')}
                        >
                          <Input name={`financial_expense_items.${index}.name`} disabled={!fluidPermissions()} />
                        </FormControl>
                      </Col>
                      <Col xs={4}>
                        <Button
                          color="danger"
                          title="Remover item de despesa"
                          onClick={() => remove(index)}
                          style={{ marginTop: 10 }}
                          disabled={!fluidPermissions()}
                        >
                          <FaRegTimesCircle color="red" />
                        </Button>
                      </Col>
                    </AntRow>
                  ))}
                  <Row>
                    <Button
                      color="primary"
                      title="Adicionar item de despesa"
                      onClick={() => push({ name: '' })}
                      style={{ marginTop: '16px', marginLeft: 16 }}
                      disabled={!fluidPermissions()}
                    >
                      <FaPlusCircle color="green" />
                    </Button>
                  </Row>
                </>
              )}
            </FieldArray>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
