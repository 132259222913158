import styled from 'styled-components';
import { Layout } from 'antd';

const { Footer } = Layout;

export const Container = styled(Footer)`
  gap: 20;
  background: 'none';
  text-align: 'bottom';
  color: #999;
  display: flex;
  align-items: center;
  gap: 11px;
  bottom: 0;
  justify-content: center;
  padding: 1px 0;

  img {
    margin: 8px 7px 5px 4px;
  }
`;
