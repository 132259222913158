import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import HeaderMenu from './HeaderMenu';
import IsMobile from '~/services/mobileCheck';

export default function Header({ paddingCollapsed, children }) {
  const isMobile = IsMobile();
  return (
    <Container paddingCollapsed={paddingCollapsed} isMobile={isMobile}>
      <div className="logo">{children}</div>
      <HeaderMenu />
    </Container>
  );
}

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
};
