import { Popconfirm } from 'antd';
import i18next from 'i18next';
import React from 'react';
import { FaEye, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import Button from '~/components/Button';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import Strong from '~/components/Strong';
import { TableActions } from '~/components/Table';

export const tableColumns = (t, handleEdit, handleDelete, isMobile) => {
  if (isMobile) {
    return [
      {
        dataIndex: 'summary',
        key: 'summary',
        render: (text, record) => (
          <div>
            <Strong>{t('screens:Nome')}:</Strong> {record?.name}
            <br />
            <Strong>{t('Peso Unitário (kg)')}:</Strong> {record?.weight}
            <br />
            <Strong>{t('Em estoque')}:</Strong> {record?.amount}
            <br />
            <TableActions align="center" justify="center" isMobile={isMobile}>
              <Can permission="@products/edit">
                <Button size="small" title="Editar" onClick={() => handleEdit(record)}>
                  <FaPencilAlt />
                </Button>
              </Can>
              <Cannot permission="@products/edit">
                <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record)}>
                  <FaEye />
                </Button>
              </Cannot>
              <Can permission="@products/delete">
                <Popconfirm
                  title={t('messages:confirmDelete')}
                  okText={t('messages:yes')}
                  onConfirm={() => handleDelete(record.id)}
                  cancelText={t('messages:no')}
                >
                  <Button size="small" title="Excluir">
                    <FaRegTrashAlt />
                  </Button>
                </Popconfirm>
              </Can>
            </TableActions>
          </div>
        ),
      },
    ];
  }

  return [
    {
      title: t('screens:Nome'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => record.name,
    },
    {
      title: t('Peso Unitário (kg)'),
      dataIndex: 'weight',
      key: 'weight',
      render: (text, record) => record.weight,
    },
    {
      title: t('Em estoque'),
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: t('screens:default.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (
        <TableActions align="center" justify="center">
          <Can permission="@products/edit">
            <Button size="small" title="Editar" onClick={() => handleEdit(record)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@products/edit">
            <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record)}>
              <FaEye />
            </Button>
          </Cannot>
          <Can permission="@products/delete">
            <Popconfirm
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          </Can>
        </TableActions>
      ),
    },
  ];
};

export const historyTable = (isMobile) => {
  if (isMobile) {
    return [
      {
        dataIndex: 'summary',
        key: 'summary',
        render: (text, record) => (
          <div>
            <Strong>{i18next.t('Lote')}:</Strong> {record?.id}
            <br />
            <Strong>{i18next.t('Qtde em estoque')}:</Strong> {record?.amount}
            <br />
            <Strong>{i18next.t('Preço de custo')}:</Strong>{' '}
            {'R$ ' +
              parseFloat(record?.cost_price).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            <br />
            <Strong>{i18next.t('Fornecedor')}:</Strong> {record?.provider}
          </div>
        ),
      },
    ];
  }

  return [
    {
      title: i18next.t('Lote'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: i18next.t('Qtde em estoque'),
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: i18next.t('Preço de custo'),
      dataIndex: 'cost_price',
      key: 'cost_price',
      render: (cost_price) =>
        'R$ ' + parseFloat(cost_price).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    },
    {
      title: i18next.t('Fornecedor'),
      dataIndex: 'provider',
      key: 'provider',
    },
  ];
};

export const lowStockHistoryTable = (isMobile) => {
  if (isMobile) {
    return [
      {
        dataIndex: 'summary',
        key: 'summary',
        render: (text, record) => (
          <div>
            <Strong>{i18next.t('Baixa')}:</Strong> {record?.id}
            <br />
            <Strong>{i18next.t('Qtde consumida')}:</Strong> {record?.amount}
            <br />
            <Strong>{i18next.t('Valor')}:</Strong>{' '}
            {'R$ ' +
              parseFloat(record?.price).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            <br />
            <Strong>{i18next.t('Evento')}:</Strong> {record?.event_name}
          </div>
        ),
      },
    ];
  }

  return [
    {
      title: i18next.t('Baixa'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: i18next.t('Qtde consumida'),
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: i18next.t('Valor'),
      dataIndex: 'price',
      key: 'price',
      render: (price) =>
        'R$ ' + parseFloat(price).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    },
    {
      title: i18next.t('Evento'),
      dataIndex: 'event_name',
      key: 'event_name',
    },
  ];
};
