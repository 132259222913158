import api from '~/services/api';
import PropTypes from 'prop-types';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'users';

// GET
export const getUsers = async (params, route = '') => {
  try {
    const { data } = await api.get(`/${endPoint}/${route}`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

// FIND ALL
export const findAllUsers = async (filters) => {
  try {
    const { data } = await api.get(`/${endPoint}/findAll`, filters);
    return data;
  } catch (error) {
    throw error;
  }
};

// CREATE
export const createUsers = async (values, route = '') => {
  try {
    const { data } = await api.post(`/${endPoint}/${route}`, values);
    message.success(i18next.t(`messages:${endPoint}.success`));
    return data;
  } catch (error) {
    throw error;
  }
};

// UPDATE
export const updateUsers = async (values, route = '') => {
  try {
    const { data } = await api.put(`/${endPoint}/${route}`, values);
    message.success(i18next.t(`messages:${endPoint}.successEdit`));
    return data;
  } catch (error) {
    throw error;
  }
};

// DELETE
export const deleteUsers = async (id) => {
  try {
    const { data } = await api.delete(`/${endPoint}/delete/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successDelete`));
    return data;
  } catch (error) {
    throw error;
  }
};

getUsers.propTypes = {
  params: PropTypes.object.isRequired,
};

findAllUsers.propTypes = {
  filters: PropTypes.object.isRequired,
};

createUsers.propTypes = {
  values: PropTypes.object.isRequired,
};

updateUsers.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteUsers.propTypes = {
  id: PropTypes.string.isRequired,
};
