import { useSelector } from 'react-redux';

export function useCheckAuth() {
  const { permissions: permissionList } = useSelector((state) => state.user);

  return (permission) => {
    if (permissionList?.includes('@superAdmin')) {
      return true; // SuperAdmin tem todas as permissões
    }
    return permissionList?.includes(permission) ?? false;
  };
}
