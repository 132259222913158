import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, message, Tag } from 'antd';
import { Input, InputNumber } from 'formik-antd';
import FormControl from '~/components/Form/FormControl';
import { FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import InputCurrency from '~/components/Form/InputCurrency';
import { getOperationals } from '~/services/hooks/operationals';
import { SelectAntd } from '~/components/Pagination/styles';

export const OperationalModal = ({
  t,
  values,
  setLoading,
  loading,
  setFieldValue,
  visible,
  errors,
  fluidPermissions,
}) => {
  const [operationals, setOperationals] = useState([]);

  const fetchOperationals = async () => {
    setLoading(true);
    try {
      const operationals = await getOperationals('', 'no-paginated');
      setOperationals(operationals);
    } catch (error) {
      console.log(error);
      message.error(`Fala ao carregar campos operacionais.
        Erro: ${error}`);
    }
    setLoading(false);
  };

  const handleAddSector = () => {
    setLoading(true);
    try {
      values.sectors.push({ name: '', sector_operationals: [] });
      setFieldValue(values);
    } catch (error) {
      console.log(error);
      message.error(`Erro ao adicionar setor. Error: ${error}`);
    }
    setLoading(false);
  };

  const handleRemoveSector = (index) => {
    setLoading(true);
    try {
      values.sectors.splice(index, 1);
      setFieldValue(values);
    } catch (error) {
      console.log(error);
      message.error(`Erro ao deletar setor. Error: ${error}`);
    }
    setLoading(false);
  };

  const handleAddOperational = (sectorIndex) => {
    setLoading(true);
    try {
      values.sectors[sectorIndex].sector_operationals.push({
        amount: 0,
        price_unit: 0,
        price_total: 0,
        operationals: { id: null },
        operational_id: null,
      });
      setFieldValue(values);
    } catch (error) {
      console.log(error);
      message.error(`Erro ao adicionar operação. Error: ${error}`);
    }
    setLoading(false);
  };

  const handleRemoveOperational = (sectorIndex, sectorOperationalIndex) => {
    setLoading(true);
    try {
      values.sectors[sectorIndex].sector_operationals.splice(sectorOperationalIndex, 1);
      setFieldValue(values);
    } catch (error) {
      console.log(error);
      message.error(`Erro ao remover operação. Error: ${error}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchOperationals();
    }
  }, [visible]);

  useEffect(() => {
    if (visible && values) {
      const updatedSectors = values.sectors?.map((sector, sectorIndex) => {
        const updatedSectorOperationals = sector.sector_operationals?.map((sectorOp, sectorOperationalIndex) => {
          const amount = sectorOp?.amount || 0;
          const priceUnit = sectorOp?.price_unit
            ? sectorOp.price_unit.includes(',')
              ? parseFloat(sectorOp.price_unit.replace(',', '.'))
              : parseFloat(sectorOp.price_unit)
            : 0;
          const newPriceTotal = priceUnit * amount;
          const formattedPriceTotal = newPriceTotal
            .toFixed(2)
            .replace('.', ',')
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

          setFieldValue(
            `sectors.${sectorIndex}.sector_operationals.${sectorOperationalIndex}.price_total`,
            formattedPriceTotal
          );

          return {
            ...sectorOp,
            price_total: formattedPriceTotal,
          };
        });

        return {
          ...sector,
          sector_operationals: updatedSectorOperationals,
        };
      });
      const updatedTotalSectors = values.sectors.map((sector) => {
        const sectorTotal = sector.sector_operationals.reduce((sum, op) => {
          const rawValue = op.price_total?.toString() || '0';
          const numericValue = parseFloat(rawValue.replace(/\./g, '').replace(',', '.')) || 0;

          return sum + numericValue;
        }, 0);

        const formattedSectorTotal = sectorTotal
          .toFixed(2)
          .replace('.', ',')
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        return { ...sector, price_total: formattedSectorTotal };
      });

      setFieldValue('sectors', updatedSectors);
      updatedTotalSectors.forEach((updatedSector, index) => {
        if (JSON.stringify(updatedSector) !== JSON.stringify(values.sectors[index])) {
          setFieldValue(`sectors.${index}`, updatedSector);
        }
      });
    }
  }, [visible, JSON.stringify(values), setFieldValue]);

  return (
    <div>
      {values?.sectors?.map((sector, sectorIndex) => (
        <Card
          key={sectorIndex}
          title={`${sector.name || 'Novo Setor'}`}
          extra={
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', alignItems: 'center' }}>
              <Tag>Total do Setor: {sector.price_total || 0}</Tag>
              <Button danger onClick={() => handleRemoveSector(sectorIndex)}>
                Remover Setor
              </Button>
            </div>
          }
          style={{ marginBottom: 16 }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <FormControl
                label={t('fields:event.sector.name')}
                field={`sectors.${sectorIndex}.name`}
                error={errors?.sectors?.[sectorIndex]?.name}
                required
              >
                <Input name={`sectors.${sectorIndex}.name`} disabled={!fluidPermissions()} />
              </FormControl>
            </Col>

            {sector.sector_operationals.map((sectorOperational, sectorOperationalIndex) => (
              <Col xs={24} key={sectorOperationalIndex}>
                <Card style={{ padding: '8px' }}>
                  <Row gutter={[8, 8]} align="middle">
                    <Col xs={24} md={10}>
                      <FormControl
                        label={t('fields:event.sector.operational.name')}
                        field={`sectors.${sectorIndex}.sector_operationals.${sectorOperationalIndex}.operationals.id`}
                        error={
                          errors?.sectors?.[sectorIndex]?.sector_operationals?.[sectorOperationalIndex]?.operationals
                            ?.id
                        }
                        required
                      >
                        <SelectAntd
                          placeholder="SELECIONE"
                          value={
                            values?.sectors[sectorIndex]?.sector_operationals[sectorOperationalIndex]?.operationals
                              ?.id ?? null
                          }
                          showSearch
                          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                          onSelect={(value) => {
                            const selectedOperational = operationals.find((item) => item.id === value);

                            if (selectedOperational) {
                              setFieldValue(
                                `sectors.${sectorIndex}.sector_operationals.${sectorOperationalIndex}.operationals`,
                                {
                                  id: selectedOperational.id,
                                  name: selectedOperational.name,
                                }
                              );
                              setFieldValue(
                                `sectors.${sectorIndex}.sector_operationals.${sectorOperationalIndex}.operational_id`,
                                selectedOperational.id
                              );
                            }
                          }}
                          loading={loading}
                          disabled={!fluidPermissions()}
                        >
                          {operationals?.map((item) => (
                            <SelectAntd.Option key={item.id} value={item.id}>
                              {item.name}
                            </SelectAntd.Option>
                          ))}
                        </SelectAntd>
                      </FormControl>
                    </Col>

                    <Col xs={12} md={4}>
                      <FormControl
                        label={t('fields:event.sector.operational.amount')}
                        error={errors?.sectors?.[sectorIndex]?.sector_operationals?.[sectorOperationalIndex]?.amount}
                        required
                        field={`sectors.${sectorIndex}.sector_operationals.${sectorOperationalIndex}.amount`}
                      >
                        <InputNumber
                          name={`sectors.${sectorIndex}.sector_operationals.${sectorOperationalIndex}.amount`}
                          number
                          onChange={(value) => {
                            setFieldValue(
                              `sectors.${sectorIndex}.sector_operationals.${sectorOperationalIndex}.amount`,
                              value
                            );

                            const priceUnit =
                              values.sectors[sectorIndex].sector_operationals[sectorOperationalIndex].price_unit || 0;
                            const numericPriceUnit = parseFloat(
                              priceUnit.toString().replace(/\./g, '').replace(',', '.')
                            );

                            const newPriceTotal = numericPriceUnit * value;
                            const formattedPriceTotal = newPriceTotal
                              .toFixed(2)
                              .replace('.', ',')
                              .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

                            setFieldValue(
                              `sectors.${sectorIndex}.sector_operationals.${sectorOperationalIndex}.price_total`,
                              formattedPriceTotal
                            );
                          }}
                          disabled={!fluidPermissions()}
                        />
                      </FormControl>
                    </Col>

                    <Col xs={12} md={4}>
                      <FormControl
                        label={t('fields:event.sector.operational.priceUnit')}
                        error={
                          errors?.sectors?.[sectorIndex]?.sector_operationals?.[sectorOperationalIndex]?.price_unit
                        }
                        required
                        field={`sectors.${sectorIndex}.sector_operationals.${sectorOperationalIndex}.price_unit`}
                      >
                        <InputCurrency
                          currency="R$"
                          name={`sectors.${sectorIndex}.sector_operationals.${sectorOperationalIndex}.price_unit`}
                          onChange={(value) => {
                            const amount =
                              values.sectors[sectorIndex].sector_operationals[sectorOperationalIndex].amount || 0;
                            const numericValue = parseFloat(value.replace(/\./g, '').replace(',', '.'));
                            const newPriceTotal = numericValue * amount;
                            const formattedPriceTotal = newPriceTotal
                              .toFixed(2)
                              .replace('.', ',')
                              .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

                            setFieldValue(
                              `sectors.${sectorIndex}.sector_operationals.${sectorOperationalIndex}.price_total`,
                              formattedPriceTotal
                            );
                          }}
                          disabled={!fluidPermissions()}
                        />
                      </FormControl>
                    </Col>

                    <Col xs={24} md={4}>
                      <Tag>Preço total: {sectorOperational.price_total}</Tag>
                    </Col>

                    <Col xs={24} md={2} style={{ textAlign: 'center' }}>
                      <Button
                        color="danger"
                        title="Remover operacional"
                        onClick={() => handleRemoveOperational(sectorIndex, sectorOperationalIndex)}
                        disabled={!fluidPermissions()}
                      >
                        <FaRegTimesCircle color="red" />
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}

            <Col xs={24}>
              <Button
                color="primary"
                block
                onClick={() => handleAddOperational(sectorIndex)}
                disabled={!fluidPermissions()}
              >
                <FaPlusCircle color="green" /> Adicionar Operacional
              </Button>
            </Col>
          </Row>
        </Card>
      ))}

      <Row justify="center">
        <Col>
          <Button color="primary" onClick={handleAddSector} disabled={!fluidPermissions()}>
            Adicionar Setor
          </Button>
        </Col>
      </Row>
    </div>
  );
};
