import { Col, Menu } from 'antd';
import styled from 'styled-components';

export const AntMenu = styled(Menu)`
  color: #f5f5f5 !important;
  background-color: transparent !important;

  .ant-menu-submenu::after {
    display: none;
  }

  .ant-menu-submenu,
  .ant-menu-submenu-popup,
  .ant-menu,
  .ant-menu-light {
    height: 100%;
  }

  border: none;

  svg,
  span {
    color: #f5f5f5 !important;
  }
`;

export const CustomAvatar = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
