import React, { useEffect, useState } from 'react';
import { Table, TableHeader } from '~/components/Table';
import Box from '~/components/Box';
import { Button, Col, Input, message, Modal } from 'antd';
import Pagination from '~/components/Pagination';
import Global from '~/styles/global';
import { getEmployees } from '~/services/hooks/employees';
import { ModalFooter } from '~/components/Modal';
import { SelectAntd } from '~/components/Pagination/styles';
import { getStates } from '~/services/hooks/states';
import { getCities } from '~/services/hooks/cities';
import { FaMinus } from 'react-icons/fa';
import { employeesTableColumns } from '../table';

export default function EmployeeModal({
  visible,
  t,
  onClose,
  values,
  setFieldValue,
  operationalId,
  sectorId,
  sectorIndex,
  sectorOperationalIndex,
  fluidPermissions,
  isMobile,
}) {
  const [employees, setEmployees] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState({});
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useState({
    classification: '',
    departure_at: null,
    cities: null,
    city_id: null,
  });
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState(null);

  const fetchEmployees = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          page,
          perPage,
          search,
          ...searchParams,
        },
      };
      const employees = await getEmployees(params);
      setEmployees(employees.data);
      setMeta(employees.meta);
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao carregar os funcionários. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const fetchStateAndCity = async () => {
    try {
      if (states.length < 1) {
        const states = await getStates('', 'no-paginated');
        setStates(states);
      }
      if (selectedState) {
        const params = {
          params: {
            state_id: selectedState,
          },
        };
        const cities = await getCities(params, 'no-paginated');
        setCities(cities.data);
      }
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao carregar os estados/cidades. Erro: ') + error.message);
    }
  };

  const handleAddEmployee = (employeeId, employeeName) => {
    values.team.push({
      event_id: values.id,
      employee_id: employeeId,
      employee_name: employeeName,
      operational_id: operationalId,
      sector_id: sectorId,
      sector_index: sectorIndex,
      sector_operational_index: sectorOperationalIndex,
      departure_time: null,
      extra: 0,
      departure_at: '',
    });
    setFieldValue(values);
  };

  useEffect(() => {
    if (visible) {
      fetchEmployees();
    }
  }, [visible, page, search, perPage, searchParams]);

  useEffect(() => {
    if (visible) {
      fetchStateAndCity();
    }
  }, [visible, selectedState]);

  return (
    <Modal
      title={t('screens:events.employees.title')}
      onCancel={onClose}
      style={{ maxWidth: isMobile ? '100%' : '1500px' }}
      afterClose={() => {
        setEmployees([]);
        setSearch('');
        setPage(1);
        setPerPage(10);
        setSearchParams({
          classification: '',
          departure_at: null,
          cities: null,
          city_id: null,
        });
        setSelectedState(null);
        setCities([]);
        setStates([]);
      }}
      open={visible}
      centered
      width={isMobile ? '100%' : '1700px'}
      footer={<ModalFooter loading={loading} onCancel={onClose} />}
    >
      <Box>
        <TableHeader>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              flexWrap: isMobile ? 'wrap' : '',
            }}
          >
            <Input.Search onSearch={(value) => setSearch(value)} style={{ width: isMobile ? '100%' : 300 }} />
            <SelectAntd
              placeholder="Classificação"
              onSelect={(value) => {
                setSearchParams((thisValues) => ({ ...thisValues, classification: value }));
              }}
              loading={loading}
              style={{ width: isMobile ? '48%' : 150, marginTop: isMobile ? 10 : '' }}
              disabled={!fluidPermissions()}
            >
              <SelectAntd.Option key={0} value={0}>
                A
              </SelectAntd.Option>
              <SelectAntd.Option key={1} value={1}>
                B
              </SelectAntd.Option>
              <SelectAntd.Option key={2} value={2}>
                C
              </SelectAntd.Option>
            </SelectAntd>
            <SelectAntd
              placeholder="Trabalhou no últimos 30 dias"
              value={searchParams.departure_at ?? null}
              onSelect={(value) => {
                setSearchParams((thisValues) => ({ ...thisValues, departure_at: value }));
              }}
              loading={loading}
              style={{ width: isMobile ? '48%' : 230, marginTop: isMobile ? 10 : '' }}
              disabled={!fluidPermissions()}
            >
              <SelectAntd.Option key={1} value={1}>
                1
              </SelectAntd.Option>
              <SelectAntd.Option key={2} value={2}>
                2
              </SelectAntd.Option>
              <SelectAntd.Option key={3} value={3}>
                3
              </SelectAntd.Option>
              <SelectAntd.Option key={4} value={4}>
                4
              </SelectAntd.Option>
              <SelectAntd.Option key={5} value={5}>
                5
              </SelectAntd.Option>
              <SelectAntd.Option key={6} value={6}>
                6
              </SelectAntd.Option>
              <SelectAntd.Option key={7} value={7}>
                7
              </SelectAntd.Option>
              <SelectAntd.Option key={8} value={8}>
                8
              </SelectAntd.Option>
              <SelectAntd.Option key={9} value={9}>
                9
              </SelectAntd.Option>
              <SelectAntd.Option key={10} value={10}>
                10
              </SelectAntd.Option>
            </SelectAntd>
            <SelectAntd
              placeholder="Estado"
              onSelect={(value) => {
                setSearchParams((thisValues) => ({ ...thisValues, cities: value, city_id: null }));
                setSelectedState(value);
              }}
              value={selectedState ?? null}
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              loading={loading}
              style={{ width: isMobile ? '48%' : 150, marginTop: isMobile ? 10 : '' }}
              disabled={!fluidPermissions()}
            >
              {states?.map((state) => (
                <SelectAntd.Option key={state.id} value={state.id}>
                  {state.name}
                </SelectAntd.Option>
              ))}
            </SelectAntd>
            <SelectAntd
              placeholder="Cidade"
              value={searchParams.city_id ?? null}
              onSelect={(value) => {
                setSearchParams((thisValues) => ({ ...thisValues, city_id: value }));
              }}
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              loading={loading}
              style={{ width: isMobile ? '48%' : 150, marginTop: isMobile ? 10 : '' }}
              disabled={!selectedState || !fluidPermissions()}
            >
              {cities?.map((states, statesIndex) => (
                <SelectAntd.Option key={statesIndex} value={states.id}>
                  {states.name}
                </SelectAntd.Option>
              ))}
            </SelectAntd>
            <Button
              title="Limpar pesquisa"
              onClick={() => {
                setSelectedState(null);
                setSearchParams((thisValues) => ({ ...thisValues, city_id: null, cities: null, departure_at: null }));
                setSearch('');
              }}
              disabled={!fluidPermissions()}
              style={{ marginTop: isMobile ? 10 : '' }}
            >
              <FaMinus />
            </Button>
          </Col>
        </TableHeader>
        <Table
          pagination={false}
          rowKey="id"
          loading={loading}
          dataSource={employees}
          columns={employeesTableColumns(t, handleAddEmployee, isMobile)}
          bordered
        />
        <Pagination
          isMobile={isMobile}
          onChange={(value) => {
            setPage(value);
          }}
          meta={meta}
          perPage={perPage}
          setPerPage={setPerPage}
        />
        <Global />
      </Box>
    </Modal>
  );
}
