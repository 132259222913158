import React from 'react';
import PropTypes from 'prop-types';
import { FiLoader } from 'react-icons/fi';

import { Container } from './styles';
import IsMobile from '~/services/mobileCheck';

export default function Button({ type, children, color, size, loading, icon, ...props }) {
  const isMobile = IsMobile();
  return (
    <Container
      type={type}
      block={props.block}
      className={`ll-btn ${props.className || ''} ${props.block && 'btn-block'} ${loading && 'btn-loading'}`}
      {...props}
      size={isMobile ? 'small' : size}
      loading={Number(loading)}
      color={color}
      disabled={props.disabled || loading}
    >
      <span className="btn-text">
        {icon && icon}
        {children}
      </span>
      <div className="loader">
        <FiLoader />
      </div>
    </Container>
  );
}

Button.propTypes = {
  children: PropTypes.any.isRequired,
  color: PropTypes.oneOf(['primary', 'default', 'danger', 'clear', 'success', 'info', 'warning']),
  size: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  color: 'default',
  size: 'default',
  type: 'button',
  loading: false,
};
