import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Card, Col, Modal, Spin, Tag, message } from 'antd';
import { Form, Switch } from 'formik-antd';
import PropTypes from 'prop-types';
import { Table, TableActions } from '~/components/Table';
import { ModalFooter } from '~/components/Modal';
import { getPermissionsGroups } from '~/services/hooks/permissionsGroups';
import { updateRolesPermissions } from '~/services/hooks/rolesPermissions';

export default function PermissionsForm({ roleID, rolePermissions, onClose, visible, roleName }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [recordList, setRecordList] = useState([]);
  const [rolePermissionsList, setRolePermissionsList] = useState([]);

  const fetchRecords = async () => {
    setLoading(true);
    setRecordList([]);
    try {
      const data = await getPermissionsGroups('', 'no-paginated');
      setRecordList(data);
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao carregar grupo de permissões: ') + error.message);
    }
    setLoading(false);
  };

  const handleSave = async (values) => {
    try {
      const slugs = Object.keys(values).filter((role) => values[role] === true);
      await updateRolesPermissions(roleID, slugs);
      message.success(t('messages:success'));
      onClose();
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao salvar grupo de permissões: ') + error.message);
    }
  };

  useEffect(() => {
    if (recordList?.length > 0 && rolePermissions) {
      const roles = {};
      recordList.forEach((item) => {
        item.permissions.forEach((perm) => {
          roles[perm.slug] = rolePermissions.includes(perm.slug);
        });
      });
      setRolePermissionsList(roles);
    }
  }, [recordList, rolePermissions]);

  useEffect(() => {
    if (visible) {
      fetchRecords();
    }
  }, [visible]);

  const tableColumns = [
    {
      title: t('screens:permissions.data.permissions'),
      key: 'name',
      dataIndex: 'name',
    },
  ];

  // Função para agrupar permissões por categoria (Leitura, Edição, Exclusão, etc.)
  const groupPermissionsByAction = (permissions) => {
    const grouped = {
      Leitura: [],
      Edição: [],
      Exclusão: [],
      'Custo/Valor': [],
      Outros: [], // Permissões que não se encaixam nos grupos acima
    };

    permissions.forEach((permission) => {
      if (permission.name.startsWith('Leitura')) {
        grouped.Leitura.push(permission);
      } else if (permission.name.startsWith('Edição')) {
        grouped.Edição.push(permission);
      } else if (permission.name.startsWith('Exclusão')) {
        grouped.Exclusão.push(permission);
      } else if (permission.name.includes('Custo') || permission.name.includes('Valor')) {
        grouped['Custo/Valor'].push(permission);
      } else {
        grouped.Outros.push(permission);
      }
    });

    return grouped;
  };

  // Colunas para renderizar as permissões agrupadas
  const permissionsColumns = [
    {
      title: t('screens:roles.permission'),
      dataIndex: 'name',
      key: 'name',
      width: '100%', // Garante que não ultrapasse os limites
      ellipsis: true, // Trunca o texto se for muito longo
      render: (text, record) => (
        <span
          style={{
            display: 'flex',
            flexDirection: 'column',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {record.name}
          <small>
            <Tag style={{ fontSize: '14px' }}>{record.slug}</Tag>
          </small>
        </span>
      ),
    },
    {
      key: 'active',
      width: '45px',
      render: (text, record) => (
        <TableActions key={`actions.${record.id}`}>
          <Switch size="small" key={record.id} name={record.slug} />
        </TableActions>
      ),
    },
  ];

  // Renderiza as permissões agrupadas por categoria
  const renderGroupedPermissions = (record) => {
    const groupedPermissions = groupPermissionsByAction(record.permissions);

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap-reverse', alignItems: 'stretch' }}>
        {Object.keys(groupedPermissions).map(
          (group) =>
            groupedPermissions[group].length > 0 && (
              <Col key={group} style={{ flex: '1 0 calc(50% - 16px)', margin: '8px', minWidth: '300px' }}>
                <Card title={group} style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                  <Table
                    size="small"
                    showHeader={false}
                    pagination={false}
                    dataSource={groupedPermissions[group]}
                    columns={permissionsColumns}
                    style={{
                      width: '100%',
                      tableLayout: 'fixed',
                      overflow: 'hidden',
                    }}
                  />
                </Card>
              </Col>
            )
        )}
      </div>
    );
  };

  return (
    <Formik initialValues={rolePermissionsList} enableReinitialize onSubmit={handleSave}>
      {({ isSubmitting, resetForm, submitForm }) => (
        <Modal
          onCancel={onClose}
          afterClose={resetForm}
          title={t('Editando permissões - ') + (roleName ?? '')}
          visible={visible}
          width={900}
          footer={<ModalFooter onOk={submitForm} onCancel={onClose} />}
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Table
                expandRowByClick
                showHeader={false}
                pagination={false}
                rowKey="name"
                size="small"
                columns={tableColumns}
                loading={loading || isSubmitting}
                dataSource={recordList}
                expandedRowRender={renderGroupedPermissions}
              />
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}

PermissionsForm.propTypes = {
  roleID: PropTypes.number,
  rolePermissions: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

PermissionsForm.defaultProps = {
  roleID: null,
  rolePermissions: [],
  onClose: () => {},
  visible: false,
};
