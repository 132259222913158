import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const DateComponent = ({ label, value, onChange, showTime, ...props }) => {
  const format = `DD/MM/YYYY${showTime ? ' HH:mm' : ''}`;
  const placeholder = `Selecione a data${showTime ? ' e hora' : ''}`;
  const parsedValue = value ? dayjs(value) : null;

  return (
    <div style={{ marginBottom: '10px' }}>
      <label>{label}</label>
      <DatePicker
        {...props}
        value={parsedValue}
        format={format}
        placeholder={placeholder}
        style={{ width: '100%', display: 'block' }}
        showTime={showTime ? { format: 'HH:mm' } : false}
        onChange={(date) => {
          if (date) {
            onChange(date.format('YYYY-MM-DDTHH:mm:ss'));
          } else {
            onChange(null);
          }
        }}
      />
    </div>
  );
};

export default DateComponent;
