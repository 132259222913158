import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FaBalanceScale,
  FaBoxes,
  FaBoxOpen,
  FaBuilding,
  FaCalendarAlt,
  FaCreditCard,
  FaFileInvoice,
  FaFolder,
  FaIdBadge,
  FaMoneyBillAlt,
  FaMoneyBillWave,
  FaSuitcase,
  FaTachometerAlt,
  FaTags,
  FaTicketAlt,
  FaToolbox,
  FaTools,
  FaUsers,
} from 'react-icons/fa';

import { AntMenu } from './styles';

export default function SideMenu() {
  const { t } = useTranslation();
  const { permissions: permissionsList } = useSelector((state) => state.user || {});
  const location = useLocation();

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);

  // Função para verificar permissões
  const checkAuth = (permission) => {
    if (permissionsList?.includes('@superAdmin')) return true;
    return permission?.some((perm) => permissionsList?.includes(perm));
  };

  // Definir itens do menu
  const sideMenuItems = useMemo(
    () =>
      [
        {
          type: 'item',
          key: 'dashboard',
          permission: ['@dashboard/view'],
          label: t('menus:dashboard'),
          icon: <FaTachometerAlt />,
          url: '/dashboard',
        },
        {
          type: 'sub',
          key: 'financial',
          permission: [
            '@expenses/view',
            '@cards/view',
            '@assets/view',
            '@extras/view',
            '@extraExpenses/view',
            '@eventsReceipts/view',
            '@balance/view',
            '@reports/view',
          ],
          label: t('menus:financial'),
          icon: <FaMoneyBillWave />,
          items: [
            {
              type: 'sub-item',
              key: 'expense-management',
              permission: ['@expenses/view'],
              label: t('menus:expenses'),
              url: '/despesas-financeiras',
              icon: <FaFileInvoice />,
            },
            {
              type: 'sub-item',
              key: 'cards',
              permission: ['@cards/view'],
              label: t('menus:cards'),
              url: '/cartoes',
              icon: <FaCreditCard />,
            },
            {
              type: 'sub-item',
              key: 'assets',
              permission: ['@assets/view'],
              label: t('menus:assets'),
              url: '/patrimonios',
              icon: <FaBuilding />,
            },
            {
              type: 'sub-item',
              key: 'extras',
              permission: ['@extras/view'],
              label: t('menus:Receita.Extra'),
              url: '/receitas-extra',
              icon: <FaMoneyBillAlt />,
            },
            {
              type: 'sub-item',
              key: 'despesasextras',
              permission: ['@extraExpenses/view'],
              label: t('menus:Desp.Extra'),
              url: '/despesas-extra',
              icon: <FaMoneyBillAlt />,
            },
            {
              type: 'sub-item',
              key: 'events-receipt',
              permission: ['@eventsReceipts/view'],
              label: t('menus:events-receipt'),
              url: '/receita-eventos',
              icon: <FaTicketAlt />,
            },
            {
              type: 'sub-item',
              key: 'general-balance',
              permission: ['@balance/view'],
              label: t('menus:general-balance'),
              url: '/balanco-geral',
              icon: <FaBalanceScale />,
            },
            {
              type: 'sub-item',
              key: 'reports',
              permission: ['@reports/view'],
              label: t('menus:Relatórios'),
              url: '/relatorios',
              icon: <FaBalanceScale />,
            },
          ],
        },
        {
          type: 'sub',
          key: 'adm',
          permission: [
            '@operationals/view',
            '@services/view',
            '@eventsExpenses/view',
            '@clients/view',
            '@portfolios/view',
            '@products/view',
            '@lots/view',
            '@lowStock/view',
            '@users/view',
            '@employees/view',
          ],
          label: t('menus:adm'),
          icon: <FaSuitcase />,
          items: [
            {
              type: 'title',
              key: 'separator-events',
              permission: ['@operationals/view', '@services/view', '@eventsExpenses/view', '@clients/view'],
              label: t('menus:capsEvents'),
            },
            {
              type: 'sub-item',
              key: 'operationals',
              permission: ['@operationals/view'],
              label: t('menus:operational'),
              icon: <FaTools />,
              url: '/operacional',
            },
            {
              type: 'sub-item',
              key: 'services',
              permission: ['@services/view'],
              label: t('menus:services'),
              icon: <FaToolbox />,
              url: '/servicos',
            },
            {
              type: 'sub-item',
              key: 'expenses',
              permission: ['@eventsExpenses/view'],
              label: t('menus:expenses'),
              icon: <FaFileInvoice />,
              url: '/evento-despesas',
            },
            {
              type: 'sub-item',
              key: 'clients',
              permission: ['@clients/view'],
              label: t('menus:clients'),
              icon: <FaUsers />,
              url: '/clientes',
            },
            {
              type: 'sub-item',
              key: 'portfolios',
              permission: ['@portfolios/view'],
              label: t('menus:portfolios'),
              icon: <FaFolder />,
              url: '/portfolios',
            },
            {
              type: 'title',
              key: 'separator-stock',
              permission: ['@products/view', '@lots/view', '@lowStock/view'],
              label: t('menus:capsStock'),
            },
            {
              type: 'sub-item',
              key: 'products',
              permission: ['@products/view'],
              label: t('menus:products'),
              icon: <FaTags />,
              url: '/produtos',
            },
            {
              type: 'sub-item',
              key: 'lots',
              permission: ['@lots/view'],
              label: t('menus:lots'),
              icon: <FaBoxes />,
              url: '/lotes',
            },
            {
              type: 'sub-item',
              key: 'inventory-reductions',
              permission: ['@lowStock/view'],
              label: t('menus:inventoryReductions'),
              icon: <FaBoxOpen />,
              url: '/baixas-estoques',
            },
            {
              type: 'title',
              key: 'separator-access-control',
              permission: ['@users/view', '@employees/view'],
              label: t('menus:accessControl'),
            },
            {
              type: 'sub-item',
              key: 'users',
              permission: ['@users/view'],
              label: t('menus:users'),
              icon: <FaUsers />,
              url: '/usuarios',
            },
            {
              type: 'sub-item',
              key: 'employees',
              permission: ['@employees/view'],
              label: t('menus:employees'),
              icon: <FaIdBadge />,
              url: '/funcionarios',
            },
          ],
        },
        {
          type: 'item',
          key: 'events',
          permission: ['@events/view'],
          label: t('menus:events'),
          icon: <FaCalendarAlt />,
          url: '/eventos',
        },
        {
          type: 'item',
          key: 'roles',
          permission: ['@roles/view'],
          label: t('menus:roles'),
          icon: <FaUsers />,
          url: '/grupo-de-usuarios',
        },
      ].filter((item) => checkAuth(item.permission)),
    [t, permissionsList]
  );

  // Encontrar chave do item selecionado
  useEffect(() => {
    const findSelectedKey = (items, currentPath) => {
      for (const item of items) {
        if (item.url === currentPath) return item.key;
        if (item.items) {
          const foundKey = findSelectedKey(item.items, currentPath);
          if (foundKey) return foundKey;
        }
      }
      return null;
    };

    const selectedKey = findSelectedKey(sideMenuItems, location.pathname);
    if (selectedKey) {
      setSelectedKeys([selectedKey]);

      // Abrir submenus se um sub-item estiver selecionado
      const openSubmenus = sideMenuItems
        .filter((item) => item.items?.some((subItem) => subItem.key === selectedKey))
        .map((item) => item.key);

      setOpenKeys(openSubmenus);
    }
  }, [location.pathname, sideMenuItems]);

  // Renderizar menu
  const renderMenu = (item) => {
    const { type, key, icon, label, url, items } = item;

    if (type === 'sub') {
      return {
        key,
        icon,
        label,
        children: items.map((subItem) => renderMenu(subItem)),
      };
    }

    if (type === 'sub-item') {
      if (key === 'portfolios') {
        return {
          key,
          icon,
          label: (
            <Link disabled to={null}>
              {label}
            </Link>
          ),
        };
      }
      return {
        key,
        icon,
        label: <Link to={url}>{label}</Link>,
      };
    }

    if (type === 'title') {
      return {
        key,
        label: <span className="ant-menu-item-title">{label}</span>,
      };
    }

    return {
      key,
      icon,
      label: <Link to={url}>{label}</Link>,
    };
  };

  return (
    <AntMenu
      id="sideMenu"
      mode="inline"
      selectedKeys={selectedKeys}
      openKeys={openKeys}
      onOpenChange={(keys) => setOpenKeys(keys)}
      onClick={(e) => setSelectedKeys([e.key])}
      items={sideMenuItems.map(renderMenu)}
    />
  );
}
