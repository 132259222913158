const routesWithPermissions = [
  { path: '/dashboard', permission: '@dashboard/view' },
  { path: '/despesas-financeiras', permission: '@expenses/view' },
  { path: '/cartoes', permission: '@cards/view' },
  { path: '/patrimonios', permission: '@assets/view' },
  { path: '/receitas-extra', permission: '@extras/view' },
  { path: '/despesas-extra', permission: '@extraExpenses/view' },
  { path: '/receita-eventos', permission: '@eventsReceipts/view/view' },
  { path: '/balanco-geral', permission: '@balance/view' },
  { path: '/relatorios', permission: '@reports/view' },
  { path: '/operacional', permission: '@operationals/view' },
  { path: '/servicos', permission: '@services/view' },
  { path: '/evento-despesas', permission: '@eventsExpenses/view' },
  { path: '/clientes', permission: '@clients/view' },
  { path: '/portfolios', permission: '@portfolios/view' },
  { path: '/produtos', permission: '@products/view' },
  { path: '/lotes', permission: '@lots/view' },
  { path: '/baixas-estoques', permission: '@lowStock/view' },
  { path: '/usuarios', permission: '@users/view' },
  { path: '/funcionarios', permission: '@employees/view' },
  { path: '/eventos', permission: '@events/view' },
  { path: '/grupo-de-usuarios', permission: '@roles/view' },
];

export const getFirstAllowedRoute = (permissionList) => {
  if (permissionList.includes('@superAdmin')) {
    return '/dashboard';
  }

  const allowedRoute = routesWithPermissions.find((route) => permissionList.includes(route.permission));

  return allowedRoute?.path || '/404';
};
