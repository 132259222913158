import { message, Modal, Spin, Card, Input as antdInput, Col, Typography } from 'antd';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '~/components/Form/FormControl';
import InputCurrency from '~/components/Form/InputCurrency';
import { ModalFooter } from '~/components/Modal';
import { createItems, updateItems } from '~/services/hooks/items';
import { useCheckAuth } from '~/components/Can/checkAuth';
import Row from '~/components/Row';
import { Table, TableHeader } from '~/components/Table';
import { historyTable, lowStockHistoryTable } from './table';
import { getStockWriteOffsItems } from '~/services/hooks/stockWriteOffsItems';
import Pagination from '~/components/Pagination';

const initial = {
  name: null,
  weight: null,
  sale_price: null,
  amount_per_pack: null,
};

export default function ItemsModal({ visible, onClose, itemsEdit, isMobile }) {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(initial);
  const [lowStocks, setLowStocks] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState({});
  const [search, setSearch] = useState('');

  const hanldeSave = async (values) => {
    setLoading(true);
    try {
      if (itemsEdit) {
        await updateItems(itemsEdit.id, values);
        message.success(t('messages:successUpdate'));
      } else {
        await createItems(values);
        message.success(t('messages:successCreate'));
      }
      onClose();
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao salvar o produto. Erro: ') + message.message);
    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          page,
          perPage,
          search,
        },
      };
      const data = await getStockWriteOffsItems(params, `get-by-item/${itemsEdit?.id}`);

      setLowStocks(data.data);
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao carregas as baixas. Erro: ') + message.message);
    }
    setLoading(false);
  };

  const fluidPermissions = () => {
    if (itemsEdit) {
      return authCheck('@products/edit');
    } else {
      return authCheck('@products/create');
    }
  };

  useEffect(() => {
    if (visible) {
      if (itemsEdit) {
        setInitialValues(itemsEdit);
        console.log(itemsEdit);
      }
    }
  }, [visible]);

  useEffect(() => {
    if (initialValues && visible) {
      fetchData();
    }
  }, [initialValues, search, page]);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={hanldeSave}
    >
      {({ isSubmitting, submitForm, values }) => (
        <Modal
          title={(itemsEdit ? t('Editando produto - ') : t('Criando produto - ')) + (values.name ?? '')}
          onCancel={onClose}
          afterClose={() => setInitialValues(initial)}
          open={visible}
          centered
          width={isMobile ? '100%' : '800px'}
          footer={<ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} />}
        >
          <Spin spinning={loading}>
            <Card style={{ marginBottom: 20 }}>
              <Row>
                <FormControl cols={{ sm: 12, xs: 12 }} label={t('fields:name')} field="name">
                  <Input name="name" placeholder={t('fields:name')} disabled={!fluidPermissions()} />
                </FormControl>
                <FormControl cols={{ sm: 12, xs: 12 }} label={t('fields:items.weigth')} field="weight">
                  <Input name="weight" placeholder={t('fields:items.weigth')} disabled={!fluidPermissions()} />
                </FormControl>
              </Row>
              <Row>
                <FormControl cols={{ sm: 8, xs: 24 }} label={t('fields:items.salePrice')} field="sale_price">
                  <InputCurrency
                    name="sale_price"
                    placeholder={t('fields:items.salePrice')}
                    disabled={!fluidPermissions()}
                  />
                </FormControl>
                <FormControl cols={{ sm: 8, xs: 12 }} label={t('fields:items.amountPerPack')} field="amount_per_pack">
                  <Input
                    name="amount_per_pack"
                    placeholder={t('fields:items.amountPerPack')}
                    disabled={!fluidPermissions()}
                  />
                </FormControl>
                <FormControl cols={{ sm: 8, xs: 12 }} label={t('Qtde em estoque')} field="amount">
                  <Input name="amount" placeholder={t('fields:items.amountPerPack')} disabled />
                </FormControl>
              </Row>
            </Card>
            <Typography.Title level={3} style={{ marginTop: 20 }}>
              {t('Histórico de entrada') +
                ' - ' +
                t('Média: R$ ') +
                parseFloat(values.weighted).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
            </Typography.Title>
            <Table
              pagination={false}
              rowKey="id"
              loading={loading}
              dataSource={values.batches_history}
              columns={historyTable(isMobile)}
              bordered
            />
            <Typography.Title level={3} style={{ marginTop: 20 }}>
              {t('Histórico de consumo') +
                ' - ' +
                t('Média: R$ ') +
                parseFloat(values.weighted_swoi).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
            </Typography.Title>
            <Col>
              <TableHeader>
                <antdInput.Search
                  onSearch={(value) => {
                    setSearch(value);
                    setPage(1);
                  }}
                  className="search-field"
                  placeholder={t('screens:default.search')}
                />
              </TableHeader>
              <Table
                pagination={false}
                rowKey="id"
                loading={loading}
                dataSource={lowStocks}
                columns={lowStockHistoryTable(isMobile)}
                bordered
              />
              <Pagination
                isMobile={isMobile}
                onChange={(value) => {
                  setPage(value);
                }}
                meta={meta}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </Col>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
