import { message, Modal, Spin, Row } from 'antd';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import { createServices, updateServices } from '~/services/hooks/services';
import { useCheckAuth } from '~/components/Can/checkAuth';

const initial = {
  name: null,
};

export default function ServicesModal({ visible, onClose, servicesEdit, isMobile }) {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(initial);

  const handleSave = async (values) => {
    setLoading(true);
    try {
      if (servicesEdit) {
        await updateServices(values.id, values);
        message.success(t('messages:successUpdate'));
      } else {
        await createServices(values);
        message.success(t('messages:successCreate'));
      }
      onClose();
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao salvar o serviço. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const fluidPermissions = () => {
    if (servicesEdit) {
      return authCheck('@services/edit');
    } else {
      return authCheck('@services/create');
    }
  };

  useEffect(() => {
    if (visible) {
      if (servicesEdit) {
        setInitialValues(servicesEdit);
      }
    }
  }, [visible]);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSave}
    >
      {({ isSubmitting, submitForm, values }) => (
        <Modal
          title={t(servicesEdit ? 'Editando serviço - ' : 'Criando serviço - ') + (values.name ?? '')}
          onCancel={onClose}
          afterClose={() => setInitialValues(initial)}
          open={visible}
          centered
          width={isMobile ? '100%' : '800px'}
          footer={<ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} />}
        >
          <Spin spinning={loading}>
            <Row>
              <FormControl cols={{ xs: 24 }} label={t('Nome')} field="name">
                <Input name="name" placeholder={t('fields:name')} disabled={!fluidPermissions()} />
              </FormControl>
            </Row>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
