import api from '~/services/api';
import PropTypes from 'prop-types';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'roles-users';

// GET
export const getRolesUsers = async (params, route = '') => {
  try {
    const { data } = await api.get(`/${endPoint}/${route}`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

// CREATE
export const createRolesUsers = async (values) => {
  try {
    const { data } = await api.post(`/${endPoint}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// UPDATE
export const updateRolesUsers = async (values, route = '') => {
  try {
    const { data } = await api.put(`/${endPoint}/${route}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// DELETE
export const deleteRolesUsers = async (id) => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successDelete`));
    return data;
  } catch (error) {
    throw error;
  }
};

getRolesUsers.propTypes = {
  params: PropTypes.object.isRequired,
};

createRolesUsers.propTypes = {
  values: PropTypes.object.isRequired,
};

updateRolesUsers.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteRolesUsers.propTypes = {
  id: PropTypes.string.isRequired,
};
