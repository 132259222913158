import api from '~/services/api';

const endPoint = 'cities';

// GET ALL
export const getCities = async (params, route = '') => {
  try {
    const { data } = await api.get(`/${endPoint}/${route}`, params);
    return data;
  } catch (error) {
    throw error;
  }
};
