export function signInRequest(username, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { username, password },
  };
}

export function signInSuccess(token, user, version, permissions, roles) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user, version, permissions, roles },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signOutRequest() {
  return {
    type: '@auth/SIGN_OUT_REQUEST',
  };
}

export function signOutSuccess() {
  return {
    type: '@auth/SIGN_OUT_SUCCESS',
  };
}

export function tokenExpired() {
  return {
    type: '@auth/TOKEN_EXPIRED',
  };
}

export function rememberCredentials(username, password, checked) {
  return {
    type: '@auth/REMEMBER_CREDENTIALS',
    payload: { username, password, checked },
  };
}
