import React from 'react';
import moment from 'moment'; //npm install moment
import 'moment/locale/pt-br';
import { TableActions } from '~/components/Table';
import Can from '~/components/Can';
import Button from '~/components/Button';
import { FaEye } from 'react-icons/fa';
import Strong from '~/components/Strong';

export const tableColumns = (t, handleModalEvent, isMobile) => {
  if (isMobile) {
    return [
      {
        dataIndex: 'summary',
        key: 'summary',
        render: (text, record) => (
          <div>
            <Strong>{t('screens:default.date')}:</Strong>{' '}
            {moment(record.event.starts_at).locale('pt-br').format('DD/MM/YYYY')}
            <br />
            <Strong>{t('screens:default.name')}:</Strong> {record.event.name}
            <br />
            <Strong>{t('screens:eventsRevenues.total_receipts')}:</Strong>{' '}
            {Number(record.total_receipts || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            <br />
            <Strong>{t('screens:eventsRevenues.total_agio')}:</Strong>{' '}
            {Number(record.total_agio || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            <br />
            <Strong>{t('Sobra / Falta de dinheiro')}:</Strong>{' '}
            {Number(record.total_losses || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            <br />
            <Strong>{t('Valor venda bebidas nobre')}:</Strong>{' '}
            {Number(record.total_drinks_brute || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            <br />
            <Strong>{t('Valor total bruto de receitas')}:</Strong>{' '}
            {Number(record.total_receipt_brute || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            <br />
            <Strong>{t('Valor Total líquido de receitas')}:</Strong>{' '}
            {Number(record.total_receipt_final || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            <br />
            <TableActions align="center" justify="flex" isMobile={isMobile}>
              <Can permission="@events/view">
                <Button
                  style={{ margin: 'auto' }}
                  size="small"
                  title="Visualizar"
                  onClick={() => handleModalEvent(record?.event)}
                >
                  <FaEye />
                </Button>
              </Can>
            </TableActions>
          </div>
        ),
      },
    ];
  }

  return [
    {
      title: t('screens:default.date'),
      dataIndex: 'starts_at',
      key: 'starts_at',
      render: (text, record) => moment(record.event.starts_at).locale('pt-br').format('DD/MM/YYYY'), //formatar data
    },
    {
      title: t('screens:default.name'),
      dataIndex: 'name',
      key: 'name',
      width: '900px',
      render: (text, record) => record.event.name,
    },
    {
      title: t('screens:eventsRevenues.total_receipts'),
      dataIndex: 'total_receipts',
      key: 'total_receipts',
      width: '300px',
      render: (text, record) =>
        Number(record.total_receipts || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // valor padrão 000
    },
    {
      title: t('screens:eventsRevenues.total_agio'),
      dataIndex: 'total_agio',
      key: 'total_agio',
      width: '300px',
      render: (text, record) =>
        Number(record.total_agio || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // valor padrão 000
    },
    {
      title: t('Sobra / Falta de dinheiro'),
      dataIndex: 'final_price',
      key: 'final_price',
      width: '300px',
      render: (text, record) =>
        Number(record.total_losses || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // valor padrão 000
    },
    {
      title: t('Valor venda bebidas nobre'),
      dataIndex: 'total_receipt',
      key: 'total_receipt',
      width: '300px',
      render: (text, record) =>
        Number(record.total_drinks_brute || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // valor padrão 000
    },
    {
      title: t('Valor total bruto de receitas'),
      dataIndex: 'total_receipt_brute',
      key: 'total_receipt_brute',
      width: '300px',
      render: (text, record) =>
        Number(record.total_receipt_brute || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // valor padrão 000
    },
    {
      title: t('Valor Total líquido de receitas'),
      dataIndex: 'total_cost',
      key: 'total_cost',
      width: '300px',
      render: (text, record) =>
        Number(record.total_receipt_final || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // valor padrão 000
    },
    {
      title: t('screens:default.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => {
        console.log(record);
        return (
          <TableActions align="center" justify="center">
            <Can permission="@events/view">
              <Button
                style={{ margin: 'auto' }}
                size="small"
                title="Visualizar"
                onClick={() => handleModalEvent(record?.event)}
              >
                <FaEye />
              </Button>
            </Can>
          </TableActions>
        );
      },
    },
  ];
};
