import api from '~/services/api';
import PropTypes from 'prop-types';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'roles';

// GET
export const getRoles = async (params, route = '') => {
  try {
    const { data } = await api.get(`/${endPoint}/${route}`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

// CREATE
export const createRoles = async (values) => {
  try {
    const { data } = await api.post(`/${endPoint}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// UPDATE
export const updateRoles = async (route = '', values) => {
  try {
    const { data } = await api.put(`/${endPoint}/${route}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// DELETE
export const deleteRoles = async (id) => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successDelete`));
    return data;
  } catch (error) {
    throw error;
  }
};

getRoles.propTypes = {
  params: PropTypes.object.isRequired,
};

createRoles.propTypes = {
  values: PropTypes.object.isRequired,
};

updateRoles.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteRoles.propTypes = {
  id: PropTypes.string.isRequired,
};
