import api from '~/services/api';
import PropTypes from 'prop-types';

const endPoint = 'operationals';

// GET
export const getOperationals = async (params, route = '') => {
  try {
    const { data } = await api.get(`/${endPoint}/${route}`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

// CREATE
export const createOperationals = async (values) => {
  try {
    const { data } = await api.post(`/${endPoint}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// UPDATE
export const updateOperationals = async (id, values) => {
  try {
    const { data } = await api.put(`/${endPoint}/${id}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// DELETE
export const deleteOperationals = async (id) => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

getOperationals.propTypes = {
  params: PropTypes.object.isRequired,
};

createOperationals.propTypes = {
  values: PropTypes.object.isRequired,
};

updateOperationals.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteOperationals.propTypes = {
  id: PropTypes.string.isRequired,
};
