import { Popconfirm } from 'antd';
import i18next from 'i18next';
import React from 'react';
import { FaEye, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import Button from '~/components/Button';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import Strong from '~/components/Strong';
import { TableActions } from '~/components/Table';

export const tableColumns = (handleDelete, handleEdit, isMobile) => {
  if (isMobile) {
    return [
      {
        dataIndex: 'summary',
        key: 'summary',
        render: (text, record) => (
          <div>
            <Strong>{i18next.t('screens:Lote')}:</Strong> {record?.id}
            <br />
            <Strong>{i18next.t('screens:default.description')}:</Strong> {record?.description}
            <br />
            <Strong>{i18next.t('screens:default.createdBy')}:</Strong>{' '}
            {record?.created_by_id ? record.created_by_id.name : 'Desconhecido'}
            <br />
            <Strong>{i18next.t('screens:default.createdAt')}:</Strong>{' '}
            {new Date(record?.created_at).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}
            <br />
            <TableActions align="center" justify="center" isMobile={isMobile}>
              <Can permission="@lots/edit">
                <Button size="small" title="Editar" onClick={() => handleEdit(record)}>
                  <FaPencilAlt />
                </Button>
              </Can>
              <Cannot permission="@lots/edit">
                <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record)}>
                  <FaEye />
                </Button>
              </Cannot>
              <Can permission="@lots/delete">
                <Popconfirm
                  title={i18next.t('messages:confirmDelete')}
                  okText={i18next.t('messages:yes')}
                  onConfirm={() => handleDelete(record.id)}
                  cancelText={i18next.t('messages:no')}
                >
                  <Button size="small" title="Excluir">
                    <FaRegTrashAlt />
                  </Button>
                </Popconfirm>
              </Can>
            </TableActions>
          </div>
        ),
      },
    ];
  }

  return [
    {
      title: i18next.t('screens:Lote'),
      dataIndex: 'id',
      width: '6%',
      key: 'id',
      render: (text, record) => record.id,
    },
    {
      title: i18next.t('screens:default.description'),
      dataIndex: 'description',
      key: 'description',
      width: '30%',
      render: (text, record) => record.description,
    },
    {
      title: i18next.t('screens:default.createdBy'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (record?.created_by_id ? record.created_by_id.name : 'Desconhecido'),
    },
    {
      title: i18next.t('screens:default.createdAt'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, record) => {
        const date = new Date(record.created_at);
        return date.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
      },
    },
    {
      title: i18next.t('screens:default.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '70px',
      align: 'center',
      render: (text, record) => (
        <TableActions align="center" justify="center">
          <Can permission="@lots/edit">
            <Button size="small" title="Editar" onClick={() => handleEdit(record)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@lots/edit">
            <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record)}>
              <FaEye />
            </Button>
          </Cannot>
          <Can permission="@lots/delete">
            <Popconfirm
              title={i18next.t('messages:confirmDelete')}
              okText={i18next.t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={i18next.t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          </Can>
        </TableActions>
      ),
    },
  ];
};
