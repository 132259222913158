import styled from 'styled-components';
import FC from '@fullcalendar/react'; // Importando corretamente o FullCalendar

// Estilizando o FullCalendar
export const FullCalendar = styled(FC)`
  .my-custom-day-header {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border-radius: 5px;
  }

  .my-custom-day-cell {
    background-color: #f1f8e9;
    border: 1px solid #4caf50;
    padding: 5px;
  }

  /* Ajustes para células do calendário */
  .fc .fc-daygrid-day-frame {
    min-height: 6rem !important; /* Altura mínima das células */
  }

  /* Cabeçalhos menores */
  .fc .fc-col-header-cell-cushion {
    font-size: 0.8rem !important;
    padding: 4px !important;
  }

  /* Botões de navegação menores */
  .fc .fc-button {
    padding: 0.3em 0.6em !important;
    font-size: 0.8rem !important;
  }
`;
