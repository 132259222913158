import { message, Modal, Spin, Card } from 'antd';
import { Formik } from 'formik';
import { Input, Radio } from 'formik-antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { createEmployees, updateEmployees } from '~/services/hooks/employees';
import { useCheckAuth } from '~/components/Can/checkAuth';
import MaskedInput from '~/components/MaskedInput';
import { SelectAntd, SelectAntdFormik } from '~/components/Pagination/styles';
import { getStates } from '~/services/hooks/states';

const initial = {
  name: null,
  address: null,
  cpf: null,
  rg: null,
  phone: null,
  work_reference: null,
  email: null,
  classification: null,
  bank_name: null,
  bank_agency: null,
  bank_account_number: null,
  bank_account_type: null,
  bank_account_operation: null,
  city_id: null,
  note: null,
  emergency_name: null,
  emergency_phone: null,
};

export default function EmployeesModal({ visible, onClose, employeesEdit, isModal }) {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(initial);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [cityEnable, setCityEnable] = useState(false);

  const fetchStates = async () => {
    setLoading(true);
    try {
      const response = await getStates('', 'no-paginated');
      setStates(response);
    } catch (error) {
      console.log(error);
      message.error(t('Não foi possível carregar os estados. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const handleSave = async (record) => {
    setLoading(true);
    try {
      employeesEdit ? await updateEmployees(employeesEdit.id, record) : await createEmployees(record);
      message.success(t('messages:successCreate'));
      onClose();
    } catch (error) {
      console.log(error);
      message.error(t('Não foi possível salvar o funcionário. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const handleStateSelect = (value) => {
    if (value) {
      setCityEnable(true);
      const selected = states.find((state) => state.id === value);
      setSelectedState(selected);
    }
  };

  const fluidPermissions = () => {
    if (employeesEdit) {
      return authCheck('@employees/edit');
    } else {
      return authCheck('@employees/create');
    }
  };

  useEffect(() => {
    if (visible) {
      fetchStates();

      if (employeesEdit) {
        if (employeesEdit?.city_id) {
          const stateMatch = states?.find((s) => s.cities?.some((c) => c.id === employeesEdit.city_id));
          setSelectedState(stateMatch);
          setCityEnable(true);
        }

        setInitialValues(employeesEdit);
      }
    }
  }, [visible]);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSave}
    >
      {({ isSubmitting, submitForm }) => (
        <Modal
          title={
            (employeesEdit ? t('Editando funcionário - ') : t('criando funcionário - ')) + (employeesEdit?.name ?? '')
          }
          onCancel={onClose}
          afterClose={() => setInitialValues(initial)}
          open={visible}
          centered
          width={isModal ? '100%' : '1200px'}
          footer={<ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} />}
        >
          <Spin spinning={loading}>
            <Card>
              <Row>
                <FormControl cols={{ sm: 8, xs: 24 }} label={t('fields:employees.name')} field="name">
                  <Input name="name" placeholder={t('Nome')} disabled={!fluidPermissions()} />
                </FormControl>
                <FormControl cols={{ sm: 8, xs: 12 }} label={t('fields:employees.email')} field="email">
                  <Input name="email" placeholder={t('Email')} disabled={!fluidPermissions()} />
                </FormControl>
                <FormControl cols={{ sm: 8, xs: 12 }} label={t('fields:employees.phone')} field="phone">
                  <MaskedInput
                    name="phone"
                    mask="(99) 99999-9999"
                    placeholder="(    )        -    "
                    disabled={!fluidPermissions()}
                  />
                </FormControl>
              </Row>
              <Row>
                <FormControl cols={{ xs: 24 }} label={t('fields:employees.address')} field="address">
                  <Input.TextArea name="address" placeholder={t('Endereço')} disabled={!fluidPermissions()} />
                </FormControl>
                <FormControl cols={{ sm: 6, xs: 12 }} label={t('fields:employees.cpf')} field="cpf">
                  <MaskedInput
                    name="cpf"
                    mask="999.999.999-99"
                    placeholder="___.___.___-__"
                    style={{ width: '100%' }}
                    disabled={!fluidPermissions()}
                  />
                </FormControl>
                <FormControl cols={{ sm: 6, xs: 12 }} label={t('fields:employees.rg')} field="rg">
                  <Input
                    name="rg"
                    placeholder={t('RG')}
                    disabled={!fluidPermissions()}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                  />
                </FormControl>
                <FormControl cols={{ sm: 6, xs: 12 }} field="state_id" label={t('fields:event.state')}>
                  <SelectAntd
                    placeholder="SELECIONE"
                    value={selectedState?.id}
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    onSelect={handleStateSelect}
                  >
                    {states.map((state) => (
                      <SelectAntd.Option key={state.id} value={state.id}>
                        {state.name}
                      </SelectAntd.Option>
                    ))}
                  </SelectAntd>
                </FormControl>
                <FormControl cols={{ sm: 6, xs: 12 }} field="city_id" label={t('fields:event.city')}>
                  <SelectAntdFormik
                    name="city_id"
                    placeholder="SELECIONE"
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    loading={loading}
                    disabled={!cityEnable || !fluidPermissions()}
                  >
                    {selectedState?.cities?.map((city) => (
                      <SelectAntd.Option key={city.id} value={city.id}>
                        {city.name}
                      </SelectAntd.Option>
                    ))}
                  </SelectAntdFormik>
                </FormControl>
              </Row>
              <Row>
                <FormControl
                  cols={{ sm: 8, xs: 12 }}
                  label={t('fields:employees.work_reference')}
                  field="work_reference"
                >
                  <Input
                    name="work_reference"
                    placeholder={t('Referência de Trabalho')}
                    disabled={!fluidPermissions()}
                  />
                </FormControl>
                <FormControl
                  cols={{ sm: 8, xs: 12 }}
                  label={t('fields:employees.emergency_name')}
                  field="emergency_name"
                >
                  <Input name="emergency_name" placeholder={t('Nome de Emergência')} disabled={!fluidPermissions()} />
                </FormControl>

                <FormControl
                  cols={{ sm: 8, xs: 24 }}
                  label={t('fields:employees.emergency_phone')}
                  field="emergency_phone"
                >
                  <Input
                    name="emergency_phone"
                    placeholder={t('Telefone de Emergência')}
                    disabled={!fluidPermissions()}
                  />
                </FormControl>
              </Row>
              <Row>
                <FormControl cols={{ sm: 4, xs: 12 }} label={t('fields:employees.bank_name')} field="bank_name">
                  <Input name="bank_name" placeholder={t('Nome do Banco')} disabled={!fluidPermissions()} />
                </FormControl>
                <FormControl cols={{ sm: 4, xs: 12 }} label={t('fields:employees.bank_agency')} field="bank_agency">
                  <Input name="bank_agency" placeholder={t('Agência Bancária')} disabled={!fluidPermissions()} />
                </FormControl>
                <FormControl cols={{ sm: 4, xs: 12 }} label={t('Operação')} field="bank_account_operation">
                  <Input name="bank_account_operation" placeholder={t('Operação')} disabled={!fluidPermissions()} />
                </FormControl>
                <FormControl
                  cols={{ sm: 4, xs: 12 }}
                  label={t('fields:employees.bank_account_number')}
                  name="bank_account_number"
                >
                  <Input name="bank_account_number" placeholder={t('Número da Conta')} disabled={!fluidPermissions()} />
                </FormControl>
                <FormControl
                  cols={{ sm: 8, xs: 24 }}
                  label={t('fields:employees.bank_account_type')}
                  field="bank_account_type"
                >
                  <Radio.Group
                    name="bank_account_type"
                    defaultValue={0}
                    options={[
                      { value: 0, label: t('Conta corrente') },
                      { value: 1, label: t('Conta poupança') },
                    ]}
                    disabled={!fluidPermissions()}
                  />
                </FormControl>
              </Row>
              <Row>
                <FormControl cols={{ sm: 4, xs: 24 }} label={t('Classificação')} field="classification">
                  <Radio.Group
                    name="classification"
                    defaultValue={0}
                    options={[
                      { value: 0, label: t('A') },
                      { value: 1, label: t('B') },
                      { value: 2, label: t('C') },
                    ]}
                    disabled={!fluidPermissions()}
                  />
                </FormControl>
                <FormControl cols={{ sm: 20, xs: 24 }} label={t('fields:employees.note')} field="note">
                  <Input.TextArea name="note" placeholder={t('Nota')} disabled={!fluidPermissions()} />
                </FormControl>
              </Row>
            </Card>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
