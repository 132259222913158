import { message, Modal, Spin } from 'antd';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateComponent from '~/components/DateComponent';
import FormControl from '~/components/Form/FormControl';
import InputCurrency from '~/components/Form/InputCurrency';
import { ModalFooter } from '~/components/Modal';
import { createExtraRevenues, updateExtraRevenues } from '~/services/hooks/extraRevenues';
import { useCheckAuth } from '~/components/Can/checkAuth';
import Row from '~/components/Row';

const initial = {
  name: null,
  description: null,
  price: null,
  acquired_at: null,
};

export default function ExtraRevenuesModal({ visible, onClose, extraRevenuesEdit, isMobile }) {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(initial);

  const handleSave = async (values) => {
    setLoading(true);
    try {
      if (extraRevenuesEdit) {
        await updateExtraRevenues(values.id, values);
        message.success(t('messages:successUpdate'));
      } else {
        await createExtraRevenues(values);
        message.success(t('messages:successCreate'));
      }
      onClose();
    } catch (error) {
      console.log(error);
      message.error(t('Não foi possível salvar a receita. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const fluidPermissions = () => {
    if (extraRevenuesEdit) {
      return authCheck('@extras/edit');
    } else {
      return authCheck('@extras/create');
    }
  };

  useEffect(() => {
    if (visible) {
      if (extraRevenuesEdit) {
        setInitialValues(extraRevenuesEdit);
      }
    }
  }, [visible]);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSave}
    >
      {({ isSubmitting, submitForm, setFieldValue, values }) => (
        <Modal
          title={
            (extraRevenuesEdit ? t('Editando receita extra - ') : t('Criando receita extra - ')) + (values.name ?? '')
          }
          onCancel={onClose}
          afterClose={() => setInitialValues(initial)}
          open={visible}
          centered
          width={isMobile ? '100%' : '800px'}
          footer={<ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} />}
        >
          <Spin spinning={loading}>
            <Row>
              <FormControl cols={{ sm: 8, xs: 24 }} label={t('screens:default.name')} field="name">
                <Input name="name" disabled={!fluidPermissions()} />
              </FormControl>
              <FormControl cols={{ sm: 8, xs: 12 }} label={t('screens:default.price')} field="price">
                <InputCurrency name="price" disabled={!fluidPermissions()} />
              </FormControl>
              <FormControl cols={{ sm: 8, xs: 12 }} label={t('screens:default.acquiredAt')} field="acquired_at">
                <DateComponent
                  value={values.acquired_at}
                  onChange={(date) => setFieldValue('acquired_at', date)}
                  disabled={!fluidPermissions()}
                />
              </FormControl>
            </Row>
            <Row>
              <FormControl cols={{ xs: 24 }} label={t('screens:default.description')} field="description">
                <Input.TextArea name="description" disabled={!fluidPermissions()} />
              </FormControl>
            </Row>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
