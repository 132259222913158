import api from '~/services/api';
import PropTypes from 'prop-types';

const endPoint = 'patrimonies';

// GET
export const getPatrimonies = async (params) => {
  try {
    const { data } = await api.get(`/${endPoint}`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

// CREATE
export const createPatrimonies = async (values) => {
  try {
    const { data } = await api.post(`/${endPoint}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// UPDATE
export const updatePatrimonies = async (id, values) => {
  try {
    const { data } = await api.put(`/${endPoint}/${id}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// DELETE
export const deletePatrimonies = async (id) => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

getPatrimonies.propTypes = {
  params: PropTypes.object.isRequired,
};

createPatrimonies.propTypes = {
  values: PropTypes.object.isRequired,
};

updatePatrimonies.propTypes = {
  values: PropTypes.object.isRequired,
};

deletePatrimonies.propTypes = {
  id: PropTypes.string.isRequired,
};
