import { message, Modal, Spin, Card, Button } from 'antd';
import { Formik, FieldArray } from 'formik';
import { Input } from 'formik-antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '~/components/Form/FormControl';
import { FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import { ModalFooter } from '~/components/Modal';
import { SelectAntd } from '~/components/Pagination/styles';
import Row from '~/components/Row';
import { updateCards } from '~/services/hooks/cards';
import { createCardsOperator } from '~/services/hooks/cardsOperator';
import { useCheckAuth } from '~/components/Can/checkAuth';

const initial = {
  name: null,
  cards: [],
};

export default function CardModal({ visible, onClose, cardEdit, isMobile }) {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(initial);

  const fluidPermissions = () => {
    if (cardEdit) {
      return authCheck('@cards/edit');
    } else {
      return authCheck('@cards/create');
    }
  };

  const saveCards = async (values) => {
    setLoading(true);
    try {
      if (cardEdit) {
        await updateCards(values.id, values);
        message.success(t('messages:cards.successPut'));
      } else {
        await createCardsOperator(values);
        message.success(t('messages:cards.successPost'));
      }
      onClose();
    } catch (error) {
      console.log(error);
      message.error(t('messages:cards.errorDel') + error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      if (cardEdit) {
        setInitialValues(cardEdit);
      }
    }
  }, [visible]);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={saveCards}
    >
      {({ isSubmitting, submitForm, values, setFieldValue }) => (
        <Modal
          title={t(cardEdit ? 'screens:cards.titlePut' : 'screens:cards.titlePost') + (values.name ?? '')}
          onCancel={onClose}
          style={{ maxWidth: isMobile ? '100%' : '1500px' }}
          afterClose={() => setInitialValues(initial)}
          open={visible}
          centered
          width="800px"
          footer={<ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} />}
        >
          <Spin spinning={loading}>
            <Row>
              <FormControl cols={{ xs: 12 }} field="name" label={t('fields:card_operator.name')}>
                <Input name="name" disabled={!fluidPermissions()} />
              </FormControl>
            </Row>
            <FieldArray
              name="cards"
              render={(arrayHelpers) => (
                <div>
                  {values.cards?.map((soloCard, index) => (
                    <Card
                      key={index}
                      style={{ marginBottom: '16px' }}
                      title={`Cartão ${index + 1}`}
                      extra={
                        <Button
                          color="danger"
                          title={t('screens:default.delete')}
                          onClick={() => arrayHelpers.remove(index)}
                          style={{
                            padding: '5px 10px',
                            borderRadius: '4px',
                            display: 'flex',
                          }}
                          disabled={!fluidPermissions()}
                        >
                          <FaRegTimesCircle color="red" />
                        </Button>
                      }
                    >
                      <Row gutter={16}>
                        <FormControl cols={{ xs: 10 }} field={`cards.${index}.card_type`} label={t('fields:card.type')}>
                          <SelectAntd
                            name={`cards.${index}.card_type`}
                            placeholder="Selecionar"
                            value={values.cards[index].card_type}
                            onChange={(value) => {
                              setFieldValue(`cards.${index}.card_type`, value);
                            }}
                            disabled={!fluidPermissions()}
                          >
                            <SelectAntd.Option value=""> {t('fields:card.all')} </SelectAntd.Option>
                            <SelectAntd.Option value={0}>{t('fields:card.debit')}</SelectAntd.Option>
                            <SelectAntd.Option value={1}>{t('fields:card.credit')}</SelectAntd.Option>
                            <SelectAntd.Option value={2}>{t('fields:card.pix')}</SelectAntd.Option>
                          </SelectAntd>
                        </FormControl>
                        <FormControl cols={{ xs: 10 }} field={`cards.${index}.fee`} label={t('fields:card.fee')}>
                          <Input name={`cards.${index}.fee`} disabled={!fluidPermissions()} />
                        </FormControl>
                      </Row>
                    </Card>
                  ))}
                  <div style={{ marginTop: '16px', textAlign: 'right' }}>
                    <Button
                      color="green"
                      onClick={() => arrayHelpers.push({ card_type: '', fee: '' })}
                      style={{
                        padding: '5px 10px',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      disabled={!fluidPermissions()}
                    >
                      <FaPlusCircle color="green" />
                    </Button>
                  </div>
                </div>
              )}
            />
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
