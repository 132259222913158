import { Button, Col } from 'antd';
import { InputNumber } from 'formik-antd';
import React, { useEffect, useState } from 'react';
import { FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import FormControl from '~/components/Form/FormControl';
import InputCurrency from '~/components/Form/InputCurrency';
import { SelectAntd } from '~/components/Pagination/styles';
import Row from '~/components/Row';
import { getServices } from '~/services/hooks/services';

export default function ServiceModal({
  values,
  setFieldValue,
  loading,
  setLoading,
  t,
  visible,
  errors,
  fluidPermissions,
}) {
  const [services, setServices] = useState([]);

  const fetchServices = async () => {
    setLoading(true);
    try {
      const services = await getServices('', 'no-paginated');
      setServices(services);
    } catch (error) {
      message.error(`Fala ao carregar serviços.
        Erro: ${error}`);
    }
    setLoading(false);
  };

  const handleAddService = () => {
    setLoading(true);
    try {
      values.services_events.push({
        event_id: values.id,
        amount: 0,
        price_unit: 0,
        price_total: 0,
        service_id: null,
      });
      setFieldValue('services_events', [...values.services_events]);
    } catch (error) {
      console.log(error);
      message.error(`Erro ao adicionar serviço. Error: ${error}`);
    }
    setLoading(false);
  };

  const handleRemoveService = (index) => {
    setLoading(true);
    try {
      values.services_events.splice(index, 1);
      setFieldValue('services_events', [...values.services_events]);
    } catch (error) {
      console.log(error);
      message.error(`Erro ao deletar serviço. Error: ${error}`);
    }
    setLoading(false);
  };

  const handleUpdateTotal = (index, field, value) => {
    const updatedServices = [...values.services_events];
    updatedServices[index][field] = value;
    updatedServices[index].price_total = updatedServices[index].amount * updatedServices[index].price_unit;
    setFieldValue('services_events', updatedServices);
  };

  useEffect(() => {
    if (visible) {
      fetchServices();
    }
  }, [visible]);

  return (
    <>
      {values?.services_events?.map((service_event, index) => (
        <Row key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
          <FormControl
            label={t('fields:event.service.name')}
            field={`services_events.${index}.service.id`}
            error={errors?.services_events?.[index]?.service?.id}
            required
            cols={{ sm: 6, xs: 24 }}
          >
            <SelectAntd
              placeholder="SELECIONE"
              value={service_event?.service?.id ?? ''}
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              onSelect={(value) => {
                setFieldValue(`services_events.${index}.service.id`, value);
              }}
              loading={loading}
              disabled={!fluidPermissions()}
            >
              {services?.map((item) => (
                <SelectAntd.Option key={item.id} value={item.id}>
                  {item.name}
                </SelectAntd.Option>
              ))}
            </SelectAntd>
          </FormControl>

          <FormControl
            label={t('fields:event.service.amount')}
            field={`services_events.${index}.amount`}
            error={errors?.services_events?.[index]?.amount}
            required
            cols={{ sm: 6, xs: 12 }}
          >
            <InputNumber
              name={`services_events.${index}.amount`}
              value={service_event.amount}
              onChange={(value) => handleUpdateTotal(index, 'amount', value)}
              disabled={!fluidPermissions()}
            />
          </FormControl>

          <FormControl
            label={t('fields:event.service.priceUnit')}
            field={`services_events.${index}.price_unit`}
            error={errors?.services_events?.[index]?.price_unit}
            required
            cols={{ sm: 6, xs: 12 }}
          >
            <InputCurrency
              currency="R$"
              number={false}
              name={`services_events.${index}.price_unit`}
              value={service_event.price_unit}
              onChange={(value) => handleUpdateTotal(index, 'price_unit', value)}
              disabled={!fluidPermissions()}
            />
          </FormControl>

          <FormControl
            label={t('Preço total (R$)')}
            field={`services_events.${index}.price_total`}
            required
            cols={{ sm: 5, xs: 20 }}
          >
            <InputCurrency
              currency="R$"
              number={false}
              name={`services_events.${index}.price_total`}
              value={service_event.price_total}
              disabled
            />
          </FormControl>

          <Button
            color="danger"
            title="Remover serviço"
            onClick={() => handleRemoveService(index)}
            style={{ marginTop: 10 }}
            disabled={!fluidPermissions()}
          >
            <FaRegTimesCircle color="red" />
          </Button>
          <Col style={{ backgroundColor: '#b8b8b8', marginTop: 10, marginBottom: 20, width: '90%' }} />
        </Row>
      ))}
      <Row>
        <Col span={21}>
          <Button color="primary" onClick={handleAddService} disabled={!fluidPermissions()}>
            <FaPlusCircle color="green" />
          </Button>
        </Col>
      </Row>
    </>
  );
}
