import { Popconfirm } from 'antd';
import React from 'react';
import { FaLockOpen, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import Button from '~/components/Button';
import Strong from '~/components/Strong';
import { TableActions } from '~/components/Table';

export const tableColumns = (t, handleDelete, handleRole, handlePermissions, isMobile) => {
  if (isMobile) {
    return [
      {
        dataIndex: 'summary',
        key: 'summary',
        render: (text, record) => (
          <div>
            <Strong>{t('screens:default.name')}:</Strong> {record?.name}
            <br />
            <Strong>{t('Slug')}:</Strong> {record?.slug}
            {record.id !== 1 && (
              <TableActions align="center" justify="center" isMobile={isMobile}>
                <Button onClick={() => handleRole(record)} title={t('messages:edit')}>
                  <FaPencilAlt />
                </Button>
                <Popconfirm
                  title={t('messages:confirmDelete')}
                  okText={t('messages:delete')}
                  cancelText={t('messages:no')}
                  onConfirm={() => handleDelete(record.id)}
                >
                  <Button title={t('messages:edit')}>
                    <FaRegTrashAlt />
                  </Button>
                </Popconfirm>
                <Button onClick={() => handlePermissions(record)}>
                  <FaLockOpen />
                </Button>
              </TableActions>
            )}
          </div>
        ),
      },
    ];
  }

  return [
    {
      title: t('Nome'),
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: t('Slug'),
      key: 'slug',
      dataIndex: 'slug',
    },
    {
      title: t('Ações'),
      key: 'actions',
      width: '140px',
      render: (text, record) => {
        return (
          record.id !== 1 && (
            <TableActions>
              <Button onClick={() => handleRole(record)} title={t('messages:edit')}>
                <FaPencilAlt />
              </Button>
              <Popconfirm
                title={t('messages:confirmDelete')}
                okText={t('messages:delete')}
                cancelText={t('messages:no')}
                onConfirm={() => handleDelete(record.id)}
              >
                <Button title={t('messages:edit')}>
                  <FaRegTrashAlt />
                </Button>
              </Popconfirm>
              <Button onClick={() => handlePermissions(record)}>
                <FaLockOpen />
              </Button>
            </TableActions>
          )
        );
      },
    },
  ];
};
