import React, { useEffect, useState } from 'react';
import { getFinancialBalanceSheets } from '~/services/hooks/financialBalanceSheets';
import Box from '~/components/Box';
import { Table, TableHeader } from '~/components/Table';
import { Col, Input } from 'antd';
import { tableColumns } from './table';
import { useTranslation } from 'react-i18next';
import { SelectAntd } from '~/components/Pagination/styles';
import IsMobile from '~/services/mobileCheck';

export default function FinancialBalanceSheets() {
  const { t } = useTranslation();
  const isMobile = IsMobile();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState('month');
  const [selectedYear, setSelectedYear] = useState(2025);
  // const [totalMonths, setTotalMonths] = useState({});

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          period: selectedPeriod,
          year: selectedPeriod === 'month' ? selectedYear : null,
        },
      };

      const data = await getFinancialBalanceSheets(params);

      setData(data.data);
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao carregas os balanços. Erro: ') + error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [selectedPeriod, selectedYear]);

  return (
    <Box>
      <TableHeader>
        <Col style={{ display: 'flex' }}>
          <SelectAntd
            style={{ width: 200 }}
            placeholder={t('screens:financialBalanceSheets.periodo')}
            value={selectedPeriod}
            onChange={(value) => {
              setSelectedPeriod(value);
            }}
          >
            <SelectAntd.Option value="anual">{t('Anual')}</SelectAntd.Option>
            <SelectAntd.Option value="month">{t('Meses')}</SelectAntd.Option>
          </SelectAntd>
          <SelectAntd
            style={{ width: 200, marginLeft: 10 }}
            placeholder={t('screens:financialBalanceSheets.year')}
            value={selectedYear}
            onChange={(value) => {
              setSelectedYear(value);
            }}
            disabled={selectedPeriod === 'anual'}
          >
            {Array.from({ length: 8 }, (_, i) => 2018 + i).map((year) => (
              <SelectAntd.Option key={year} value={year.toString()}>
                {year}
              </SelectAntd.Option>
            ))}
          </SelectAntd>
          <Input.Search
            onSearch={(value) => {
              setSearch(value);
            }}
            className="search-field"
            placeholder={t('screens:default.search')}
            style={{ marginLeft: 10 }}
          />
        </Col>
      </TableHeader>
      <Table
        pagination={false}
        rowKey="id"
        loading={loading}
        dataSource={data}
        columns={tableColumns(t, selectedPeriod, isMobile)}
        bordered
      />
    </Box>
  );
}
