import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Checkbox, Col, Image, Layout } from 'antd';
import * as Yup from 'yup';
import { rememberCredentials, signInRequest } from '~/store/modules/auth/actions';
import { Container, Box, Copyright } from './styles';
import { CopyrightOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import FormControl from '~/components/Form/FormControl';
import Button from '~/components/Button';
import FooterCustom from '~/components/Footer';

const schema = Yup.object().shape({
  username: Yup.string().typeError('Insira seu e-mail').email('Insira um e-mail válido').required('Insira seu e-mail'),
  password: Yup.string('Insira sua senha').typeError('Insira sua senha').required('Insira sua senha'),
});

export default function Login() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  var username = useSelector((state) => state.auth.rememberUsername);
  var password = useSelector((state) => state.auth.rememberPassword);
  var checked = useSelector((state) => state.auth.checked);

  function handleRemember({ target }) {
    var checked = target.checked;
    checked ? (username = document.getElementsByName('username')['0'].value) : (username = '');
    checked ? (password = document.getElementsByName('password')['0'].value) : (password = '');
    dispatch(rememberCredentials(username, password, checked));
  }

  function handleSubmit({ username, password }) {
    setLoading(true);
    dispatch(signInRequest(username, password));
    setLoading(false);
  }

  const currentYear = new Date().getFullYear();
  const myStyleLayout = {
    height: '100%',
    background: 'linear-gradient(90deg, #2e394b 0%, #4e6180 50%, #2e394b 100%)',
  };

  return (
    <Layout className="layout" style={myStyleLayout}>
      <Box>
        <Formik
          initialValues={{ username, password }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validationSchema={schema}
        >
          {({ errors, touched }) => (
            <Container>
              <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 20 }}>
                <Image width={170} height="auto" src="images/LOGO_COLOR_VERTICAL-01_red.png" preview={false} />
              </Col>
              <FormControl field="username" error={touched.username && errors.username}>
                <Input
                  type="email"
                  name="username"
                  size="small"
                  prefix={<UserOutlined />}
                  placeholder={t('fields:login.email.placeholder')}
                />
              </FormControl>
              <FormControl field="password" error={touched.password && errors.password}>
                <Input
                  type="password"
                  name="password"
                  size="small"
                  prefix={<LockOutlined />}
                  placeholder={t('fields:login.password.placeholder')}
                />
              </FormControl>
              <div style={{ textAlign: 'left' }}>
                <Checkbox style={{ fontSize: '12px' }} checked={checked} onChange={handleRemember}>
                  {t('fields:login.rememberMe')}{' '}
                </Checkbox>
                <span style={{ float: 'right', marginBottom: '10px' }}>
                  <Link to="/password/link">{t('fields:login.forgotPassword')}</Link>
                </span>
              </div>

              <Button size="middle" block type="submit" loading={loading} color="primary">
                {t('fields:login.submit')}
              </Button>
            </Container>
          )}
        </Formik>
      </Box>
      <Copyright>
        {` `}
        <span>{t('fields:login.copyright.copyright')}</span>
        {` `}
        <CopyrightOutlined />
        {` ${currentYear} | `}
        <span>{t('fields:login.copyright.produced')}</span>
      </Copyright>
      <FooterCustom />
    </Layout>
  );
}
