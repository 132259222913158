import { Input, InputNumber } from 'formik-antd';
import React from 'react';
import FormControl from '~/components/Form/FormControl';
import InputCurrency from '~/components/Form/InputCurrency';
import { SelectAntd, SelectAntdFormik } from '~/components/Pagination/styles';
import Row from '~/components/Row';
import DateComponent from '~/components/DateComponent';
import MaskedInput from '~/components/MaskedInput';
import { message } from 'antd';

export const EventModal = ({
  t,
  event,
  loading,
  customers,
  values,
  setFieldValue,
  fetchAddress,
  state,
  selectedState,
  setSelectedState,
  setCityEnable,
  sellers,
  errors,
  fluidPermissions,
}) => {
  const handleStateSelect = (value) => {
    if (value) {
      setCityEnable(true);
      const selectedState = state.find((item) => item.id === value);
      setSelectedState(selectedState);
      setFieldValue('city.state', value);
    }
  };

  const handleCitySelect = (value) => {
    if (selectedState) {
      setFieldValue('city_id', value);
    } else {
      message.warning('Selecione um estado primeiro');
    }
  };

  const handleZipcodeChange = (value, values) => {
    if (value.length === 8) {
      fetchAddress(value, values); // Passa o valor sem a máscara para a API
    }
  };

  return (
    <>
      <Row>
        <FormControl cols={{ sm: 12, xs: 24 }} field="name" error={errors.name} required label={t('fields:event.name')}>
          <Input name="name" disabled={!fluidPermissions()} />
        </FormControl>
        <FormControl
          cols={{ sm: 4, xs: 12 }}
          field="price_discount"
          error={errors.price_discount}
          label={t('fields:event.priceDiscount')}
        >
          <InputCurrency name="price_discount" currency="R$" number disabled={!fluidPermissions()} />
        </FormControl>
        <FormControl
          cols={{ sm: 8, xs: 12 }}
          field="customer_id"
          error={errors.customer_id}
          required
          label={t('fields:event.customer')}
        >
          <SelectAntd
            placeholder="SELECIONE"
            value={values?.customer_id ?? null}
            onSelect={(value) => {
              setFieldValue('customer_id', value);
            }}
            showSearch
            filterOption={(input, option) => option?.children?.toLowerCase()?.includes(input.toLowerCase())}
            loading={loading}
            disabled={!fluidPermissions()}
          >
            <SelectAntd.Option key="empty" value="" />
            {customers.map((item) => (
              <SelectAntd.Option key={item.id} value={item.id}>
                {item.name}
              </SelectAntd.Option>
            ))}
          </SelectAntd>
        </FormControl>
      </Row>
      <Row>
        <FormControl
          cols={{ sm: 12, xs: 12 }}
          field="description"
          error={errors.description}
          label={t('fields:event.description')}
        >
          <Input name="description" disabled={!fluidPermissions()} />
        </FormControl>
        <FormControl
          cols={{ sm: 12, xs: 12 }}
          field="seller_id"
          error={errors.seller_id}
          required
          label={t('fields:event.seller')}
        >
          <SelectAntd
            placeholder="SELECIONE"
            value={event?.seller_id}
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            onSelect={(value) => setFieldValue('seller_id', value)}
            loading={loading}
            disabled={!fluidPermissions()}
          >
            {sellers?.map((item) => (
              <SelectAntd.Option key={item.id} value={item.id}>
                {item.name}
              </SelectAntd.Option>
            ))}
          </SelectAntd>
        </FormControl>
      </Row>
      <Row>
        <FormControl
          cols={{ sm: 4, xs: 12 }}
          field="starts_at"
          error={errors.starts_at}
          required
          label={t('fields:event.startsAt')}
        >
          <DateComponent
            value={values.starts_at}
            showTime
            onChange={(date) => setFieldValue('starts_at', date)}
            disabled={!fluidPermissions()}
          />
        </FormControl>
        <FormControl
          cols={{ sm: 4, xs: 12 }}
          field="ends_at"
          error={errors.ends_at}
          required
          label={t('fields:event.endsAt')}
        >
          <DateComponent
            value={values.ends_at}
            showTime
            onChange={(date) => setFieldValue('ends_at', date)}
            disabled={!fluidPermissions()}
          />
        </FormControl>
        <FormControl
          cols={{ sm: 4, xs: 12 }}
          field="estimated_people"
          error={errors.estimated_people}
          required
          label={t('fields:event.estimatedPeople')}
        >
          <InputNumber name="estimated_people" disabled={!fluidPermissions()} />
        </FormControl>
        <FormControl
          cols={{ sm: 4, xs: 12 }}
          field="status"
          error={errors.status}
          required
          label={t('fields:event.status')}
        >
          <SelectAntdFormik
            placeholder="Status"
            name="status"
            onSelect={(value) => {
              setFieldValue('status', value);
            }}
            disabled={!fluidPermissions()}
          >
            <SelectAntdFormik.Option value={0}>EM PREENCHIMENTO</SelectAntdFormik.Option>
            <SelectAntdFormik.Option value={1}>ORÇAMENTO EM ANÁLISE</SelectAntdFormik.Option>
            <SelectAntdFormik.Option value={2}>FINALIZADO</SelectAntdFormik.Option>
            <SelectAntdFormik.Option value={3}>REPROVADO</SelectAntdFormik.Option>
            <SelectAntdFormik.Option value={4}>APROVADO</SelectAntdFormik.Option>
          </SelectAntdFormik>
        </FormControl>
        <FormControl
          cols={{ sm: 4, xs: 24 }}
          field="payment_status"
          error={errors.payment_status}
          required
          label={t('fields:event.paymentStatus')}
        >
          <SelectAntd
            placeholder="Status do pagamento"
            onSelect={(value) => {
              setFieldValue('payment_status', value);
            }}
            disabled={!fluidPermissions()}
          >
            <SelectAntd.Option value={0}>PAGAMENTO EM ABERTO</SelectAntd.Option>
            <SelectAntd.Option value={1}>NÃO LÍQUIDADO</SelectAntd.Option>
            <SelectAntd.Option value={2}>LÍQUIDADO</SelectAntd.Option>
          </SelectAntd>
        </FormControl>
      </Row>
      <Row>
        <FormControl
          cols={{ sm: 4, xs: 12 }}
          field="adress_cep"
          error={errors.adress_cep}
          required
          label={t('fields:event.zipcode')}
        >
          <MaskedInput
            name="adress_cep"
            mask="99999-999"
            placeholder="_____-___"
            style={{ width: '100%' }}
            onChange={(value) => handleZipcodeChange(value, values)}
            disabled={!fluidPermissions()}
          />
        </FormControl>
        <FormControl
          cols={{ sm: 10, xs: 12 }}
          field="city.state_id"
          error={errors.state}
          label={t('fields:event.state')}
        >
          <SelectAntd
            placeholder="SELECIONE"
            value={selectedState?.id || ''}
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            onSelect={handleStateSelect}
            loading={loading}
            disabled={!fluidPermissions()}
          >
            {state?.map((item) => (
              <SelectAntd.Option key={item.id} value={item.id}>
                {item.name}
              </SelectAntd.Option>
            ))}
          </SelectAntd>
        </FormControl>
        <FormControl
          cols={{ sm: 10, xs: 24 }}
          field="city_id"
          error={errors.city_id}
          label={t('fields:event.city')}
          onClick={handleCitySelect}
        >
          <SelectAntd
            placeholder="SELECIONE"
            value={values?.city_id}
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            onSelect={handleCitySelect}
            loading={loading}
            disabled={!setCityEnable || !fluidPermissions()}
          >
            {selectedState?.cities?.map((item) => (
              <SelectAntd.Option key={item.id} value={item.id}>
                {item.name}
              </SelectAntd.Option>
            ))}
          </SelectAntd>
        </FormControl>
      </Row>
      <Row>
        <FormControl
          cols={{ sm: 6, xs: 12 }}
          field="neighborhood"
          error={errors.neighborhood}
          label={t('fields:event.neighborhood')}
        >
          <Input name="neighborhood" disabled={!fluidPermissions()} />
        </FormControl>
        <FormControl
          cols={{ sm: 6, xs: 12 }}
          field="adress_street"
          error={errors.adress_street}
          label={t('fields:event.street')}
        >
          <Input name="adress_street" disabled={!fluidPermissions()} />
        </FormControl>
        <FormControl
          cols={{ sm: 6, xs: 12 }}
          field="adress_number"
          error={errors.adress_number}
          label={t('fields:event.addressNumber')}
        >
          <Input name="adress_number" disabled={!fluidPermissions()} />
        </FormControl>
        <FormControl
          cols={{ sm: 6, xs: 12 }}
          field="adress_reference"
          error={errors.adress_reference}
          label={t('fields:event.addressReference')}
        >
          <Input name="adress_reference" disabled={!fluidPermissions()} />
        </FormControl>
      </Row>
    </>
  );
};
