import { Popconfirm, Tag, Button as AntdButton, InputNumber } from 'antd';
import React from 'react';
import {
  FaCheckCircle,
  FaDownload,
  FaExclamationCircle,
  FaEye,
  FaMoneyCheckAlt,
  FaPencilAlt,
  FaPlusCircle,
  FaRegTrashAlt,
  FaTimesCircle,
  FaUserCheck,
} from 'react-icons/fa';
import Button from '~/components/Button';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import { TableActions } from '~/components/Table';
import moment from 'moment';
import 'moment/locale/pt-br';
import i18next from 'i18next';
import Strong from '~/components/Strong';

export const tableColumns = (
  t,
  handleModal,
  handleDelete,
  handleModalFinancial,
  handleInvites,
  isMobile,
  isImport = false
) => {
  const statusReturn = (code) => {
    let response = 'Não possuí';

    switch (code) {
      case 0:
        response = <Tag>EM PREENCHIMENTO</Tag>;
        break;
      case 1:
        response = <Tag color="grey">ORÇAMENTO EM ANÁLISE</Tag>;
        break;
      case 2:
        response = <Tag color="blue">FINALIZADO</Tag>;
        break;
      case 3:
        response = <Tag color="red">REPROVADO</Tag>;
        break;
      case 4:
        response = <Tag color="green">APROVADO</Tag>;
        break;
    }

    return response;
  };

  const paymentReturn = (code) => {
    let response = '';

    switch (code) {
      case 0:
        response = (
          <FaExclamationCircle
            style={{ backgroundColor: 'yellow', borderRadius: '100px' }}
            title="PAGAMENTO EM ABERTO"
          />
        );
        break;
      case 1:
        response = (
          <FaTimesCircle style={{ backgroundColor: 'rgb(250, 93, 93)', borderRadius: '100px' }} title="NÃO LÍQUIDADO" />
        );
        break;
      case 2:
        response = (
          <FaCheckCircle style={{ backgroundColor: 'rgb(117, 233, 71)', borderRadius: '100px' }} title="LÍQUIDADO" />
        );
        break;
    }

    return response;
  };

  if (isMobile) {
    return [
      {
        dataIndex: 'summary',
        key: 'summary',
        render: (text, record) => (
          <div>
            <Strong>{t('screens:Nome')}:</Strong> {record?.name}
            <br />
            <Strong>{t('screens:events.code')}:</Strong> {moment(record.starts_at).year() + '/' + record.id}
            <br />
            <Strong>{t('screens:Status')}:</Strong>
            <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {statusReturn(record.status)}
              {paymentReturn(record.payment_status)}
            </span>
            <br />
            <Strong>{t('screens:startDate')}:</Strong> {moment(record.starts_at).locale('pt-br').format('DD/MM/YYYY')}
            <br />
            <Strong>{t('screens:Cidade')}:</Strong>
            {record.city ? record.city.name + '/' + (record.city.state ? record.city.state.acronym : '') : ''}
            <br />
            {!isImport ? (
              <TableActions align="center" justify="center">
                <Can permission="@events/editInviteEmployers">
                  <Button size="small" title="Convidar colaboradores" onClick={() => handleInvites(record)}>
                    <FaUserCheck />
                  </Button>
                </Can>
                <Can permission="@events/editFinancialClosing">
                  <Button size="small" title="Fechamento financeiro" onClick={() => handleModalFinancial(record)}>
                    <FaMoneyCheckAlt />
                  </Button>
                </Can>
                <Can permission="@events/edit">
                  <Button size="small" title="Editar" onClick={() => handleModal(record)}>
                    <FaPencilAlt />
                  </Button>
                </Can>
                <Cannot permission="@events/edit">
                  <Button
                    style={{ margin: 'auto' }}
                    size="small"
                    title="Visualizar"
                    onClick={() => handleModal(record)}
                  >
                    <FaEye />
                  </Button>
                </Cannot>
                <Can permission="@events/delete">
                  <Popconfirm
                    title={t('messages:confirmDelete')}
                    okText={t('messages:yes')}
                    onConfirm={() => handleDelete(record.id)}
                    cancelText={t('messages:no')}
                  >
                    <Button size="small" title="Excluir">
                      <FaRegTrashAlt />
                    </Button>
                  </Popconfirm>
                </Can>
              </TableActions>
            ) : (
              <TableActions align="center" justify="center">
                <Can permission="@events/edit">
                  <AntdButton size="small" title="Editar" onClick={() => isImport(record.consigneds)}>
                    <FaDownload />
                  </AntdButton>
                </Can>
              </TableActions>
            )}
          </div>
        ),
      },
    ];
  }

  return [
    {
      title: t('screens:Nome'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => record.name,
    },
    {
      title: t('screens:events.code'),
      dataIndex: 'code',
      key: 'code',
      render: (text, record) => moment(record.starts_at).year() + '/' + record.id,
    },
    {
      title: t('screens:Status'),
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        return (
          <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {statusReturn(record.status)}
            {paymentReturn(record.payment_status)}
          </span>
        );
      },
    },
    {
      title: t('screens:startDate'),
      dataIndex: 'starts_at',
      key: 'starts_at',
      render: (text, record) => moment(record.starts_at).locale('pt-br').format('DD/MM/YYYY'), //formatar data
    },

    {
      title: t('screens:Cidade'),
      dataIndex: 'name',
      key: 'name',
      width: '300px',
      render: (text, record) =>
        record.city ? record.city.name + '/' + (record.city.state ? record.city.state.acronym : '') : '',
    },
    {
      title: t('screens:default.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) =>
        !isImport ? (
          <TableActions align="center" justify="center">
            <Can permission="@events/editInviteEmployers">
              <Button size="small" title="Convidar colaboradores" onClick={() => handleInvites(record)}>
                <FaUserCheck />
              </Button>
            </Can>
            <Can permission="@events/editFinancialClosing">
              <Button size="small" title="Fechamento financeiro" onClick={() => handleModalFinancial(record)}>
                <FaMoneyCheckAlt />
              </Button>
            </Can>
            <Can permission="@events/edit">
              <Button size="small" title="Editar" onClick={() => handleModal(record)}>
                <FaPencilAlt />
              </Button>
            </Can>
            <Cannot permission="@events/edit">
              <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleModal(record)}>
                <FaEye />
              </Button>
            </Cannot>
            <Can permission="@events/delete">
              <Popconfirm
                title={t('messages:confirmDelete')}
                okText={t('messages:yes')}
                onConfirm={() => handleDelete(record.id)}
                cancelText={t('messages:no')}
              >
                <Button size="small" title="Excluir">
                  <FaRegTrashAlt />
                </Button>
              </Popconfirm>
            </Can>
          </TableActions>
        ) : (
          <TableActions align="center" justify="center">
            <Can permission="@events/edit">
              <AntdButton size="small" title="Editar" onClick={() => isImport(record.consigneds)}>
                <FaDownload />
              </AntdButton>
            </Can>
          </TableActions>
        ),
    },
  ];
};

export const employeesTableColumns = (t, handleAddEmployee, isMobile) => {
  if (isMobile) {
    return [
      {
        dataIndex: 'summary',
        key: 'summary',
        render: (text, record) => (
          <div>
            <p>
              <Strong>{t('screens:Nome')}:</Strong> {record.name}
            </p>
            <p>
              <Strong>{t('screens:Email')}:</Strong> {record.email}
            </p>
            <p>
              <Strong>{t('screens:Cidade')}:</Strong> {record.city_id?.name}
            </p>
            <Can permission="@events/edit">
              <TableActions align="center" justify="center">
                <AntdButton size="medium" title="Adicionar" onClick={() => handleAddEmployee(record.id, record.name)}>
                  <FaPlusCircle />
                </AntdButton>
              </TableActions>
            </Can>
          </div>
        ),
      },
    ];
  }

  return [
    {
      title: t('screens:Nome'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => record.name,
    },
    {
      title: t('screens:Email'),
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => record.email,
    },
    {
      title: t('screens:Cidade'),
      dataIndex: 'cidade',
      key: 'cidade',
      render: (text, record) => record.city_id?.name,
    },
    {
      title: t('screens:default.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (
        <Can permission="@events/edit">
          <AntdButton size="large" title="Adicionar" onClick={() => handleAddEmployee(record.id, record.name)}>
            <FaPlusCircle />
          </AntdButton>
        </Can>
      ),
    },
  ];
};

export const tableSwoiColumns = (setFieldValue, isMobile) => {
  const calcTotals = (record, value, index) => {
    const newValue = Number(value);
    const quantidadeVendida = record.consigned_amount - newValue;
    const totalCostPrice = record.item_cost * quantidadeVendida;
    const totalSellPrice = record.price_unit * quantidadeVendida;

    record = {
      ...record,
      amount: quantidadeVendida,
      amount_returned: newValue,
      total_cost_price: totalCostPrice,
      total_consigned_price: totalSellPrice,
    };

    setFieldValue(`stock_write_offs.swoi.${index}`, record);
  };

  if (isMobile) {
    return [
      {
        title: i18next.t('screens:default.summary'),
        dataIndex: 'summary',
        key: 'summary',
        render: (_, record, index) => (
          <div>
            <p>
              <Strong>{i18next.t('screens:Nome')}:</Strong> {record.item_name}
            </p>
            <p>
              <Strong>{i18next.t('Qtde consignado')}:</Strong> {record.consigned_amount}
            </p>
            <p>
              <Strong>{i18next.t('Qtde devolução')}:</Strong>
              <InputNumber
                type="number"
                style={{ width: '100%' }}
                max={record.consigned_amount}
                value={record?.amount_returned || 0}
                onChange={(value) => calcTotals(record, value, index)}
              />
            </p>
            <p>
              <Strong>{i18next.t('Qtde venda')}:</Strong> {record.amount}
            </p>
            <p>
              <Strong>{i18next.t('Valor de custo (R$)')}:</Strong>{' '}
              {parseFloat(record.item_cost).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
            </p>
            <p>
              <Strong>{i18next.t('Total de custo (R$)')}:</Strong>{' '}
              {parseFloat(record.total_cost_price).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
            </p>
            <p>
              <Strong>{i18next.t('Valor de venda (R$)')}:</Strong>{' '}
              {parseFloat(record.price_unit).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
            </p>
            <p>
              <Strong>{i18next.t('Total de venda (R$)')}:</Strong>{' '}
              {parseFloat(record.total_consigned_price).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
            </p>
          </div>
        ),
      },
    ];
  }

  return [
    {
      title: i18next.t('screens:Nome'),
      dataIndex: 'item_name',
      key: 'item_name',
    },
    {
      title: i18next.t('Qtde consignado'),
      dataIndex: 'consigned_amount',
      key: 'consigned_amount',
    },
    {
      title: i18next.t('Qtde devolução'),
      dataIndex: 'amount_returned',
      key: 'amount_returned',
      render: (_, record, index) => {
        return (
          <InputNumber
            type="number"
            style={{ width: '100%' }}
            max={record.consigned_amount}
            value={record?.amount_returned || 0}
            onChange={(value) => {
              calcTotals(record, value, index);
            }}
          />
        );
      },
    },
    {
      title: i18next.t('Qtde venda'),
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: i18next.t('Valor de custo'),
      children: [
        {
          title: i18next.t('Unitário (R$)'),
          dataIndex: 'item_cost',
          key: 'item_cost',
          render: (text) => {
            const parsedValue = parseFloat(text);
            return parsedValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          },
        },
        {
          title: i18next.t('Total (R$)'),
          dataIndex: 'cost_total',
          key: 'cost_total',
          render: (_, record) => {
            const quantidadeVendida = record?.amount || 0;
            const totalCost = record.item_cost * quantidadeVendida;

            if (record) {
              record.total_cost_price = totalCost;
            }

            return totalCost.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          },
        },
      ],
    },
    {
      title: i18next.t('Valor de venda'),
      children: [
        {
          title: i18next.t('Unitário (R$)'),
          dataIndex: 'price_unit',
          key: 'price_unit',
          render: (text) => {
            const parsedValue = parseFloat(text);
            return parsedValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          },
        },
        {
          title: i18next.t('Total (R$)'),
          dataIndex: 'price_total',
          key: 'price_total',
          render: (_, record) => {
            const quantidadeVendida = record?.amount || 0;
            const formattedPrice = parseFloat(
              record.price_unit
                ?.replace(/[^\d]/g, '') // Remove todos os caracteres não numéricos
                .replace(/(\d{2})$/, '.$1') // Adiciona o ponto antes das últimas 2 casas
            );
            const total = formattedPrice * quantidadeVendida;

            if (record) {
              record.total_consigned_price = total;
            }

            console.log(record);
            return total.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          },
        },
      ],
    },
  ];
};

export const tableSwoiTotal = (data) => {
  const totalCost = data.reduce((sum, record) => sum + record.item_cost * record.amount, 0);
  const totalSale = data.reduce((sum, record) => sum + parseFloat(record.price_unit) * record.amount, 0);

  return [
    {
      key: 'total',
      item_name: i18next.t('Total'),
      cost_total: totalCost.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      price_total: totalSale.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    },
  ];
};
