import { Popconfirm } from 'antd';
import React from 'react';
import { FaEye, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import Button from '~/components/Button';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import { TableActions } from '~/components/Table';
import moment from 'moment'; //npm install moment
import 'moment/locale/pt-br';
import Strong from '~/components/Strong';

export const tableColumns = (t, handleEdit, handleDelete, isMobile) => {
  if (isMobile) {
    return [
      {
        dataIndex: 'summary',
        key: 'summary',
        render: (text, record) => (
          <div>
            <Strong>{t('screens:default.name')}:</Strong> {record.name}
            <br />
            <Strong>{t('screens:default.date')}:</Strong>{' '}
            {moment(record.acquired_at).locale('pt-br').format('DD/MM/YYYY')}
            <br />
            <Strong>{t('screens:default.price')}:</Strong>{' '}
            {Number(record.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            <br />
            <TableActions align="center" justify="flex" isMobile={isMobile}>
              <Can permission="@extraExpenses/edit">
                <Button size="small" title="Editar" onClick={() => handleEdit(record)}>
                  <FaPencilAlt />
                </Button>
              </Can>
              <Cannot permission="@extraExpenses/edit">
                <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record)}>
                  <FaEye />
                </Button>
              </Cannot>
              <Can permission="@extraExpenses/delete">
                <Popconfirm
                  title={t('messages:confirmDelete')}
                  okText={t('messages:yes')}
                  onConfirm={() => handleDelete(record.id)}
                  cancelText={t('messages:no')}
                >
                  <Button size="small" title="Excluir">
                    <FaRegTrashAlt />
                  </Button>
                </Popconfirm>
              </Can>
            </TableActions>
          </div>
        ),
      },
    ];
  }

  return [
    {
      title: t('screens:default.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => record.name,
    },
    {
      title: t('screens:default.date'),
      dataIndex: 'acquired_at',
      key: 'acquired_at',
      render: (text, record) => moment(record.acquired_at).locale('pt-br').format('DD/MM/YYYY'), //formatar data
    },
    {
      title: t('screens:default.price'),
      dataIndex: 'price',
      key: 'price',
      width: '300px',
      render: (text, record) => Number(record.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // converte para R$
    },
    {
      title: t('screens:default.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (
        <TableActions align="center" justify="center">
          <Can permission="@extraExpenses/edit">
            <Button size="small" title="Editar" onClick={() => handleEdit(record)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@extraExpenses/edit">
            <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record)}>
              <FaEye />
            </Button>
          </Cannot>
          <Can permission="@extraExpenses/delete">
            <Popconfirm
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          </Can>
        </TableActions>
      ),
    },
  ];
};
