import styled from 'styled-components';
import { Layout } from 'antd';

export const Container = styled(Layout)`
  background-color: #2e394b;

  > .ant-layout-content {
    padding: 30px;
  }

  .ant-layout-sider-children {
    background-color: #2e394b;
  }

  .ant-layout-header {
    background: #2e394b;
    height: 50px;
  }

  .ant-layout-sider-light .ant-layout-sider-trigger {
    background: #ffbb00;
  }
`;

export const Content = styled(Layout.Content)`
  padding: 24px;
  margin: 0;
  min-height: 280px;
`;
