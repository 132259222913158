import { Menu } from 'antd';
import styled from 'styled-components';

export const AntMenu = styled(Menu)`
  height: auto;
  border-right: 0;
  background-color: #2e394b;
  color: #f5f5f5 !important;

  a {
    color: #f5f5f5 !important;
  }

  svg {
    color: #ffbb00 !important;
  }

  /* Seleciona o <li> que contém o .ant-menu-item-title */
  li[data-menu-id^='sideMenu-separator'] {
    cursor: default !important;
    background-color: transparent !important;
    pointer-events: none !important;
    padding-left: 0;
    margin-left: -10px;
  }

  /* Aplica o estilo ao conteúdo de texto */
  li[data-menu-id^='sideMenu-separator'] .ant-menu-item-title {
    color: #ffbb00 !important;
    font-weight: bold !important;
  }

  /* Remove o efeito de hover no <li> */
  li[data-menu-id^='sideMenu-separator']:hover {
    background-color: transparent !important;
    cursor: default !important;
  }

  .ant-menu-light,
  .ant-layout-header,
  .ant-menu-light .ant-menu-sub,
  .ant-menu.ant-menu-light .ant-menu-sub,
  .ant-menu.ant-menu-light,
  .ant-menu.ant-menu-light .ant-menu-sub {
    background-color: #2e394b;
  }

  .ant-menu-item-selected {
    background-color: #ffbb00 !important;
    a {
      color: black !important;
    }

    svg {
      color: #2e394b !important;
    }
  }

  .ant-layout-sider-light {
    background-color: #2e394b;
  }

  .ant-layout-sider-light .ant-layout-sider-trigger {
    background-color: #2e394b;
    color: #ffbb00 !important;
  }

  i {
    color: #ffbb00 !important;
  }

  .ant-menu-submenu-title {
    color: #f5f5f5;
  }

  .ant-menu-submenu-open {
    .ant-menu-submenu-title {
      background-color: #1f2733 !important;
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      color: #ffbb00 !important;
    }
  }
`;
