import { Col, message, Modal, Select, Spin, Tabs } from 'antd';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalFooter } from '~/components/Modal';
import Row from '~/components/Row';
import { Permissions } from '~/services/permissions';
import { StyledTabs } from './style';
import { registerTableColumns } from './table';
import { getFinancialExpenses } from '~/services/hooks/financialExpenses';
import { getFinancialExpenseItems } from '~/services/hooks/financialExpenseItems';
import { Table } from '~/components/Table';
import Button from '~/components/Button';
import FinancialExpenseItemExpensesModal from './financialExpenseItemExpensesModal';
import { useCheckAuth } from '~/components/Can/checkAuth';

export default function RegisterModal({ visible, onClose, isMobile }) {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const [loading, setLoading] = useState(false);
  const [financialExItems, setFinancialExItems] = useState([]);
  const [financialEx, setFinancialEx] = useState([]);
  const [expenseType, setExpenseType] = useState(0);
  const [activeKey, setActiveKey] = useState(0);
  const [editFinancialExItem, setEditFinancialExItem] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [financialExItemExModalVisible, setFinancialExItemExModalVisible] = useState(false);
  const permissions = Permissions();
  const years = Array.from({ length: 7 }, (_, i) => new Date().getFullYear() - i);

  const fetchFinancialExpenseItems = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          selectedYear,
          financial_expense_id: activeKey,
          withMonth: true,
        },
      };
      const data = await getFinancialExpenseItems(params, 'no-paginated');
      setFinancialExItems(data);
    } catch (error) {
      message.error(`Não foi possível trazer registros, erro: ${error}`);
    }
    setLoading(false);
  };

  const fetchFinancialEx = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          expense_type: expenseType,
        },
      };
      const data = await getFinancialExpenses(params, 'no-paginated');

      setFinancialEx(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleCreate = () => {
    setFinancialExItemExModalVisible(true);
  };

  const handleEdit = (financialExpenseItemId) => {
    setEditFinancialExItem(financialExpenseItemId);
    setFinancialExItemExModalVisible(true);
  };

  const renderContent = () => (
    <StyledTabs
      tabPosition="bottom"
      onChange={(key) => setActiveKey(key)}
      items={financialEx.map((expense) => ({
        label: expense.name || 'Despesa não especificada',
        key: expense.id || 'undefined-expense',
        children: (
          <>
            <Table
              pagination={false}
              rowKey="id"
              loading={loading}
              dataSource={financialExItems}
              columns={registerTableColumns(t, handleEdit)}
              bordered
            />
          </>
        ),
      }))}
    ></StyledTabs>
  );

  useEffect(() => {
    if (visible) {
      fetchFinancialExpenseItems();
    }
  }, [visible, activeKey, selectedYear]);

  useEffect(() => {
    if (visible) {
      fetchFinancialEx();
    }
  }, [visible, expenseType]);

  return (
    <>
      <Formik validateOnBlur={false} validateOnChange={false} enableReinitialize>
        {({ isSubmitting, submitForm, resetForm }) => (
          <Modal
            title={t('screens:expenses.register.title')}
            onCancel={onClose}
            style={{ maxWidth: isMobile ? '100%' : '1500px' }}
            afterClose={resetForm}
            open={visible}
            loading={isSubmitting}
            centered
            width="1500px"
            footer={
              <ModalFooter
                type={permissions && !permissions.includes('@expenses/edit') && 'info'}
                onOk={submitForm}
                loading={isSubmitting}
                onCancel={onClose}
              />
            }
          >
            <Row>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBottom: 20,
                }}
              >
                <Select defaultValue={selectedYear} style={{ width: 200 }} onChange={(value) => setSelectedYear(value)}>
                  <Select.Option value="" disabled>
                    Selecionar Ano
                  </Select.Option>
                  {years.map((year) => (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  ))}
                </Select>
                <Button
                  disabled={!authCheck('@registerExpenses/create')}
                  color="primary"
                  onClick={() => handleCreate(null)}
                >
                  Adicionar Registro
                </Button>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Tabs defaultActiveKey={0} onChange={(key) => setExpenseType(key)}>
                  <Tabs.TabPane tab="Fixa" key={0}>
                    <Spin spinning={loading}>{renderContent()}</Spin>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Fixa / Variável" key={1}>
                    <Spin spinning={loading}>{renderContent()}</Spin>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Variável" key={2}>
                    <Spin spinning={loading}>{renderContent()}</Spin>
                  </Tabs.TabPane>
                </Tabs>
              </Col>
            </Row>
          </Modal>
        )}
      </Formik>
      <FinancialExpenseItemExpensesModal
        visible={financialExItemExModalVisible}
        financialExId={activeKey}
        financialExItemId={editFinancialExItem}
        year={selectedYear}
        isMobile={isMobile}
        onClose={() => {
          setEditFinancialExItem(null);
          setFinancialExItemExModalVisible(false);
          fetchFinancialExpenseItems();
        }}
      />
    </>
  );
}
