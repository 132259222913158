import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
import { Breadcrumb } from 'antd';

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Roboto:100,400,700|Montserrat:100,400,700&display=swap');

/* 15 76 129 pantone do ano rgb */

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing:border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100vh !important;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  * {
    /* font: 14px 'Roboto', sans-serif !important; */
    font: calc(12px + 0.1vw) 'Roboto', sans-serif;
  }

  a {
    text-decoration:none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  .page-loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0%;
    right: 0;
  }

  .ant-select-item-option:hover, .ant-select-item-option-selected {
    background-color: rgba(255, 187, 0, 0.1) !important;
  }
`;

export const BreadcrumbCustom = styled(Breadcrumb)`
  margin: 16px 0;
`;
