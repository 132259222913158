import { Popconfirm } from 'antd';
import React from 'react';
import { FaDownload, FaEye, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import Button from '~/components/Button';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import Strong from '~/components/Strong';
import { TableActions } from '~/components/Table';

export const tableColumns = (t, handleEdit, fetchReports, isMobile) => {
  if (isMobile) {
    return [
      {
        dataIndex: 'summary',
        key: 'summary',
        render: (text, record) => (
          <div>
            <Strong>{t('screens:Título')}:</Strong> {record?.title}
            <br />
            <Strong>{t('screens:Contexto')}:</Strong> {record?.report_type}
            <br />
            <Strong>{t('screens:Status')}:</Strong> {record?.status}
            <br />
            <Strong>{t('Criado em')}:</Strong>{' '}
            {new Date(record?.created_at).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}
            <br />
            <TableActions align="center" justify="flex" isMobile={isMobile}>
              <Can permission="@reports/edit">
                <Button size="small" title="Editar" onClick={() => handleEdit(record)}>
                  <FaPencilAlt />
                </Button>
              </Can>
              <Cannot permission="@reports/edit">
                <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record)}>
                  <FaEye />
                </Button>
              </Cannot>
              <Can permission="@reports/view">
                <Button
                  style={{ margin: 'auto' }}
                  size="small"
                  title="Baixar relatórios"
                  onClick={() => fetchReports(record.id)}
                >
                  <FaDownload />
                </Button>
              </Can>
              <Can permission="@reports/delete">
                <Popconfirm
                  title={t('messages:confirmDelete')}
                  okText={t('messages:yes')}
                  onConfirm={() => handleDelete(record.id)}
                  cancelText={t('messages:no')}
                >
                  <Button size="small" title="Excluir">
                    <FaRegTrashAlt />
                  </Button>
                </Popconfirm>
              </Can>
            </TableActions>
          </div>
        ),
      },
    ];
  }

  return [
    {
      title: t('screens:Título'),
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => record.title,
    },
    {
      title: t('screens:Contexto'),
      dataIndex: 'report_type',
      key: 'report_type',
      render: (text, record) => record.report_type,
    },
    {
      title: t('screens:Status'),
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => record.status,
    },
    {
      title: t('Criado em'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, record) => {
        const date = new Date(record.created_at);
        return date.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
      },
    },
    {
      title: t('screens:default.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (
        <TableActions align="center" justify="center">
          <Can permission="@reports/edit">
            <Button size="small" title="Editar" onClick={() => handleEdit(record)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@reports/edit">
            <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record)}>
              <FaEye />
            </Button>
          </Cannot>
          <Can permission="@reports/view">
            <Button
              style={{ margin: 'auto' }}
              size="small"
              title="Baixar relatórios"
              onClick={() => fetchReports(record.id)}
            >
              <FaDownload />
            </Button>
          </Can>
          <Can permission="@reports/delete">
            <Popconfirm
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          </Can>
        </TableActions>
      ),
    },
  ];
};
