import React, { useEffect } from 'react';
import { Card, Button } from 'antd';
import { FieldArray, useFormikContext } from 'formik';
import { Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import FormControl from '~/components/Form/FormControl';
import { SelectAntdFormik } from '~/components/Pagination/styles';
import InputCurrency from '~/components/Form/InputCurrency';
import Row from '~/components/Row';

export default function FinancialClosingRevenues({ values, setFieldValue, cardsOperators, handleTitle }) {
  const { t } = useTranslation();
  const { values: forkimValues } = useFormikContext();

  const formatValues = (value, isFee = false) => {
    if (isFee) {
      return parseFloat(value);
    }

    const withoutDotAndComma = value.replace(/[.,]/g, '');
    let formattedValue = withoutDotAndComma;

    if (formattedValue.length > 2) {
      formattedValue = formattedValue.slice(0, -2) + '.' + formattedValue.slice(-2);
    } else {
      formattedValue = '0.' + formattedValue.padStart(2, '0');
    }

    return parseFloat(formattedValue);
  };

  const disabledCardSelect = (revenue) => {
    if (revenue?.id) {
      return !revenue.card_operator_id;
    }

    return !revenue?.cardOptions?.length > 0;
  };

  const brFormat = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  useEffect(() => {
    const {
      revenues = [],
      event_financial_payments = [],
      event_financial_losses = [],
      debit_fee,
      credit_fee,
      pix_fee,
    } = values;

    const initialSums = {
      debit: { price: 0, liquid: 0 },
      credit: { price: 0, liquid: 0 },
      pix: { price: 0, liquid: 0 },
      agio: 0,
      payments: 0,
    };

    // Processa receitas
    const revenueSums = revenues.reduce((acc, revenue) => {
      const { card_type, raw_price, final_price, agio } = revenue;
      const formattedRaw = formatValues(raw_price || '');
      const formattedFinal = formatValues(final_price || '');

      if (card_type === 0) {
        acc.debit.price += formattedRaw;
        acc.debit.liquid += formattedFinal;
      } else if (card_type === 1) {
        acc.credit.price += formattedRaw;
        acc.credit.liquid += formattedFinal;
      } else {
        acc.pix.price += formattedRaw;
        acc.pix.liquid += formattedFinal;
      }

      acc.agio += formatValues(agio || '');
      return acc;
    }, initialSums);

    // Processa pagamentos e perdas
    revenueSums.payments = event_financial_payments.reduce((acc, efp) => acc + formatValues(efp.price), 0);
    revenueSums.losses_price = event_financial_losses.reduce((acc, efl) => acc + formatValues(efl.price), 0);

    // Cálculo de taxas do cliente
    const { debit, credit, pix, payments, losses_price, agio } = revenueSums;
    const clientFees = {
      debit: (debit_fee / 100) * debit.price,
      credit: (credit_fee / 100) * credit.price,
      pix: (pix_fee / 100) * pix.price,
    };

    const totalLiquidClient =
      debit.price - clientFees.debit + credit.price - clientFees.credit + pix.price - clientFees.pix;

    const finalPrice = totalLiquidClient - payments;
    const realFinalPrice = debit.liquid + credit.liquid + pix.liquid - payments;

    // Atualiza os valores do Formik
    setFieldValue('debit_raw_price', brFormat(debit.price));
    setFieldValue('credit_raw_price', brFormat(credit.price));
    setFieldValue('pix_raw_price', brFormat(pix.price));
    setFieldValue('debit_final_price', brFormat(debit.liquid));
    setFieldValue('credit_final_price', brFormat(credit.liquid));
    setFieldValue('pix_final_price', brFormat(pix.liquid));
    setFieldValue('total_raw_price', brFormat(debit.price + credit.price + pix.price));
    setFieldValue('revenues_price', brFormat(debit.liquid + credit.liquid + pix.liquid));
    setFieldValue('agio_total', brFormat(agio));
    setFieldValue('debit_fee_price', brFormat(clientFees.debit));
    setFieldValue('credit_fee_price', brFormat(clientFees.credit));
    setFieldValue('pix_fee_price', brFormat(clientFees.pix));
    setFieldValue('total_liquid_client', brFormat(totalLiquidClient));
    setFieldValue('payments_price', brFormat(payments));
    setFieldValue('losses_price', brFormat(losses_price));
    setFieldValue('customer_final_price', brFormat(finalPrice));
    setFieldValue('final_price', brFormat(realFinalPrice));

    // Dependências específicas para evitar loops infinitos
  }, [
    values.revenues,
    values.event_financial_payments,
    values.event_financial_losses,
    values.debit_fee,
    values.credit_fee,
    values.pix_fee,
  ]);

  useEffect(() => {
    values.revenues?.forEach((revenue, index) => {
      const feeValue = formatValues(revenue.fee || '', true);
      const timesValues = feeValue / 100;
      const rawPrice = formatValues(revenue.raw_price || '');
      const feeAmount = rawPrice * timesValues;
      const finalPrice = rawPrice - feeAmount;
      const formattedFee = brFormat(feeAmount);
      const formattedFinalPrice = brFormat(finalPrice);

      let correctFee = '0';

      if (revenue.card_type == 0) {
        correctFee = forkimValues.debit_fee || '';
      } else if (revenue.card_type == 1) {
        correctFee = forkimValues.credit_fee || '';
      }

      const submitedFee = correctFee - revenue.fee;
      const dividedFee = submitedFee / 100;
      const finalAgio = brFormat(dividedFee * rawPrice);

      setFieldValue(`revenues.${index}.fee_value`, formattedFee);
      setFieldValue(`revenues.${index}.final_price`, formattedFinalPrice);
      setFieldValue(`revenues.${index}.agio`, finalAgio);
    });
  }, [values.revenues]);

  useEffect(() => {
    values.revenues.forEach((revenue, index) => {
      const cardOperator = cardsOperators.find((co) => co.id === revenue.card_operator_id);
      setFieldValue(`revenues.${index}.cardOptions`, cardOperator?.cards || []);
      setFieldValue(`revenues.${index}.card_type`, revenue?.card_type);
    });
  }, [values.card_operator_id, values.card_type]);

  return (
    <FieldArray
      name="revenues"
      render={(arrayHelpers) => (
        <>
          {values?.revenues?.map((revenue, index) => (
            <Card
              key={'revenue' + index}
              style={{ marginBottom: 16 }}
              title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span>{handleTitle(values, index, revenue?.cardOptions)}</span>
                  <Button danger type="text" onClick={() => arrayHelpers.remove(index)}>
                    <FaRegTimesCircle size={18} />
                  </Button>
                </div>
              }
            >
              <Row>
                <FormControl cols={{ sm: 12, xs: 24 }} label={t('Cartão')} field={`revenues.${index}.card_operator_id`}>
                  <SelectAntdFormik
                    placeholder="Selecione um cartão"
                    name={`revenues.${index}.card_operator_id`}
                    onSelect={(value) => {
                      const cardOperator = cardsOperators.find((co) => co.id === value);
                      setFieldValue(`revenues.${index}.cardOptions`, cardOperator?.cards || []);
                    }}
                  >
                    {cardsOperators.map((operator) => (
                      <SelectAntdFormik.Option key={operator.id} value={operator.id}>
                        {operator.name}
                      </SelectAntdFormik.Option>
                    ))}
                  </SelectAntdFormik>
                </FormControl>
                <FormControl cols={{ sm: 12, xs: 24 }} label={t('Modalidade')} field={`revenues.${index}.card_type`}>
                  <SelectAntdFormik
                    placeholder="Selecione uma modalidade"
                    name={`revenues.${index}.card_type`}
                    onSelect={(value, option) => {
                      const selectedCard = revenue?.cardOptions?.find((c) => c.id == option.key);
                      setFieldValue(`revenues.${index}.fee`, selectedCard?.fee);
                      setFieldValue(`revenues.${index}.card_id`, option.key);
                      setFieldValue(`revenues.${index}.card_type`, value);
                    }}
                    disabled={disabledCardSelect(revenue)}
                  >
                    {revenue?.cardOptions?.map((card) => (
                      <SelectAntdFormik.Option key={card.id} value={card.card_type}>
                        {card.card_type_name}
                      </SelectAntdFormik.Option>
                    ))}
                  </SelectAntdFormik>
                </FormControl>
              </Row>
              <Row>
                <FormControl cols={{ sm: 12, xs: 24 }} label={t('Valor bruto')} field={`revenues.${index}.raw_price`}>
                  <InputCurrency name={`revenues.${index}.raw_price`} />
                </FormControl>
                <FormControl cols={{ sm: 12, xs: 24 }} label={t('Taxa real (%)')} field={`revenues.${index}.fee`}>
                  <Input name={`revenues.${index}.fee`} />
                </FormControl>
              </Row>
              <Row>
                <FormControl cols={{ sm: 8, xs: 24 }} label={t('Taxa')} field={`revenues.${index}.fee_value`}>
                  <InputCurrency name={`revenues.${index}.fee_value`} disabled />
                </FormControl>
                <FormControl
                  cols={{ sm: 8, xs: 24 }}
                  label={t('Valor líquido')}
                  field={`revenues.${index}.final_price`}
                >
                  <InputCurrency name={`revenues.${index}.final_price`} disabled />
                </FormControl>
                <FormControl
                  cols={{ sm: 8, xs: 24 }}
                  label={t('Ágio de taxa administrativa')}
                  field={`revenues.${index}.agio`}
                >
                  <InputCurrency name={`revenues.${index}.agio`} disabled />
                </FormControl>
              </Row>
            </Card>
          ))}
          <Button
            type="primary"
            icon={<FaPlusCircle />}
            onClick={() =>
              arrayHelpers.push({
                card_id: null,
                card_type: null,
                card_operator_id: null,
                fee: '',
                fee_value: '',
                final_price: '',
                agio: '',
                cardOptions: [],
              })
            }
            style={{ marginTop: 16 }}
          >
            {t('Adicionar cartão')}
          </Button>
        </>
      )}
    />
  );
}
