import React, { useEffect, useState } from 'react';
import { deleteFinancialExpenses, getFinancialExpenses } from '~/services/hooks/financialExpenses';
import Box from '~/components/Box';
import { Table, TableHeader } from '~/components/Table';
import { Col, Input, message } from 'antd';
import Pagination from '~/components/Pagination';
import Global from '~/styles/global';
import { tableColumns } from './table';
import { useTranslation } from 'react-i18next';
import { SelectAntd } from '~/components/Pagination/styles';
import Button from '~/components/Button';
import { ColButtons } from './style';
import RegisterModal from './registerModal';
import ExpenseModal from './modal';
import { useCheckAuth } from '~/components/Can/checkAuth';
import IsMobile from '~/services/mobileCheck';

export default function FinancialExpenses() {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const isMobile = IsMobile();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState({});
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useState({
    expense_type: null,
  });
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const [expenseModalVisible, setExpenseModalVisible] = useState(false);
  const [editExpense, setEditExpense] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          page,
          perPage,
          search,
          ...searchParams,
        },
      };
      const data = await getFinancialExpenses(params);

      setData(data.data);
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
      message.error(t('messages:cards.errorGet') + error.message);
    }
    setLoading(false);
  };

  const handleEdit = (expense) => {
    setEditExpense(expense);
    setExpenseModalVisible(true);
  };

  const handleDelete = async (value) => {
    try {
      await deleteFinancialExpenses(value);
      fetchData();
    } catch (error) {
      message.error(t('messages:cards.errorDel') + error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, search, searchParams]);

  return (
    <>
      <Box>
        <ColButtons>
          <Button
            disabled={!authCheck('@registerExpenses/view')}
            color="secundary"
            onClick={() => setRegisterModalVisible(true)}
          >
            {t('screens:financialExpenses.expensesRegister')}
          </Button>
          <Button disabled={!authCheck('@expenses/create')} color="primary" onClick={() => handleEdit(null)}>
            {t('screens:financialExpenses.addExpense')}
          </Button>
        </ColButtons>
        <TableHeader>
          <Col style={{ display: 'flex' }}>
            <SelectAntd
              style={{ width: '200px', marginRight: '20px' }}
              placeholder={t('screens:default.type')}
              onSelect={(value) => {
                setSearchParams((initialValues) => ({
                  ...initialValues,
                  expense_type: value,
                }));
                setPage(1);
              }}
            >
              <SelectAntd.Option value="">{t('screens:default.all')}</SelectAntd.Option>
              <SelectAntd.Option value={0}>{t('screens:financialExpenses.fixed')}</SelectAntd.Option>
              <SelectAntd.Option value={1}>{t('screens:financialExpenses.fixedVariable')}</SelectAntd.Option>
              <SelectAntd.Option value={2}>{t('screens:financialExpenses.variable')}</SelectAntd.Option>
            </SelectAntd>
            <Input.Search
              onSearch={(value) => {
                setSearch(value);
                setPage(1);
              }}
              className="search-field"
              placeholder={t('screens:default.search')}
            />
          </Col>
        </TableHeader>
        <Table
          pagination={false}
          rowKey="id"
          loading={loading}
          dataSource={data}
          columns={tableColumns(t, handleEdit, handleDelete)}
          bordered
        />
        <Pagination
          isMobile={isMobile}
          onChange={(value) => {
            setPage(value);
          }}
          meta={meta}
          perPage={perPage}
          setPerPage={setPerPage}
        />

        <Global />
      </Box>
      <ExpenseModal
        visible={expenseModalVisible}
        expense={editExpense}
        isMobile={isMobile}
        onClose={() => {
          setExpenseModalVisible(false);
          setEditExpense(null);
          fetchData();
        }}
      />
      <RegisterModal
        visible={registerModalVisible}
        isMobile={isMobile}
        onClose={() => {
          fetchData();
          setRegisterModalVisible(false);
        }}
      />
    </>
  );
}
