import React, { useEffect, useState } from 'react';
import { Modal, Spin, message, Tabs } from 'antd';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { ModalFooter } from '~/components/Modal';
import { getCardsOperator } from '~/services/hooks/cardsOperator';
import {
  createEventsFinancials,
  getEventsFinancials,
  pdfEventsFinancials,
  updateEventsFinancials,
} from '~/services/hooks/eventFinancials';
import FinancialClosingRevenues from './financialClosingRevenues';
import FinancialClosingPayments from './financialClosingPayments';
import FinancialClosingLosses from './financialClosingLosses';
import Real from './real';
import Client from './client';

const initial = {
  event_id: null,
  revenues: [],
  event_financial_payments: [],
  event_financial_losses: [],
  debit_fee: null,
  credit_fee: null,
  anticipation_fee: '0',
  agio: '0',
  debit_raw_price: null,
  debit_fee_price: null,
  debit_final_price: null,
  credit_raw_price: null,
  credit_fee_price: null,
  credit_liquid_price: null,
  credit_final_price: null,
  anticipation_price: '0.00',
  revenues_price: null,
  final_price: null,
  customer_final_price: null,
  payments_price: null,
  agio_total: null,
  pix_raw_price: null,
  pix_fee_price: null,
  pix_final_price: null,
  pix_fee: null,
};

export default function FinancialClosingModal({ visible, onClose, event }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [cardsOperators, setCardsOperators] = useState([]);
  const [initialValues, setInitialValues] = useState(initial);

  const fetchRevenues = async () => {
    try {
      const response = await getEventsFinancials('', `by-event/${event.id}`);

      if (!response) {
        setInitialValues(initial);
        return;
      }

      setInitialValues(response);
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao carregar a receita. Erro: ') + error.message);
    }
  };

  const fetchCardsOperator = async () => {
    try {
      const data = await getCardsOperator('', 'no-paginated');
      setCardsOperators(data);
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao carregar os cartões. Erro: ') + error.message);
    }
  };

  const handleTitle = (values, index, cards) => {
    const firstTitle = cardsOperators?.find((co) => co.id === values?.revenues[index]?.card_operator_id)?.name || '';
    const secondTitle = cards?.find((c) => c.id == values?.revenues[index]?.card_id)?.card_type_name || '';

    return firstTitle && secondTitle ? firstTitle + ' - ' + secondTitle : firstTitle;
  };

  const handleSave = async (values) => {
    setLoading(true);
    try {
      if (values?.id) {
        await updateEventsFinancials(values.id, values);
      } else {
        values.event_id = event.id;
        await createEventsFinancials(values);
      }
      message.success(t('messages:successCreate'));
      onClose();
    } catch (error) {
      console.log('Erro ao salvar:', error);
      message.error(t('Erro ao salvar o fechamento. Error: ') + error.message);
    }
    setLoading(false);
  };

  const handlePdf = async (eventFinancials, route) => {
    try {
      const response = await pdfEventsFinancials(eventFinancials, route);
      window.open(response.data, '_blank');
    } catch (error) {
      console.log(error);
      message.error(t('Não foi possível abrir o documento. Erro: ') + error.message);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchRevenues();
      fetchCardsOperator();
    }
  }, [visible]);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSave}
    >
      {({ values, submitForm, setFieldValue }) => (
        <Modal
          title={`Fechamento Financeiro - ${event?.name}`}
          open={visible}
          onCancel={onClose}
          afterClose={() => setInitialValues(initial)}
          width={1000}
          footer={<ModalFooter onOk={submitForm} onCancel={onClose} loading={loading} />}
        >
          <Spin spinning={loading}>
            <Tabs
              defaultActiveKey="1"
              size="large"
              items={[
                {
                  label: 'Valores',
                  key: 'Valores',
                  children: (
                    <div>
                      <FinancialClosingRevenues
                        values={values}
                        setFieldValue={setFieldValue}
                        cardsOperators={cardsOperators}
                        handleTitle={handleTitle}
                      />
                    </div>
                  ),
                },
                {
                  label: 'Real',
                  key: 'Real',
                  children: <Real handlePdf={handlePdf} values={values} loading={loading} />,
                },
                {
                  label: 'Cliente',
                  key: 'Cliente',
                  children: <Client handlePdf={handlePdf} values={values} loading={loading} />,
                },
                {
                  label: 'Pagamentos & Sobra/Falta',
                  key: 'Pagamentos & Sobra/Falta',
                  children: (
                    <>
                      <div style={{ marginTop: '30px' }}>
                        <h2>{t('Pagamentos')}</h2>
                        <FinancialClosingPayments values={values} setFieldValue={setFieldValue} />
                      </div>
                      <div style={{ marginTop: '30px' }}>
                        <h2>{t('Sobra/falta de dinheiro')}</h2>
                        <FinancialClosingLosses values={values} setFieldValue={setFieldValue} />
                      </div>
                    </>
                  ),
                },
              ]}
            />
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
