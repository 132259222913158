import { useSelector } from 'react-redux';

export default function Cannot({ children, role, permission }) {
  const { permissions: permissionList, roles: roleList } = useSelector((state) => state.user);

  const checkAuth = (permissions, roles) => {
    let auth = false;

    if (!roleList?.includes('@superAdmin')) {
      if (roles) {
        auth = roleList?.includes(roles);
      } else if (permissions) {
        auth = permissionList?.includes(permissions);
      } else {
        auth = false;
      }
    } else {
      auth = true;
    }

    return auth;
  };

  return !checkAuth(permission, role) ? children : null;
}
