import React, { useEffect, useState } from 'react';
import { message, Modal, Spin, Typography } from 'antd';
import { FieldArray, Formik } from 'formik';
import { Input, Radio } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import MaskedInput from '~/components/MaskedInput';
import { SelectAntd, SelectAntdFormik } from '~/components/Pagination/styles';
import { createCustomers, updateCustomers } from '~/services/hooks/customers';
import { getStates } from '~/services/hooks/states';
import Row from '~/components/Row';
import axios from 'axios';
import { getServices } from '~/services/hooks/services';
import Button from '~/components/Button';
import { FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import { getUsers } from '~/services/hooks/users';

const initial = {
  customer_type: null,
  name: null,
  nickname: null,
  social_name: null,
  cnpj: null,
  state_registration: null,
  cpf: null,
  rg: null,
  prospect_type: null,
  phone: null,
  description: null,
  email: null,
  web_page: null,
  responsible_id: null,
  responsible_name: null,
  note: null,
  services: [],
  contacts: [],
  address: {
    zipcode: null,
    street: null,
    complement: null,
    number: null,
    neighborhood: null,
    city_id: null,
    state_id: null,
    addresseble_type: 'Customer',
    addressable_id: null,
    reference: null,
  },
};

export default function CustomerModal({ visible, onClose, customersEdit, isMobile }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(initial);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [cityEnable, setCityEnable] = useState(false);
  const [services, setServices] = useState([]);
  const [users, setUsers] = useState([]);

  const handleInitialValues = () => {
    if (customersEdit?.id) {
      if (customersEdit?.address?.city_id) {
        const stateMatch = states?.find((s) => s.cities?.some((c) => c.id === customersEdit.address.city_id));
        setSelectedState(stateMatch);
        setCityEnable(true);
      }

      setInitialValues(customersEdit);
    } else {
      setInitialValues(initial);
    }
  };

  const fetchStates = async () => {
    try {
      const response = await getStates('', 'no-paginated');
      setStates(response);
    } catch (error) {
      console.log(error);
      message.error(t('messages:states.errorGet') + error.message);
    }
  };

  const fetchUsers = async () => {
    try {
      const data = await getUsers('', 'no-paginated');
      setUsers(data);
    } catch (error) {
      console.log(error);
      message.error(t('messages:users.errorGet') + error.message);
    }
  };

  const fetchServices = async () => {
    try {
      const services = await getServices('', 'no-paginated');
      setServices(services);
    } catch (error) {
      console.log(error);
      message.error(t('messages:services.errorGet') + error.message);
    }
  };

  const fetchAddress = async (cep, values, setValues) => {
    try {
      const { data } = await axios.get(`http://viacep.com.br/ws/${cep}/json/`);

      const stateMatch = states.find((s) => s.acronym === data.uf);
      let updatedCityId = values.address?.city_id;

      if (stateMatch) {
        setSelectedState(stateMatch);

        const cityMatch = stateMatch.cities.find((city) => city.name.toLowerCase() === data.localidade.toLowerCase());

        if (cityMatch) {
          updatedCityId = cityMatch.id;
          setCityEnable(true);
        }
      }

      setValues({
        ...values,
        address: {
          zipcode: cep,
          neighborhood: values.address?.neighborhood?.trim() ? values.address.neighborhood : data.bairro || '',
          street: values.address?.adress_street?.trim() ? values.address.adress_street : data.logradouro || '',
          number: values.address?.adress_number?.trim() ? values.address.adress_number : data.numero || '',
          city_id: updatedCityId,
        },
      });
    } catch (error) {
      console.log(error);
      message.error(t('messages:cep.errorGet') + error.message);
    }
  };

  const saveCustomer = async (record) => {
    setLoading(true);
    try {
      if (customersEdit?.id) {
        await updateCustomers(customersEdit.id, record);
        message.success(t('messages:customers.successPut'));
      } else {
        await createCustomers(record);
        message.success(t('messages:customers.successPost'));
      }
      onClose();
    } catch (error) {
      console.log(error);
      message.error(t('messages:customers.errorSave') + error.message);
    }
    setLoading(false);
  };

  const handleStateSelect = (value) => {
    if (value) {
      setCityEnable(true);
      const selected = states.find((state) => state.id === value);
      setSelectedState(selected);
    }
  };

  const handleZipcodeChange = (value, values, setValues) => {
    setLoading(true);
    if (value.length > 7) {
      fetchAddress(value, values, setValues);
    }
    setLoading(false);
  };

  const fetchAll = async () => {
    setLoading(true);
    try {
      handleInitialValues();

      await Promise.all([fetchStates(), fetchServices(), fetchUsers()]);
    } catch (error) {
      console.log('Erro ao carregar dados iniciais:', error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchAll();
    }
  }, [visible]);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={saveCustomer}
    >
      {({ isSubmitting, submitForm, values, setValues }) => (
        <Modal
          title={t(customersEdit ? 'screens:customers.titlePut' : 'screens:customers.titlePost') + (values.name ?? '')}
          onCancel={onClose}
          afterClose={() => setInitialValues(initial)}
          open={visible}
          centered
          width={isMobile ? '100%' : '1300px'}
          footer={<ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} />}
        >
          <Spin spinning={loading}>
            <Row>
              <FormControl cols={{ md: 24, xs: 24 }} label={t('fields:customers.type')} field="customer_type">
                <Radio.Group
                  name="customer_type"
                  options={[
                    { label: 'Pessoa Física', value: 0 },
                    { label: 'Pessoa Jurídica', value: 1 },
                  ]}
                  optionType="button"
                  buttonStyle="solid"
                />
              </FormControl>
            </Row>
            {values?.customer_type === 1 && (
              <>
                <Row>
                  <FormControl cols={{ md: 12, xs: 24 }} label={t('fields:customers.name')} field="name">
                    <Input name="name" placeholder={t('Nome')} />
                  </FormControl>
                  <FormControl cols={{ md: 12, xs: 24 }} label={t('fields:customers.socialName')} field="social_name">
                    <Input name="social_name" placeholder="Razão Social" />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl cols={{ md: 8, xs: 12 }} label="CNPJ" field="cnpj">
                    <Input name="cnpj" placeholder="CNPJ" />
                  </FormControl>
                  <FormControl
                    cols={{ md: 8, xs: 12 }}
                    label={t('fields:customers.stateRegistration')}
                    field="state_registration"
                  >
                    <Input name="state_registration" placeholder="state_registration" />
                  </FormControl>
                  <FormControl cols={{ md: 8, xs: 24 }} label={t('fields:customers.phone')} field="phone">
                    <Input name="phone" placeholder={t('Telefone')} />
                  </FormControl>
                </Row>
              </>
            )}{' '}
            {values?.customer_type === 0 && (
              <>
                <Row>
                  <FormControl cols={{ md: 12, xs: 24 }} label={t('fields:customers.name')} field="name">
                    <Input name="name" placeholder={t('Nome')} />
                  </FormControl>
                  <FormControl cols={{ md: 12, xs: 24 }} label={t('fields:customers.nickname')} field="nickname">
                    <Input name="nickname" placeholder={t('Apelido')} />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl cols={{ md: 8, xs: 12 }} label={t('fields:customers.cpf')} field="cpf">
                    <Input name="cpf" placeholder={t('CPF')} />
                  </FormControl>
                  <FormControl cols={{ md: 8, xs: 12 }} label={t('fields:customers.rg')} field="rg">
                    <Input name="rg" placeholder={t('RG')} />
                  </FormControl>
                  <FormControl cols={{ md: 8, xs: 24 }} label={t('fields:customers.phone')} field="phone">
                    <Input name="phone" placeholder={t('Telefone')} />
                  </FormControl>
                </Row>
              </>
            )}
            {values?.customer_type !== null && (
              <>
                <Row>
                  <FormControl cols={{ md: 8, xs: 24 }} label={t('fields:customers.type')} field="description">
                    <Input name="description" placeholder={t('Descrição')} />
                  </FormControl>
                  <FormControl cols={{ md: 8, xs: 24 }} label={t('Serviços')} field="services" required>
                    <SelectAntdFormik
                      name="services"
                      placeholder="SELECIONE"
                      mode="multiple"
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      loading={loading}
                      onChange={(selectedValues) => {
                        setValues((prevValues) => ({
                          ...prevValues,
                          services: selectedValues,
                        }));
                      }}
                      value={values.services || []}
                    >
                      {services?.map((service) => (
                        <SelectAntd.Option key={service.id} value={service.id}>
                          {service.name}
                        </SelectAntd.Option>
                      ))}
                    </SelectAntdFormik>
                  </FormControl>
                  <FormControl cols={{ md: 8, xs: 24 }} label={t('Responsável')} field={`responsible_id`}>
                    <SelectAntdFormik
                      name="responsible_id"
                      placeholder="SELECIONE"
                      value={services?.id ?? ''}
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      onSelect={(value, option) => {
                        setValues((prevValues) => ({
                          ...prevValues,
                          responsible_id: value,
                          responsible_name: option.children,
                        }));
                      }}
                      loading={loading}
                    >
                      {users?.map((user) => (
                        <SelectAntd.Option key={user.id} value={user.id}>
                          {user.name}
                        </SelectAntd.Option>
                      ))}
                    </SelectAntdFormik>
                  </FormControl>
                </Row>
                <Row>
                  <FormControl cols={{ md: 8, xs: 12 }} label={t('fields:customers.activeType')} field="prospect_type">
                    <SelectAntdFormik placeholder="SELECIONE" name="prospect_type">
                      <SelectAntd.Option key={0} value={0}>
                        {t('Ativo')}
                      </SelectAntd.Option>
                      <SelectAntd.Option key={1} value={1}>
                        {t('Inativo')}
                      </SelectAntd.Option>
                      <SelectAntd.Option key={2} value={2}>
                        {t('A prospectar')}
                      </SelectAntd.Option>
                    </SelectAntdFormik>
                  </FormControl>
                  <FormControl cols={{ md: 8, xs: 12 }} label={t('fields:customers.email')} field="email">
                    <Input name="email" placeholder={t('Email')} />
                  </FormControl>
                  <FormControl cols={{ md: 8, xs: 24 }} label={t('fields:customers.webPage')} field="web_page">
                    <Input name="web_page" placeholder={t('Página Web')} />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl
                    cols={{ md: 4, xs: 12 }}
                    field="address.zipcode"
                    required
                    label={t('fields:event.zipcode')}
                  >
                    <MaskedInput
                      name="address.zipcode"
                      mask="99999-999"
                      placeholder="_____-___"
                      style={{ width: '100%' }}
                      onChange={(value) => handleZipcodeChange(value, values, setValues)}
                    />
                  </FormControl>
                  <FormControl cols={{ md: 10, xs: 12 }} field="address.state_id" label={t('fields:event.state')}>
                    <SelectAntd
                      placeholder="SELECIONE"
                      value={selectedState?.id}
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      onSelect={handleStateSelect}
                    >
                      {states.map((state) => (
                        <SelectAntd.Option key={state.id} value={state.id}>
                          {state.name}
                        </SelectAntd.Option>
                      ))}
                    </SelectAntd>
                  </FormControl>
                  <FormControl cols={{ md: 10, xs: 24 }} field="address.city_id" label={t('fields:event.city')}>
                    <SelectAntdFormik
                      name="address.city_id"
                      placeholder="SELECIONE"
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      loading={loading}
                      disabled={!cityEnable}
                    >
                      {selectedState?.cities?.map((city) => (
                        <SelectAntd.Option key={city.id} value={city.id}>
                          {city.name}
                        </SelectAntd.Option>
                      ))}
                    </SelectAntdFormik>
                  </FormControl>
                </Row>
                <Row>
                  <FormControl
                    cols={{ md: 6, xs: 12 }}
                    field="address.neighborhood"
                    label={t('fields:event.neighborhood')}
                  >
                    <Input name="address.neighborhood" />
                  </FormControl>
                  <FormControl cols={{ md: 6, xs: 12 }} field="address.street" label={t('fields:event.street')}>
                    <Input name="address.street" />
                  </FormControl>
                  <FormControl cols={{ md: 6, xs: 12 }} field="address.number" label={t('fields:event.addressNumber')}>
                    <Input name="address.number" />
                  </FormControl>
                  <FormControl
                    cols={{ md: 6, xs: 12 }}
                    field="address.reference"
                    label={t('fields:event.addressReference')}
                  >
                    <Input name="address.reference" />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl cols={{ md: 24, xs: 24 }} label={t('fields:customers.note')} field="note">
                    <Input.TextArea name="note" placeholder={t('Nota')} />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl cols={{ md: 24, xs: 24 }}>
                    <Typography.Title style={{ fontSize: 30 }}>{t('screens:customers.contactsTitle')}</Typography.Title>
                  </FormControl>
                </Row>
                <FieldArray
                  name="contacts"
                  render={(arrayHelpers) => (
                    <div>
                      {values.contacts?.map((contact, index) => (
                        <>
                          <FormControl>
                            <Button
                              color="danger"
                              onClick={() => arrayHelpers.remove(index)}
                              style={{ marginLeft: '8px', alignSelf: 'flex-end' }}
                            >
                              <FaRegTimesCircle />
                            </Button>
                          </FormControl>
                          <Row key={index} style={{ marginBottom: '16px' }}>
                            <FormControl cols={{ md: 5, xs: 12 }} field={`contacts.${index}.name`} label={t('Nome')}>
                              <Input name={`contacts.${index}.name`} />
                            </FormControl>
                            <FormControl
                              cols={{ md: 5, xs: 12 }}
                              field={`contacts.${index}.role`}
                              label={t('fields:customers.role')}
                            >
                              <Input name={`contacts.${index}.role`} />
                            </FormControl>
                            <FormControl
                              cols={{ md: 5, xs: 12 }}
                              field={`contacts.${index}.social_media`}
                              label={t('fields:customers.socialMedia')}
                            >
                              <Input name={`contacts.${index}.social_media`} />
                            </FormControl>
                            <FormControl
                              cols={{ md: 5, xs: 12 }}
                              field={`contacts.${index}.phone`}
                              label={t('fields:customers.phone')}
                            >
                              <Input name={`contacts.${index}.phone`} />
                            </FormControl>
                            <FormControl
                              cols={{ md: 4, xs: 24 }}
                              field={`contacts.${index}.email`}
                              label={t('fields:customers.email')}
                            >
                              <Input name={`contacts.${index}.email`} />
                            </FormControl>
                          </Row>
                        </>
                      ))}
                      <Button
                        color="green"
                        onClick={() =>
                          arrayHelpers.push({ name: '', role: '', social_media: '', phone: '', email: '' })
                        }
                        style={{ marginTop: '16px' }}
                      >
                        <FaPlusCircle style={{ marginRight: '8px' }} />
                      </Button>
                    </div>
                  )}
                />
              </>
            )}
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
