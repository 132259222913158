// import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

export default function Can({ children, permission, role }) {
  const { permissions: permissionList, roles: roleList } = useSelector((state) => state.user);

  const checkAuth = (permissions, roles) => {
    let auth = false;

    if (!roleList?.includes('@superAdmin')) {
      if (roles) {
        auth = roleList?.includes(roles);
      } else if (permissions) {
        auth = permissionList?.includes(permissions);
      } else {
        auth = false;
      }
    } else {
      auth = true;
    }

    return auth;
  };

  return checkAuth(permission, role) ? children : null;
}

Can.defaultProps = {
  permission: '',
  role: '',
};

Can.propTypes = {
  permission: PropTypes.string,
  role: PropTypes.string,
  children: PropTypes.node.isRequired,
};
