import React, { useEffect, useState } from 'react';
import { getCustomers, deleteCustomers } from '~/services/hooks/customers';
import { getStates } from '~/services/hooks/states';
import { getCities } from '~/services/hooks/cities';
import Box from '~/components/Box';
import { Table, TableHeader } from '~/components/Table';
import { Col, Input, message } from 'antd';
import Pagination from '~/components/Pagination';
import { tableColumns } from './table';
import { useTranslation } from 'react-i18next';
import Button from '~/components/Button';
import { SelectAntd } from '~/components/Pagination/styles';
import { useCheckAuth } from '~/components/Can/checkAuth';
import ColButton from '~/components/ColButton';
import CustomerModal from './form';
import IsMobile from '~/services/mobileCheck';

export default function Customers() {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const isMobile = IsMobile();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState({});
  const [search, setSearch] = useState('');
  const [customersEdit, setCustomersEdit] = useState({});
  const [ModalVisible, setModalVisible] = useState(false);
  const [searchParams, setSearchParams] = useState({
    customer_type: null,
    prospect_type: null,
    responsible_id: {
      name: null,
    },
    city_id: null,
    state_id: null,
  });
  const [cityValue, setCityValue] = useState('');

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          page,
          perPage,
          search,
          ...searchParams,
        },
      };
      const data = await getCustomers(params);

      setData(data.data);
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
      message.error(t('messages:customers.errorGet') + error.message);
    }
    setLoading(false);
  };

  const fetchStates = async () => {
    setLoading(true);
    try {
      const response = await getStates('', 'no-paginated');
      setStates(response);
    } catch (error) {
      console.log(error);
      message.error(t('messages:states.errorGet') + error.message);
    }
    setLoading(false);
  };

  const fetchCities = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          ...searchParams,
        },
      };
      const { data } = await getCities(params, 'no-paginated');
      setCities(data);
    } catch (error) {
      console.log(error);
      message.error(t('messages:cities.errorGet') + error.message);
    }
    setLoading(false);
  };

  const handleEdit = (record) => {
    setCustomersEdit(record);
    setModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteCustomers(id);
      message.success(t('messages:customers.successDel'));
      fetchData();
    } catch (error) {
      console.log(error);
      message.error(t('messages:customers.errorDel') + error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, search, searchParams]);

  useEffect(() => {
    fetchStates();
  }, []);

  useEffect(() => {
    fetchCities();
    if (!searchParams.state_id) {
      setCityValue(null);
    }
  }, [searchParams]);

  return (
    <Box>
      <ColButton>
        <Button color="primary" onClick={() => handleEdit(null)} disabled={!authCheck('@clients/create')}>
          {t('screens:customers.addCustomer')}
        </Button>
      </ColButton>
      <TableHeader>
        <Col
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: isMobile ? 'center' : 'space-around',
            width: '100%',
            gap: isMobile ? 15 : 30,
          }}
        >
          {[
            {
              placeholder: t('screens:customers.customerType'),
              onSelect: (value) => {
                setSearchParams((initialValues) => ({
                  ...initialValues,
                  customer_type: value,
                }));
                setPage(1);
              },
              options: [
                { value: '', label: t('screens:default.all') },
                { value: 0, label: t('screens:customers.naturalPerson') },
                { value: 1, label: t('screens:customers.legalEntity') },
              ],
            },
            {
              placeholder: t('screens:customers.prospectType'),
              onSelect: (value) => {
                setSearchParams((initialValues) => ({
                  ...initialValues,
                  prospect_type: value,
                }));
                setPage(1);
              },
              options: [
                { value: '', label: t('screens:customers.all') },
                { value: 0, label: t('screens:customers.active') },
                { value: 1, label: t('screens:customers.inactive') },
                { value: 2, label: t('screens:customers.toProspect') },
              ],
            },
            {
              placeholder: t('screens:default.state'),
              onSelect: (value) => {
                setSearchParams((initialValues) => ({
                  ...initialValues,
                  state_id: value,
                  city_id: null,
                }));
                setCityValue(null);
                setPage(1);
                fetchData();
              },
              options: [
                { value: '', label: t('screens:default.all') },
                ...states?.map((state) => ({ value: state.id, label: state.name })),
              ],
            },
            {
              placeholder: t('screens:default.city'),
              disabled: !searchParams.state_id,
              value: cityValue,
              onSelect: (value) => {
                setCityValue(value);
                setSearchParams((initialValues) => ({
                  ...initialValues,
                  city_id: value,
                }));
                setPage(1);
              },
              options: cities,
            },
            {
              placeholder: t('screens:default.responsible'),
              onSelect: (value) => {
                setSearchParams((initialValues) => ({
                  ...initialValues,
                  responsible_id: {
                    ...initialValues.responsible_id,
                    name: value,
                  },
                }));
                setPage(1);
              },
              options: [
                { value: '', label: t('screens:default.all') },
                { value: 4, label: 'Irere' },
                { value: 5, label: 'Tadeu' },
                { value: 16, label: 'Juliana' },
              ],
            },
          ].map((filter, index) => (
            <div key={index} style={{ width: isMobile ? '45%' : '200px', marginBottom: isMobile ? 10 : 0 }}>
              <SelectAntd
                style={{ width: '100%' }}
                placeholder={filter.placeholder}
                onSelect={filter.onSelect}
                disabled={filter.disabled}
                value={filter.value}
              >
                {filter.options.map((option) => (
                  <SelectAntd.Option key={option.value} value={option.value}>
                    {option.label}
                  </SelectAntd.Option>
                ))}
              </SelectAntd>
            </div>
          ))}
          <div style={{ width: isMobile ? '90%' : '400px', marginBottom: isMobile ? 10 : 0 }}>
            <Input.Search
              onSearch={(value) => {
                setSearch(value);
                setPage(1);
              }}
              style={{ width: '100%' }}
              placeholder={t('screens:default.search')}
            />
          </div>
        </Col>
      </TableHeader>
      <Table
        pagination={false}
        rowKey="id"
        loading={loading}
        dataSource={data}
        columns={tableColumns(handleEdit, handleDelete, isMobile)}
        bordered
      />
      <Pagination
        isMobile={isMobile}
        onChange={(value) => {
          setPage(value);
        }}
        meta={meta}
        perPage={perPage}
        setPerPage={setPerPage}
      />
      <CustomerModal
        visible={ModalVisible}
        customersEdit={customersEdit ?? {}}
        isMobile={isMobile}
        onClose={() => {
          fetchData();
          setCustomersEdit({});
          setModalVisible(false);
        }}
      />
    </Box>
  );
}
