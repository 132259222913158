import { Popconfirm } from 'antd';
import React from 'react';
import { FaEye, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import Button from '~/components/Button';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import Strong from '~/components/Strong';
import { TableActions } from '~/components/Table';

export const tableColumns = (t, handleDelete, handleEdit, isMobile) => {
  const prices = (record) => {
    let plus = 0;

    if (record.patrimony_payments && Array.isArray(record.patrimony_payments)) {
      record.patrimony_payments.forEach((payment) => {
        plus += parseFloat(payment.price || 0);
      });
    }

    return plus;
  };

  if (isMobile) {
    return [
      {
        dataIndex: 'summary',
        key: 'summary',
        render: (text, record) => (
          <div>
            <Strong>{t('screens:Nome')}:</Strong> {record.name}
            <br />
            <Strong>{t('screens:Valor')}:</Strong>{' '}
            {prices(record).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            <br />
            <TableActions align="center" justify="flex" isMobile={isMobile}>
              <Can permission="@assets/edit">
                <Button size="small" title="Editar" onClick={() => handleEdit(record)}>
                  <FaPencilAlt />
                </Button>
              </Can>
              <Cannot permission="@assets/edit">
                <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record)}>
                  <FaEye />
                </Button>
              </Cannot>
              <Can permission="@assets/delete">
                <Popconfirm
                  title={t('messages:confirmDelete')}
                  okText={t('messages:yes')}
                  onConfirm={() => handleDelete(record.id)}
                  cancelText={t('messages:no')}
                >
                  <Button size="small" title="Excluir">
                    <FaRegTrashAlt />
                  </Button>
                </Popconfirm>
              </Can>
            </TableActions>
          </div>
        ),
      },
    ];
  }

  return [
    {
      title: t('screens:Nome'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => record.name,
    },
    {
      title: t('screens:Valor'),
      dataIndex: 'price',
      key: 'price',
      width: '300px',
      render: (text, record) => {
        const totalPrice = prices(record); // Chama a função de soma para o record
        return totalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }); // Converte para R$
      },
    },
    {
      title: t('screens:default.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (
        <TableActions align="center" justify="center">
          <Can permission="@assets/edit">
            <Button size="small" title="Editar" onClick={() => handleEdit(record)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@assets/edit">
            <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record)}>
              <FaEye />
            </Button>
          </Cannot>
          <Can permission="@assets/delete">
            <Popconfirm
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          </Can>
        </TableActions>
      ),
    },
  ];
};
