import api from '~/services/api';
import PropTypes from 'prop-types';

const endPoint = 'stockwriteoffsitems';

// GET
export const getStockWriteOffsItems = async (params, route = '') => {
  try {
    const { data } = await api.get(`/${endPoint}/${route}`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

// CREATE
export const createStockWriteOffsItems = async (values) => {
  try {
    const { data } = await api.post(`/${endPoint}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// UPDATE
export const updateStockWriteOffsItems = async (id, values) => {
  try {
    const { data } = await api.put(`/${endPoint}/${id}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// DELETE
export const deleteStockWriteOffsItems = async (id) => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

getStockWriteOffsItems.propTypes = {
  params: PropTypes.object.isRequired,
};

createStockWriteOffsItems.propTypes = {
  values: PropTypes.object.isRequired,
};

updateStockWriteOffsItems.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
};

deleteStockWriteOffsItems.propTypes = {
  id: PropTypes.string.isRequired,
};
