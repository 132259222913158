import React from 'react';
import BaseLayout from 'pages/_layouts/base';

import { Container } from './styles';
import { navigate } from '~/services/navigate';
import Button from '~/components/Button';

export default function ErrorPage() {
  return (
    <BaseLayout>
      <Container>
        <div className="error-code">404</div>
        <h1>404</h1>
        <h2>Página não encontrada</h2>

        <Button onClick={() => navigate('/')} color="primary">
          {' '}
          Voltar para Home
        </Button>
      </Container>
    </BaseLayout>
  );
}
