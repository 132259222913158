import React, { useEffect, useState } from 'react';
import errorHandler from '~/Utils/errorHandler';
import Box from '~/components/Box';
import { Table, TableHeader } from '~/components/Table';
import { Col, Input, message } from 'antd';
import Pagination from '~/components/Pagination';
import Global from '~/styles/global';
import { tableColumns } from './table';
import { useTranslation } from 'react-i18next';
import { SelectAntd } from '~/components/Pagination/styles';
import Button from '~/components/Button';
import CardModal from './form';
import { getCardsOperator, deleteCardsOperator } from '~/services/hooks/cardsOperator';
import { useCheckAuth } from '~/components/Can/checkAuth';
import ColButton from '~/components/ColButton';
import IsMobile from '~/services/mobileCheck';

export default function Cards() {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const isMobile = IsMobile();
  const [loading, setLoading] = useState(false);
  const [cardEdit, setCardEdit] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState({});
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useState({
    card_type: null,
  });
  const [ModalVisible, setModalVisible] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          page,
          perPage,
          search,
          ...searchParams,
        },
      };
      const data = await getCardsOperator(params);
      setData(data.data);
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
      message.error(t('messages:cards.errorGet') + error.message);
    }
    setLoading(false);
  };

  const handleEdit = async (record) => {
    setCardEdit(record);
    setModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteCardsOperator(id);
      message.success(t('messages:cards.successDel') + error.message);
      fetchData();
    } catch (error) {
      errorHandler(error);
      message.error(t('messages:cards.errorDel') + error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, search, searchParams]);

  return (
    <Box>
      <ColButton>
        <Button color="primary" onClick={() => handleEdit(null)} disabled={!authCheck('@cards/create')}>
          {t('screens:cards.addCards')}
        </Button>
      </ColButton>
      <TableHeader>
        <Col style={{ display: 'flex' }}>
          <SelectAntd
            style={{ width: '200px', marginRight: '20px' }}
            placeholder={t('screens:cards.all')}
            onSelect={(value) => {
              setSearchParams((initialValues) => ({
                ...initialValues,
                card_type: value,
              }));
              setPage(1);
            }}
          >
            <SelectAntd.Option value="">{t('screens:cards.all')}</SelectAntd.Option>
            <SelectAntd.Option value={0}>{t('screens:cards.debit')}</SelectAntd.Option>
            <SelectAntd.Option value={1}>{t('screens:cards.credit')}</SelectAntd.Option>
            <SelectAntd.Option value={2}>{t('fields:card.pix')}</SelectAntd.Option>
          </SelectAntd>
          <Input.Search
            onSearch={(value) => {
              setSearch(value);
              setPage(1);
            }}
            className="search-field"
            placeholder={t('screens:default.search')}
          />
        </Col>
      </TableHeader>
      <Table
        pagination={false}
        rowKey="id"
        loading={loading}
        dataSource={data}
        columns={tableColumns(handleDelete, handleEdit)}
        bordered
      />
      <Pagination
        isMobile={isMobile}
        onChange={(value) => {
          setPage(value);
        }}
        meta={meta}
        perPage={perPage}
        setPerPage={setPerPage}
      />
      <Global />
      <CardModal
        visible={ModalVisible}
        cardEdit={cardEdit}
        isMobile={isMobile}
        onClose={() => {
          setModalVisible(false);
          setCardEdit(null);
          fetchData();
        }}
      />
    </Box>
  );
}
