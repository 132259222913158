import React, { useEffect, useState } from 'react';
import Box from '~/components/Box';
import { message, Spin } from 'antd';
import dayGridPlugin from '@fullcalendar/daygrid';
import ptLocale from '@fullcalendar/core/locales/pt';
import { getEvents } from '~/services/hooks/events';
import { FullCalendar } from './styles';
import { useCheckAuth } from '~/components/Can/checkAuth';
import FormEvents from '../Events/forms/modal';
import { useTranslation } from 'react-i18next';
import IsMobile from '~/services/mobileCheck';

export default function Dashboard() {
  const isMobile = IsMobile();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [formVisible, setFormVisible] = useState(false);
  const [editEvent, setEditEvent] = useState(null);
  const checkAuth = useCheckAuth();

  const fetchEvents = async () => {
    setLoading(true);
    try {
      const params = { params: { month, year } };
      const data = await getEvents(params, 'no-paginated');
      setEvents(data);
    } catch (error) {
      console.log(error);
      message.error(t('messages:events.errorGet') + error.message);
    }
    setLoading(false);
  };

  const handleEdit = (event) => {
    if (checkAuth('@events/view')) {
      const fullEvent = events.find((e) => e.id === parseInt(event._def.publicId));
      setEditEvent(fullEvent);
      setFormVisible(true);
    }
  };

  const handleDatesSet = (info) => {
    setMonth(info.view.currentStart.getMonth() + 1);
    setYear(info.view.currentStart.getFullYear());
  };

  const CustomEvent = ({ event }) => {
    const { status } = event.extendedProps;
    const handleStatus = () => {
      switch (status) {
        case 0:
          return '#bee5eb';
        case 1:
          return '#ffc965';
        case 2:
          return '#c6c8ca';
        case 3:
          return '#f5c6cb';
        case 4:
          return '#c3e6cb';
        default:
          return '#ffffff';
      }
    };

    return (
      <div
        style={{
          backgroundColor: handleStatus(),
          padding: isMobile ? '2px' : '8px', // Reduz padding no mobile
          borderRadius: '3px',
          border: '1px solid #ddd',
          boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
          fontSize: isMobile ? '0.7rem' : '0.9rem', // Fonte menor
          color: '#333',
          whiteSpace: 'nowrap', // Impede quebra de linha
          overflow: 'hidden',
          textOverflow: 'ellipsis', // Adiciona "..." se o texto for longo
          width: '100%',
        }}
      >
        <strong>{event.title}</strong>
        <br />
        <span style={{ fontSize: isMobile ? '0.6rem' : '0.8rem' }}>
          {' '}
          {new Date(event.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
        </span>
      </div>
    );
  };

  useEffect(() => {
    fetchEvents();
  }, [month, year]);

  return (
    <Box>
      <Spin spinning={loading}>
        <div
          style={{
            width: '100%',
            overflowX: 'auto', // Força rolagem horizontal
            height: isMobile ? '70vh' : 'calc(100vh - 150px)',
            minWidth: isMobile ? '600px' : 'auto', // Largura mínima para o calendário
          }}
        >
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={events?.map((event) => ({
              id: event.id,
              title: event.name,
              start: event.starts_at,
              extendedProps: { status: event.status },
            }))}
            locale={ptLocale}
            datesSet={handleDatesSet}
            eventClick={(info) => handleEdit(info.event)}
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'today',
            }}
            eventContent={(arg) => <CustomEvent event={arg.event} />}
            titleFormat={{ month: 'long', year: 'numeric' }}
            dayHeaderClassNames="my-custom-day-header"
            dayCellClassNames="my-custom-day-cell"
            height="auto" // 🔹 Garante que a altura se ajuste corretamente
            contentHeight="auto" // 🔹 Impede que o calendário fique maior que a tela
            aspectRatio={isMobile ? 0.5 : 1.35} // Reduz ainda mais no mobile
            dayHeaderContent={(arg) => (
              <div style={{ fontSize: isMobile ? '0.8rem' : '1rem' }}>{arg.text.replace('feira', '')}</div>
            )}
          />
        </div>
      </Spin>
      <FormEvents
        visible={formVisible}
        event={editEvent}
        onClose={() => {
          setFormVisible(false);
          setEditEvent(null);
          fetchEvents();
        }}
      />
    </Box>
  );
}
