import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';

const endPoint = 'financialbalancesheets';

// GET
export const getFinancialBalanceSheets = async (params) => {
  try {
    const { data } = await api.get(`/${endPoint}`, params);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// CREATE
export const createFinancialBalanceSheets = async (values) => {
  try {
    const { data } = await api.post(`/${endPoint}`, values);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// UPDATE
export const updateFinancialBalanceSheets = async (id, values) => {
  try {
    const { data } = await api.put(`/${endPoint}/${id}`, values);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// DELETE
export const deleteFinancialBalanceSheets = async (id) => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

getFinancialBalanceSheets.propTypes = {
  params: PropTypes.object.isRequired,
};

createFinancialBalanceSheets.propTypes = {
  values: PropTypes.object.isRequired,
};

updateFinancialBalanceSheets.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteFinancialBalanceSheets.propTypes = {
  id: PropTypes.string.isRequired,
};
