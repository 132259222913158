import { Input } from 'formik-antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegFilePdf } from 'react-icons/fa';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import InputCurrency from '~/components/Form/InputCurrency';
import Row from '~/components/Row';

export default function Real({ handlePdf, values, loading }) {
  const { t } = useTranslation();

  return (
    <>
      <Row
        style={{
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'end',
        }}
      >
        <Button
          color="primary"
          icon={<FaRegFilePdf />}
          onClick={() => handlePdf(values, 'real-report')}
          title={t('Baixar relatório (interno)')}
          loading={loading}
        />
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <h2>{t('Valores')}</h2>
      </Row>
      <Row>
        <FormControl cols={{ sm: 8, xs: 12 }} label={t('Débito bruto (R$)')} field="debit_raw_price">
          <InputCurrency name="debit_raw_price" readOnly />
        </FormControl>
        <FormControl cols={{ sm: 8, xs: 12 }} label={t('Crédito bruto (R$)')} field="credit_raw_price">
          <InputCurrency name="credit_raw_price" readOnly />
        </FormControl>
        <FormControl cols={{ sm: 8, xs: 24 }} label={t('Pix bruto (R$)')} field="pix_raw_price">
          <InputCurrency name="pix_raw_price" readOnly />
        </FormControl>
      </Row>
      <Row style={{ marginBottom: 20, marginTop: 20 }}>
        <h2>{t('Total')}</h2>
      </Row>
      <Row>
        <FormControl cols={{ sm: 8, xs: 12 }} label={t('Total líquido real')} field="revenues_price">
          <InputCurrency name="revenues_price" readOnly />
        </FormControl>
        <FormControl cols={{ sm: 8, xs: 12 }} label={t('Total líquido cliente')} field="total_liquid_client">
          <InputCurrency name="total_liquid_client" readOnly />
        </FormControl>
        <FormControl cols={{ sm: 8, xs: 24 }} label={t('Ágio de taxa admninistrativa total')} field="agio_total">
          <InputCurrency name="agio_total" readOnly />
        </FormControl>
      </Row>
      <Row style={{ marginBottom: 20, marginTop: 20 }}>
        <h2>{t('Sobras/Faltas de dinheiro')}</h2>
      </Row>
      <Row>
        {values.event_financial_losses?.map((efl, index) => (
          <>
            <FormControl cols={{ sm: 12, xs: 12 }} label={t('Descrição')}>
              <Input readOnly name={`event_financial_losses.${index}.name`} />
            </FormControl>
            <FormControl cols={{ sm: 12, xs: 12 }} label={t('Valor (R$)')}>
              <InputCurrency readOnly value={efl.price} name={`event_financial_losses.${index}.price`} />
            </FormControl>
          </>
        ))}
      </Row>
      <Row>
        <FormControl cols={{ sm: 24, xs: 24 }} label={t('Total de sobras/faltas')} field="losses_price">
          <InputCurrency readOnly name={`losses_price`} />
        </FormControl>
      </Row>
      <Row style={{ marginBottom: 20, marginTop: 20 }}>
        <h2>{t('Pagamentos')}</h2>
      </Row>
      <Row>
        {values.event_financial_payments?.map((efp, index) => (
          <>
            <FormControl cols={{ sm: 12, xs: 12 }} label={t('Descrição')}>
              <Input readOnly name={`event_financial_payments.${index}.name`} />
            </FormControl>
            <FormControl cols={{ sm: 12, xs: 12 }} label={t('Valor (R$)')}>
              <InputCurrency readOnly value={efp.price} name={`event_financial_payments.${index}.price`} />
            </FormControl>
          </>
        ))}
      </Row>
      <Row>
        <FormControl cols={{ sm: 24, xs: 24 }} label={t('Total de pagamentos')} field="payments_price">
          <InputCurrency readOnly name={`payments_price`} />
        </FormControl>
      </Row>
      <Row style={{ marginBottom: 20, marginTop: 20 }}>
        <h2>{t('Total final')}</h2>
      </Row>
      <Row>
        <FormControl cols={{ sm: 12, xs: 12 }} field="final_price" label={t('Real')}>
          <InputCurrency readOnly name={`final_price`} />
        </FormControl>
        <FormControl cols={{ sm: 12, xs: 12 }} field="customer_final_price" label={t('Cliente')}>
          <InputCurrency readOnly name={`customer_final_price`} />
        </FormControl>
      </Row>
    </>
  );
}
