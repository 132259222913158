import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';

const $endPoint = 'me';

// GET ME
export const getMe = async (params, route = '') => {
  try {
    const { data } = await api.get(`/${$endPoint}/${route}`, params);
    return data;
  } catch (error) {
    errorHandler(error);
  }
};
