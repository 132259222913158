import api from '~/services/api';
import PropTypes from 'prop-types';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'rolespermissions';

// GET
export const getRolesPermissions = async (params, route = '') => {
  try {
    const { data } = await api.get(`/${endPoint}/${route}`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

// CREATE
export const createRolesPermissions = async (values) => {
  try {
    const { data } = await api.post(`/${endPoint}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// UPDATE
export const updateRolesPermissions = async (route = '', values) => {
  try {
    const { data } = await api.put(`/${endPoint}/${route}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// DELETE
export const deleteRolesPermissions = async (id) => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successDelete`));
    return data;
  } catch (error) {
    throw error;
  }
};

getRolesPermissions.propTypes = {
  params: PropTypes.object.isRequired,
};

createRolesPermissions.propTypes = {
  values: PropTypes.object.isRequired,
};

updateRolesPermissions.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteRolesPermissions.propTypes = {
  id: PropTypes.string.isRequired,
};
