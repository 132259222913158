import api from '~/services/api';
import PropTypes from 'prop-types';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import i18next from 'i18next';

const endPoint = 'eventfinancials';

// GET
export const getEventsFinancials = async (params, route = '') => {
  try {
    const { data } = await api.get(`/${endPoint}/${route}`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

// CREATE
export const createEventsFinancials = async (values, route = '') => {
  try {
    const { data } = await api.post(`/${endPoint}/${route}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// UPDATE
export const updateEventsFinancials = async (id, values) => {
  try {
    const { data } = await api.put(`/${endPoint}/${id}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// DELETE
export const deleteEventsFinancials = async (id) => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    message.success(i18next.t(`messages:${endPoint}.successDelete`));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

// PDF
export const pdfEventsFinancials = async (values, route = '') => {
  try {
    const response = await api.post(`/${endPoint}/${route}`, values);
    return response; // Retorna o response inteiro, não apenas data
  } catch (error) {
    throw error;
  }
};

getEventsFinancials.propTypes = {
  params: PropTypes.object.isRequired,
};

createEventsFinancials.propTypes = {
  values: PropTypes.object.isRequired,
};

updateEventsFinancials.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteEventsFinancials.propTypes = {
  id: PropTypes.string.isRequired,
};
