import styled from 'styled-components';

export const Container = styled.div`
  display: flex; /* Configura o container como flexbox */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  font-size: ${(props) => props.size};
  font-style: italic;
  font-weight: bold;
  color: ${(props) => props.color || props.theme.color1};
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  width: 100%; /* Garante que o container ocupe todo o espaço disponível */
  height: 100%; /* Ajuste conforme necessário */

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

export const Title = styled.h1`
  color: #666666;
  border-top: 0;
  padding-top: 0;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

export const SubTitle = styled.span`
  margin-bottom: 15px;
`;
