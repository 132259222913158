import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <Container>
      <div>{t('app:poweredBy')}</div>{' '}
      <div>
        <img src="/images/logo-image.png" width={30} />
      </div>{' '}
      | <div>{t('app:version')}</div>
    </Container>
  );
}
