import React from 'react';
import { Routes as RouterRoutes, Route, Navigate, useNavigate } from 'react-router-dom';
import Login from '~/pages/Login';
import ErrorPage from '~/pages/Errors/e404';
import Dashboard from '~/pages/Dashboard';
import Cards from '~/pages/Cards';
import Patrimonies from '~/pages/Patrimonies';
import ExtraRevenues from '~/pages/ExtraRevenues';
import ExtraExpenses from '~/pages/ExtraExpenses';
import Operationals from '~/pages/Operationals';
import Services from '~/pages/Services';
import FinancialExpenses from '~/pages/FinancialExpenses';
import Expenses from '~/pages/Expenses';
import Items from '~/pages/Items';
import Batches from '~/pages/Batches';
import StockWriteOffs from '~/pages/StockWriteOffs';
import Users from '~/pages/Users';
import Employees from '~/pages/Employees';
import Reports from '~/pages/Reports';
import Events from '~/pages/Events';
import Customers from '~/pages/Customers';
import { navigationRef } from '~/services/navigate.js';
import Roles from '~/pages/Roles/index.js';
import EventsRevenues from '~/pages/EventsRevenues/index.js';
import FinancialBalanceSheets from '~/pages/FinancialBalanceSheets/index.js';
import PrivateRoute from './privateRoute';

export default function Routes() {
  const navigate = useNavigate();
  navigationRef.current = navigate;

  return (
    <RouterRoutes>
      <Route
        path="/dashboard"
        element={<PrivateRoute element={<Dashboard />} requiredPermission="@dashboard/view" />}
      />
      <Route
        path="/despesas-financeiras"
        element={<PrivateRoute element={<FinancialExpenses />} requiredPermission="@expenses/view" />}
      />
      <Route path="/cartoes" element={<PrivateRoute element={<Cards />} requiredPermission="@cards/view" />} />
      <Route
        path="/patrimonios"
        element={<PrivateRoute element={<Patrimonies />} requiredPermission="@patrimonies/view" />}
      />
      <Route
        path="/receitas-extra"
        element={<PrivateRoute element={<ExtraRevenues />} requiredPermission="@extras/view" />}
      />
      <Route
        path="/despesas-extra"
        element={<PrivateRoute element={<ExtraExpenses />} requiredPermission="@extraExpenses/view" />}
      />
      <Route
        path="/receita-eventos"
        element={<PrivateRoute element={<EventsRevenues />} requiredPermission="@eventsReceipts/view" />}
      />
      <Route
        exact
        path="/balanco-geral"
        element={<PrivateRoute element={<FinancialBalanceSheets />} requiredPermission="@eventsReceipts/view" />}
        isPrivate
      />
      <Route path="/relatorios" element={<PrivateRoute element={<Reports />} requiredPermission="@reports/view" />} />
      <Route
        path="/operacional"
        element={<PrivateRoute element={<Operationals />} requiredPermission="@operationals/view" />}
      />
      <Route path="/servicos" element={<PrivateRoute element={<Services />} requiredPermission="@services/view" />} />
      <Route
        path="/evento-despesas"
        element={<PrivateRoute element={<Expenses />} requiredPermission="@eventsExpenses/view" />}
      />
      <Route path="/clientes" element={<PrivateRoute element={<Customers />} requiredPermission="@clients/view" />} />
      <Route path="/produtos" element={<PrivateRoute element={<Items />} requiredPermission="@products/view" />} />
      <Route path="/lotes" element={<PrivateRoute element={<Batches />} requiredPermission="@lots/view" />} />
      <Route
        path="/baixas-estoques"
        element={<PrivateRoute element={<StockWriteOffs />} requiredPermission="@lowStock/view" />}
      />
      <Route path="/usuarios" element={<PrivateRoute element={<Users />} requiredPermission="@users/view" />} />
      <Route
        path="/funcionarios"
        element={<PrivateRoute element={<Employees />} requiredPermission="@employees/view" />}
      />
      <Route path="/eventos" element={<PrivateRoute element={<Events />} requiredPermission="@events/view" />} />
      <Route
        path="/grupo-de-usuarios"
        element={<PrivateRoute element={<Roles />} requiredPermission="@roles/view" />}
      />

      {/* Login */}
      <Route path="/" element={<Login />} />

      {/* Página de erro 404 */}
      <Route path="/404" element={<ErrorPage />} />

      {/* Qualquer rota não definida leva para 404 */}
      <Route path="/*" element={<Navigate to="/404" />} />
    </RouterRoutes>
  );
}
