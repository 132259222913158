import { Row as AntRow, Button, Col, Dropdown, Menu, message, Tag, Typography } from 'antd';
import { InputNumber } from 'formik-antd';
import React, { useEffect, useState } from 'react';
import FormControl from '~/components/Form/FormControl';
import InputCurrency from '~/components/Form/InputCurrency';
import Row from '~/components/Row';
import { FieldArray } from 'formik';
import { FaDownload, FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import { getItems } from '~/services/hooks/items';
import { SelectAntd } from '~/components/Pagination/styles';
import ComponentButton from '~/components/Button';
import EventsTableImport from './modalEventsTable';
import { Table } from '~/components/Table';
import { tableSwoiColumns } from '../table';
import { pdfEvents } from '~/services/hooks/events';

export const ConsignedsModal = ({
  t,
  loading,
  setLoading,
  values,
  setFieldValue,
  errors,
  visible,
  fluidPermissions,
  isMobile,
}) => {
  const [items, setItems] = useState([]);
  const [modalEventsTableVisible, setModalEventsTableVisible] = useState(false);

  const menu = (
    <Menu>
      <Menu.Item key="real" onClick={() => handlePdf(values, 'real')}>
        Emitir PDF Real
      </Menu.Item>
      <Menu.Item key="cliente" onClick={() => handlePdf(values, 'client')}>
        Emitir PDF Cliente
      </Menu.Item>
    </Menu>
  );

  const handlePdf = async (event, type) => {
    setLoading(true);
    try {
      event.pdf_type = type;
      event.total_cost = totalCost;
      event.total_price = totalPrice;
      event.total_weight = totalWeight;
      const response = await pdfEvents(event, 'issue-swoi');
      window.open(response.data, '_blank');
    } catch (error) {
      console.log(error);
      message.error(t('Não foi possível emitir o PDF. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const fetchItems = async () => {
    setLoading(true);
    try {
      const data = await getItems('', 'no-paginated');
      setItems(data);
    } catch (error) {
      console.log(error);
      message.error(`Não foi possível carregar itens. Erro: ${error.message}`);
    }
    setLoading(false);
  };

  const handleImportItems = () => {
    setModalEventsTableVisible(true);
  };

  const totalGeneral = (Array.isArray(values?.consigneds) ? values.consigneds : []).reduce((acc, consigned) => {
    const total = (parseFloat(consigned.price_unit) || 0) * (consigned.amount || 0);
    return acc + total;
  }, 0);

  const totalCost = (Array.isArray(values?.stock_write_offs?.swoi) ? values?.stock_write_offs?.swoi : []).reduce(
    (acc, swoi) => {
      const total = parseFloat(swoi.total_cost_price) || 0;
      return acc + total;
    },
    0
  );

  const totalPrice = (Array.isArray(values?.stock_write_offs?.swoi) ? values?.stock_write_offs?.swoi : []).reduce(
    (acc, swoi) => {
      const total = parseFloat(swoi.total_consigned_price) || 0;
      return acc + total;
    },
    0
  );

  const totalWeight = (Array.isArray(values?.stock_write_offs?.swoi) ? values?.stock_write_offs?.swoi : []).reduce(
    (acc, swoi) => {
      const total = parseFloat(swoi.item_weight) || 0;
      return acc + total;
    },
    0
  );

  useEffect(() => {
    values?.consigneds?.forEach((consigned, index) => {
      const total = (parseFloat(consigned.price_unit) || 0) * (consigned.amount || 0);
      setFieldValue(`consigneds.${index}.price_total`, total.toFixed(2).toString());
    });
  }, [values.consigneds, setFieldValue]);

  useEffect(() => {
    if (visible) {
      fetchItems();
    }
  }, [visible]);

  return (
    <>
      <Col
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 15,
          flexWrap: isMobile ? 'wrap' : '',
        }}
      >
        {!values?.stock_write_offs?.swoi ? (
          <ComponentButton
            color="primary"
            onClick={handleImportItems}
            disabled={!fluidPermissions()}
            style={{ width: isMobile ? '48%' : '' }}
          >
            {t('Importar bebidas (evento)')}
          </ComponentButton>
        ) : (
          <Dropdown overlay={menu} trigger={['click']}>
            <ComponentButton
              color="primary"
              icon={<FaDownload />}
              style={{ marginRight: 20, width: isMobile ? '48%' : '', marginBottom: isMobile ? 15 : '' }}
            >
              Emitir PDF
            </ComponentButton>
          </Dropdown>
        )}
        <Col
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: isMobile ? 'space-between' : 'center',
            gap: 20,
            width: isMobile ? '100%' : '',
          }}
        >
          {!values.stock_write_offs && (
            <ComponentButton
              onClick={() => {
                const updatedSwoi = values.consigneds.map((item) => ({
                  item_name: item.item_name,
                  item_id: item.item_id,
                  consigned_amount: item.amount,
                  amount_returned: 0,
                  amount: item.amount,
                  item_cost: item.item_cost,
                  price_unit: item.price_unit,
                }));

                setFieldValue('stock_write_offs', { action: 0, without_cost: 0, swoi: updatedSwoi });
              }}
              style={{ width: isMobile ? '48%' : '' }}
              disabled={!fluidPermissions() || values.consigneds?.length < 1}
            >
              {t('Dar baixa em estoque')}
            </ComponentButton>
          )}
          {!values?.stock_write_offs?.swoi ? (
            <Tag
              color="default"
              style={{
                fontSize: isMobile ? '12px' : '15px',
                padding: '10px 20px',
              }}
            >
              {t('Total geral: ') + totalGeneral.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            </Tag>
          ) : (
            <Col>
              <Tag
                color="default"
                style={{
                  fontSize: isMobile ? '12px' : '15px',
                  padding: '10px 20px',
                  width: isMobile ? '47%' : '',
                  textAlign: 'center',
                }}
              >
                {t('Total peso: ') + totalWeight}
              </Tag>
              <Tag
                color="default"
                style={{
                  fontSize: isMobile ? '12px' : '15px',
                  padding: '10px 20px',
                  width: isMobile ? '47%' : '',
                  textAlign: 'center',
                }}
              >
                {t('Total custo: ') + totalCost.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </Tag>
              <Tag
                color="default"
                style={{
                  fontSize: isMobile ? '12px' : '15px',
                  padding: '10px 20px',
                  width: isMobile ? '100%' : '',
                  marginTop: isMobile ? 15 : '',
                  textAlign: 'center',
                }}
              >
                {t('Total preço: ') + totalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </Tag>
            </Col>
          )}
        </Col>
      </Col>

      <Row>
        {!values.stock_write_offs ? (
          <FieldArray name="consigneds">
            {({ push, remove }) => (
              <>
                {values?.consigneds?.map((consigned, index) => {
                  const total = (parseFloat(consigned.price_unit) || 0) * (consigned.amount || 0);

                  return (
                    <AntRow
                      key={index}
                      gutter={4}
                      align="middle"
                      style={{
                        border: '1px solid #d9d9d9',
                        borderRadius: 10,
                        padding: 20,
                        marginBottom: 10,
                        width: '100%',
                        position: 'relative',
                      }}
                    >
                      <Typography.Text
                        style={{
                          position: 'absolute',
                          top: 10,
                          right: 20,
                          fontWeight: 'bold',
                        }}
                      >
                        Total: {total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                      </Typography.Text>
                      <Col sm={6} xs={24}>
                        <FormControl
                          cols={{ xs: 24 }}
                          field={`consigneds.${index}.item_id`}
                          error={errors?.consigneds?.[index]?.item_id}
                          required
                          label={t('fields:consigneds.item_id')}
                        >
                          <SelectAntd
                            placeholder="SELECIONE"
                            value={consigned.item_id ?? null}
                            onSelect={(value) => {
                              setFieldValue(`consigneds.${index}.item_id`, value);
                            }}
                            loading={loading}
                            showSearch
                            optionFilterProp="children"
                            disabled={!fluidPermissions()}
                          >
                            {items?.map((item) => (
                              <SelectAntd.Option key={item.id} value={item.id}>
                                {item.name}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </FormControl>
                      </Col>
                      <Col sm={4} xs={12}>
                        <FormControl
                          cols={{ xs: 24 }}
                          field={`consigneds.${index}.amount`}
                          error={errors?.consigneds?.[index]?.amount}
                          required
                          label={t('fields:consigneds.amount')}
                        >
                          <InputNumber
                            name={`consigneds.${index}.amount`}
                            onChange={(value) => setFieldValue(`consigneds.${index}.amount`, value)}
                            disabled={!fluidPermissions()}
                          />
                        </FormControl>
                      </Col>
                      <Col sm={6} xs={12}>
                        <FormControl
                          cols={{ xs: 24 }}
                          field={`consigneds.${index}.packs`}
                          error={errors?.consigneds?.[index]?.packs}
                          required
                          label={t('fields:consigneds.packs')}
                        >
                          <InputCurrency name={`consigneds.${index}.packs`} disabled={!fluidPermissions()} />
                        </FormControl>
                      </Col>
                      <Col sm={6}>
                        <FormControl
                          cols={{ xs: 24 }}
                          field={`consigneds.${index}.price_unit`}
                          error={errors?.consigneds?.[index]?.price_unit}
                          required
                          label={t('fields:consigneds.priceUnit')}
                        >
                          <InputCurrency
                            name={`consigneds.${index}.price_unit`}
                            onChange={(value) => setFieldValue(`consigneds.${index}.price_unit`, value)}
                            disabled={!fluidPermissions()}
                          />
                        </FormControl>
                      </Col>
                      <Col sm={2}>
                        <Button
                          color="danger"
                          title="Remover item"
                          onClick={() => remove(index)}
                          style={{ marginTop: 4 }}
                          disabled={!fluidPermissions()}
                        >
                          <FaRegTimesCircle color="red" />
                        </Button>
                      </Col>
                    </AntRow>
                  );
                })}
                <Row>
                  <Button
                    color="primary"
                    title="Adicionar item"
                    onClick={() =>
                      push({
                        item_id: null,
                        amount: null,
                        packs: '',
                        price_unit: '',
                        price_total: '0.00',
                      })
                    }
                    style={{ marginTop: '16px', marginLeft: 16 }}
                    disabled={!fluidPermissions()}
                  >
                    <FaPlusCircle color="green" />
                  </Button>
                </Row>
              </>
            )}
          </FieldArray>
        ) : (
          <>
            <Table
              pagination={false}
              rowKey="id"
              loading={loading}
              dataSource={values.stock_write_offs.swoi || []}
              columns={tableSwoiColumns(setFieldValue, isMobile)}
              bordered
              style={{ width: '100%' }}
            />
            {/* <Col>{tableSwoiTotal([...(values.stock_write_offs?.swoi || []), ...(values.consigneds || [])])}</Col> */}
          </>
        )}
      </Row>
      <EventsTableImport
        visible={modalEventsTableVisible}
        setFieldValue={setFieldValue}
        isMobile={isMobile}
        onClose={() => setModalEventsTableVisible(false)}
      />
    </>
  );
};
