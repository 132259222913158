import { Col, Segmented } from 'antd';
import styled from 'styled-components';

export const Container = styled(Col)`
  width: 99.5%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const StyledSegmented = styled(Segmented)`
  display: flex;
  gap: 10px;
  background-color: transparent;
  .ant-segmented-item {
    background-color: #2e394b; // Cor de fundo das opções
    color: #ffffff; // Cor do texto
    margin-bottom: 10px;
    &:hover {
      background-color: #fad301; // Cor ao passar o mouse
      color: rgb(0, 0, 0);
    }

    &:focus-within {
      background-color: #fad301;
      color: rgb(0, 0, 0);
    }
  }
`;
