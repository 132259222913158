import React from 'react';

import { Container } from './styles';

export default function Row({ children, ...props }) {
  return (
    <Container gutter={16} align="top" {...props}>
      {children}
    </Container>
  );
}
