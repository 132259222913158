import React, { useEffect, useState } from 'react';
import { getReports, getReportsDownload } from '~/services/hooks/reports';
import errorHandler from '~/Utils/errorHandler';
import Box from '~/components/Box';
import { Table, TableHeader } from '~/components/Table';
import { Col, Input, message } from 'antd';
import Pagination from '~/components/Pagination';
import Global from '~/styles/global';
import { tableColumns } from './table';
import { useTranslation } from 'react-i18next';
import Button from '~/components/Button';
import ReportModal from './modal';
import { useCheckAuth } from '~/components/Can/checkAuth';
import ColButton from '~/components/ColButton';
import IsMobile from '~/services/mobileCheck';

export default function Reports() {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const isMobile = IsMobile();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState({});
  const [search, setSearch] = useState('');
  const [reportEdit, setReportEdit] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          page,
          perPage,
          search,
        },
      };
      const data = await getReports(params);

      setData(data.data);
      setMeta(data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleEdit = (report) => {
    setReportEdit(report);
    setModalVisible(true);
  };

  const fetchReports = async (recordId) => {
    setLoading(true);
    try {
      await getReportsDownload('', `download/${recordId}`);
      message.success('Iniciando download');
    } catch (error) {
      console.log(error);
      message.error(`Não foi possível baixar os arquivos. Erro: ${error.message}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, search]);

  return (
    <>
      <Box>
        <ColButton>
          <Button color="primary" onClick={() => handleEdit(null)} disabled={!authCheck('@reports/create')}>
            Criar relatório
          </Button>
        </ColButton>
        <TableHeader>
          <Col style={{ display: 'flex' }}>
            <Input.Search
              onSearch={(value) => {
                setSearch(value);
                setPage(1);
              }}
              className="search-field"
              placeholder={t('screens:default.search')}
            />
          </Col>
        </TableHeader>
        <Table
          pagination={false}
          rowKey="id"
          loading={loading}
          dataSource={data}
          columns={tableColumns(t, handleEdit, fetchReports, isMobile)}
          bordered
        />
        <Pagination
          isMobile={isMobile}
          onChange={(value) => {
            setPage(value);
          }}
          meta={meta}
          perPage={perPage}
          setPerPage={setPerPage}
        />
        <Global />
      </Box>
      <ReportModal
        visible={modalVisible}
        report={reportEdit}
        isMobile={isMobile}
        onClose={() => {
          setModalVisible(false);
          setReportEdit(null);
          fetchData();
        }}
      />
    </>
  );
}
