import api from '~/services/api';
import PropTypes from 'prop-types';

const endPoint = 'items';

// GET
export const getItems = async (params, url = '') => {
  try {
    const { data } = await api.get(`/${endPoint}/${url}`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

// CREATE
export const createItems = async (values) => {
  try {
    const { data } = await api.post(`/${endPoint}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// UPDATE
export const updateItems = async (id, values) => {
  try {
    const { data } = await api.put(`/${endPoint}/${id}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

// DELETE
export const deleteItems = async (id) => {
  try {
    const { data } = await api.delete(`/${endPoint}/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

getItems.propTypes = {
  params: PropTypes.object.isRequired,
};

createItems.propTypes = {
  values: PropTypes.object.isRequired,
};

updateItems.propTypes = {
  values: PropTypes.object.isRequired,
};

deleteItems.propTypes = {
  id: PropTypes.string.isRequired,
};
