import React, { useEffect, useState } from 'react';
import { deleteEmployees, getEmployees } from '~/services/hooks/employees';
import { getStates } from '~/services/hooks/states';
import { getCities } from '~/services/hooks/cities';
import Box from '~/components/Box';
import { Table, TableHeader } from '~/components/Table';
import { Col, Input, message } from 'antd';
import Pagination from '~/components/Pagination';
import { SelectAntd } from '~/components/Pagination/styles';
import { tableColumns } from './table';
import { useTranslation } from 'react-i18next';
import Button from '~/components/Button';
import EmployeesModal from './form';
import { useCheckAuth } from '~/components/Can/checkAuth';
import ColButton from '~/components/ColButton';
import IsMobile from '~/services/mobileCheck';

export default function Employees() {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const isMobile = IsMobile();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState({});
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useState({
    city_id: null,
  });
  const [cityValue, setCityValue] = useState(null);
  const [employeesEdit, setEmployeesEdit] = useState({});
  const [ModalVisible, setModalVisible] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          page,
          perPage,
          search,
          ...searchParams,
        },
      };
      const { data, meta } = await getEmployees(params);
      setData(data);
      setMeta(meta);
    } catch (error) {
      console.log(error);
      message.error(t('Não foi possível carregar os funcionários. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const fetchStates = async () => {
    setLoading(true);
    try {
      const data = await getStates('', 'no-paginated');
      setStates(data);
    } catch (error) {
      console.log(error);
      message.error(t('Não foi possível carregar os estados. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const fetchCities = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          ...searchParams,
        },
      };
      const { data } = await getCities(params, 'no-paginated');
      setCities(data);
    } catch (error) {
      console.log(error);
      message.error(t('Não foi possível carregar as cidades. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const handleEdit = (record) => {
    setEmployeesEdit(record);
    setModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteEmployees(id);
      message.success(`Funcionário excluído`);
      fetchData();
    } catch (error) {
      console.log(error);
      message.error(t('Não foi possível deletar o funcionário. Erro: ') + error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, search, searchParams]);

  useEffect(() => {
    fetchStates();
  }, []);

  useEffect(() => {
    fetchCities();
    if (searchParams.state_id === '') {
      setCityValue('');
    }
  }, [searchParams]);

  return (
    <Box>
      <ColButton>
        <Button color="primary" onClick={() => handleEdit(null)} disabled={!authCheck('@employees/create')}>
          {t('screens:employees.addEmployee')}
        </Button>
      </ColButton>
      <TableHeader>
        <Col
          style={{
            display: 'flex',
            flexWrap: isMobile ? 'wrap' : 'nowrap',
            gap: isMobile ? '10px' : '0',
          }}
        >
          <Input.Search
            onSearch={(value) => {
              setSearch(value);
              setPage(1);
            }}
            className="search-field"
            placeholder={t('screens:default.search')}
            style={{ width: isMobile ? '100%' : 'auto' }}
          />
          <SelectAntd
            style={{ width: isMobile ? '100%' : '200px', marginRight: isMobile ? '0' : '20px' }}
            placeholder={t('screens:default.state')}
            value={searchParams?.state_id || null}
            onSelect={(value) => {
              setSearchParams((initialValues) => ({
                ...initialValues,
                state_id: value,
                city_id: null,
              }));
              setCityValue(null);
              setPage(1);
            }}
          >
            <SelectAntd.Option value="">{t('screens:default.all')}</SelectAntd.Option>
            {states.map((state) => (
              <SelectAntd.Option key={state.id} value={state.id}>
                {state.name}
              </SelectAntd.Option>
            ))}
          </SelectAntd>
          <SelectAntd
            value={cityValue}
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            placeholder={t('screens:default.city')}
            onSelect={(value) => {
              setCityValue(value);
              setSearchParams((initialValues) => ({
                ...initialValues,
                city_id: value,
              }));
              setPage(1);
            }}
            disabled={!searchParams?.state_id}
            style={{ width: isMobile ? '100%' : 'auto' }}
          >
            {cities.map((city) => (
              <SelectAntd.Option key={city.id} value={city.id}>
                {city.name}
              </SelectAntd.Option>
            ))}
          </SelectAntd>
        </Col>
      </TableHeader>
      <Table
        pagination={false}
        rowKey="id"
        loading={loading}
        dataSource={data}
        columns={tableColumns(t, handleEdit, handleDelete, isMobile)}
        bordered
      />
      <Pagination
        isMobile={isMobile}
        onChange={(value) => {
          setPage(value);
        }}
        meta={meta}
        perPage={perPage}
        setPerPage={setPerPage}
      />
      <EmployeesModal
        visible={ModalVisible}
        employeesEdit={employeesEdit}
        isMobile={isMobile}
        onClose={() => {
          setModalVisible(false);
          setEmployeesEdit(null);
          fetchData();
        }}
      />
    </Box>
  );
}
