import React, { useEffect, useState } from 'react';
import { getEventsFinancials } from '~/services/hooks/eventFinancials';
import errorHandler from '~/Utils/errorHandler';
import Box from '~/components/Box';
import { Table, TableHeader } from '~/components/Table';
import { Col, Input } from 'antd';
import Pagination from '~/components/Pagination';
import Global from '~/styles/global';
import { tableColumns } from './table';
import { useTranslation } from 'react-i18next';
import FormEvents from '../Events/forms/modal';
import IsMobile from '~/services/mobileCheck';

export default function EventsFinancials() {
  const { t } = useTranslation();
  const isMobile = IsMobile();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState({});
  const [search, setSearch] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [viewEvent, setViewEvent] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        params: {
          page,
          perPage,
          search,
        },
      };
      const data = await getEventsFinancials(params);
      setData(data.data);
      setMeta(data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleEdit = (record) => {
    setViewEvent(record);
    setModalVisible(true);
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, search]);

  return (
    <Box>
      <TableHeader>
        <Col style={{ display: 'flex' }}>
          <Input.Search
            onSearch={(value) => {
              setSearch(value);
              setPage(1);
            }}
            className="search-field"
            placeholder={t('screens:default.search')}
          />
        </Col>
      </TableHeader>
      <Table
        pagination={false}
        rowKey="id"
        loading={loading}
        dataSource={data}
        columns={tableColumns(t, handleEdit, isMobile)}
        bordered
      />
      <Pagination
        isMobile={isMobile}
        onChange={(value) => {
          setPage(value);
        }}
        meta={meta}
        perPage={perPage}
        setPerPage={setPerPage}
      />
      <Global />
      <FormEvents
        visible={modalVisible}
        event={viewEvent}
        isMobile={isMobile}
        onClose={() => {
          setModalVisible(false);
          setViewEvent(null);
          fetchData();
        }}
      />
    </Box>
  );
}
