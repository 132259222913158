import React from 'react';
import Strong from '~/components/Strong';

export const tableColumns = (t, selectedPeriod, isMobile) => {
  if (isMobile) {
    return [
      {
        dataIndex: 'summary',
        key: 'summary',
        render: (text, record) => {
          const meses = [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
          ];

          const monthText = record.month ? meses[record.month - 1] : record.year;
          const periodText =
            selectedPeriod === 'month' ? (record.month ? `${monthText} / ${record.year}` : 'TOTAL') : record.year;

          return (
            <div>
              <Strong>{t('Período')}:</Strong> {periodText}
              <br />
              <Strong>{t('Receitas')}:</Strong>{' '}
              {Number(record.revenues).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              <br />
              <Strong>{t('Despesas')}:</Strong>{' '}
              {Number(record.expenses).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              <br />
              <Strong>{t('Lucratividade')}:</Strong>{' '}
              {Number(record.profitability).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              <br />
              <Strong>{t('Receitas extras')}:</Strong>{' '}
              {Number(record.extra_revenues).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              <br />
              <Strong>{t('Despesas extras')}:</Strong>{' '}
              {Number(record.extra_expenses).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              <br />
              <Strong>{t('Patrimônios')}:</Strong>{' '}
              {Number(record.investments).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              <br />
              <Strong>{t('Saldo')}:</Strong>{' '}
              {Number(record.balance).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            </div>
          );
        },
      },
    ];
  }

  return [
    {
      title: t('Período'),
      dataIndex: 'month',
      key: 'period',
      width: '200px',
      render: (month, record) => {
        const meses = [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro',
        ];

        const monthText = month ? meses[month - 1] : record.year; // Se month for null, exibe "Anual"
        const willReturn =
          selectedPeriod === 'month' ? (month ? `${monthText} / ${record.year}` : 'TOTAL') : record.year;
        return willReturn; // Concatena com o ano
      },
    },
    {
      title: t('Receitas'),
      dataIndex: 'revenues',
      key: 'revenues',
      width: '300px',
      render: (text, record) => Number(record.revenues).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // converte para R$
    },
    {
      title: t('Despesas'),
      dataIndex: 'expenses',
      key: 'expenses',
      width: '300px',
      render: (text, record) => Number(record.expenses).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // converte para R$
    },
    {
      title: t('Lucratividade'),
      dataIndex: 'profitability',
      key: 'profitability',
      width: '300px',
      render: (text, record) =>
        Number(record.profitability).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // converte para R$
    },
    {
      title: t('Receitas extras'),
      dataIndex: 'extra_revenues',
      key: 'extra_revenues',
      width: '300px',
      render: (text, record) =>
        Number(record.extra_revenues).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // converte para R$
    },
    {
      title: t('Despesas extras'),
      dataIndex: 'extra_expenses',
      key: 'extra_expenses',
      width: '300px',
      render: (text, record) =>
        Number(record.extra_expenses).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // converte para R$
    },
    {
      title: t('Patrimônios'),
      dataIndex: 'investments',
      key: 'investments',
      width: '300px',
      render: (text, record) =>
        Number(record.investments).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // converte para R$
    },
    {
      title: t('Saldo'),
      dataIndex: 'balance',
      key: 'balance',
      width: '300px',
      render: (text, record) => Number(record.balance).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // converte para R$
    },
  ];
};
