import React, { useEffect, useState } from 'react';
import Box from '~/components/Box';
import { Col, Input, Modal, Spin } from 'antd';
import Button from '~/components/Button';
import { Table, TableHeader } from '~/components/Table';
import { SelectAntd } from '~/components/Pagination/styles';
import DateComponent from '~/components/DateComponent';
import Pagination from '~/components/Pagination';
import Global from '~/styles/global';
import FormEvents from './modal';
import { ModalFooter } from '~/components/Modal';
import { useTranslation } from 'react-i18next';
import errorHandler from '~/Utils/errorHandler';
import { getEvents } from '~/services/hooks/events';
import { tableColumns } from '../table';

export default function EventsTableImport({ visible, onClose, setFieldValue, isMobile }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState({});
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useState({
    status: null,
    payment_status: null,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [formVisible, setFormVisible] = useState(false);
  const [editEvent, setEditEvent] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        page,
        perPage,
        search,
        starts_at: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
        ends_at: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
        ...searchParams,
      };
      const data = await getEvents({ params });

      setData(data.data);
      setMeta(data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSearch = () => {
    fetchData();
  };

  const handleImport = (consigneds) => {
    setLoading(true);
    const sanitizedConsigneds = consigneds.map(({ id: _id, event_id: _eventId, ...rest }) => rest);
    setFieldValue('consigneds', sanitizedConsigneds);
    setLoading(false);
    onClose();
  };

  useEffect(() => {
    if (visible) {
      fetchData();
    }
  }, [visible, page, perPage, searchParams]);

  return (
    <Modal
      title={t('screens:events.importItems')}
      onCancel={onClose}
      afterClose={() => setPage(1)}
      style={{ maxWidth: isMobile ? '100%' : '1700px' }}
      open={visible}
      centered
      width={isMobile ? '100%' : '1700px'}
      footer={<ModalFooter loading={loading} onCancel={onClose} />}
    >
      <Spin spinning={loading}>
        <Box>
          <TableHeader>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                flexWrap: isMobile ? 'wrap' : '',
              }}
            >
              <Input.Search
                onSearch={(value) => {
                  setSearch(value);
                  setPage(1);
                }}
                placeholder="Pesquisar"
                className="search-field"
                style={{ width: isMobile ? '48%' : '', marginBottom: isMobile ? '15px' : '' }}
              />
              <SelectAntd
                style={{ width: isMobile ? '48%' : '200px', marginBottom: isMobile ? '15px' : '' }}
                placeholder="Status"
                onSelect={(value) => {
                  setSearchParams((initialValues) => ({
                    ...initialValues,
                    status: value,
                  }));
                  setPage(1);
                }}
              >
                <SelectAntd.Option value={null}>TODOS</SelectAntd.Option>
                <SelectAntd.Option value={0}>EM PREENCHIMENTO</SelectAntd.Option>
                <SelectAntd.Option value={1}>ORÇAMENTO EM ANÁLISE</SelectAntd.Option>
                <SelectAntd.Option value={2}>FINALIZADO</SelectAntd.Option>
                <SelectAntd.Option value={3}>REPROVADO</SelectAntd.Option>
                <SelectAntd.Option value={4}>APROVADO</SelectAntd.Option>
              </SelectAntd>
              <SelectAntd
                style={{ width: isMobile ? '100%' : '200px', marginBottom: isMobile ? '15px' : '' }}
                placeholder="Pagamento"
                onSelect={(value) => {
                  setSearchParams((initialValues) => ({
                    ...initialValues,
                    payment_status: value,
                  }));
                  setPage(1);
                }}
              >
                <SelectAntd.Option value={null}>TODOS</SelectAntd.Option>
                <SelectAntd.Option value={0}>EM ABERTO</SelectAntd.Option>
                <SelectAntd.Option value={1}>NÃO LÍQUIDADO</SelectAntd.Option>
                <SelectAntd.Option value={2}>LÍQUIDADO</SelectAntd.Option>
              </SelectAntd>
              <Col style={{ width: isMobile ? '48%' : '', marginBottom: isMobile ? '15px' : '' }}>
                <DateComponent label="Data de Início" value={startDate} onChange={setStartDate} />
              </Col>
              <Col style={{ width: isMobile ? '48%' : '', marginBottom: isMobile ? '15px' : '' }}>
                <DateComponent
                  label="Data de Fim"
                  value={endDate}
                  onChange={setEndDate}
                  style={{ width: isMobile ? '48%' : '' }}
                />
              </Col>
              <Button color="default" onClick={handleSearch}>
                Buscar
              </Button>
            </Col>
          </TableHeader>
          <Table
            pagination={false}
            rowKey="id"
            loading={loading}
            dataSource={data}
            columns={tableColumns(t, null, null, null, null, isMobile, handleImport)}
            bordered
          />
          <Pagination
            isMobile={isMobile}
            onChange={(value) => {
              setPage(value);
            }}
            meta={meta}
            perPage={perPage}
            setPerPage={setPerPage}
          />
          <Global />
          <FormEvents
            visible={formVisible}
            event={editEvent}
            onClose={() => {
              setFormVisible(false);
              setEditEvent(null);
              fetchData();
            }}
          />
        </Box>
      </Spin>
    </Modal>
  );
}
