import { Badge, Popconfirm } from 'antd';
import i18next from 'i18next';
import React from 'react';
import { FaEye, FaPencilAlt, FaTrash, FaUserLock } from 'react-icons/fa';
import Button from '~/components/Button';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import Strong from '~/components/Strong';
import { TableActions } from '~/components/Table';

export const tableColumns = (handleEdit, handleDelete, handleRoleForm, isMobile) => {
  if (isMobile) {
    return [
      {
        dataIndex: 'summary',
        key: 'summary',
        render: (text, record) => (
          <div>
            <Strong>{i18next.t('Nome')}:</Strong> {record?.name}
            <br />
            <Strong>{i18next.t('Email')}:</Strong> {record?.email}
            <br />
            <Strong>{i18next.t('Grupo de permissões')}:</Strong> {record?.rolesName?.join(', ')}
            <br />
            <Strong>{i18next.t('Status')}:</Strong>{' '}
            {record?.discarded_at ? (
              <Badge count={i18next.t('Inativo')} />
            ) : (
              <Badge color="green" count={i18next.t('Ativo')} />
            )}
            <br />
            <TableActions align="center" justify="center" isMobile={isMobile}>
              <Can permission="@users/edit">
                <Button size="small" onClick={() => handleEdit(record)} title={i18next.t('messages:edit')}>
                  <FaPencilAlt />
                </Button>
              </Can>
              <Cannot permission="@users/edit">
                <Button size="small" onClick={() => handleEdit(record)} title={i18next.t('messages:edit')}>
                  <FaEye />
                </Button>
              </Cannot>
              <Can permission="@users/edit">
                <Button
                  onClick={() => handleRoleForm(record)}
                  size="small"
                  title={i18next.t('messages:editPermissions')}
                  style={{ marginLeft: 5 }}
                >
                  <FaUserLock />
                </Button>
              </Can>
              <Can permission="@users/delete">
                <Popconfirm
                  icon={false}
                  title={i18next.t('messages:confirmDelete')}
                  onConfirm={() => handleDelete(record.id)}
                  okText={i18next.t('messages:yes')}
                  cancelText={i18next.t('messages:no')}
                >
                  <Button size="small" title={i18next.t('messages:delete')}>
                    <FaTrash />
                  </Button>
                </Popconfirm>
              </Can>
            </TableActions>
          </div>
        ),
      },
    ];
  }

  return [
    {
      title: i18next.t('Nome'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: i18next.t('Email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: i18next.t('Grupo de permissões'),
      dataIndex: 'rolesName',
      key: 'rolesName',
      render: (rolesName) => rolesName?.join(', '), // Junta os valores com ", "
    },
    {
      title: i18next.t('Status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '100px',
      render: (text, record) =>
        record.discarded_at ? (
          <Badge count={i18next.t('Inativo')} />
        ) : (
          <Badge color="green" count={i18next.t('Ativo')} />
        ),
    },
    {
      title: i18next.t('screens:default.actions'),
      key: 'actions',
      align: 'center',
      width: '10%',
      className: 'table-action',
      render: (text, record) => (
        <TableActions align="center" justify="center">
          <Can permission="@users/edit">
            <Button size="small" onClick={() => handleEdit(record)} title={i18next.t('messages:edit')}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@users/edit">
            <Button size="small" onClick={() => handleEdit(record)} title={i18next.t('messages:edit')}>
              <FaEye />
            </Button>
          </Cannot>
          <Can permission="@users/edit">
            <Button
              onClick={() => handleRoleForm(record)}
              size="small"
              title={i18next.t('messages:editPermissions')}
              style={{ marginLeft: 5 }}
            >
              <FaUserLock />
            </Button>
          </Can>
          <Can permission="@users/delete">
            <Popconfirm
              icon={false}
              title={i18next.t('messages:confirmDelete')}
              onConfirm={() => handleDelete(record.id)}
              okText={i18next.t('messages:yes')}
              cancelText={i18next.t('messages:no')}
            >
              <Button size="small" title={i18next.t('messages:delete')}>
                <FaTrash />
              </Button>
            </Popconfirm>
          </Can>
        </TableActions>
      ),
    },
  ];
};
