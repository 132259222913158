import { message, Modal, Spin, Card, Button } from 'antd';
import { FieldArray, Formik } from 'formik';
import { FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import { Input } from 'formik-antd';
import React, { useEffect, useState } from 'react';
import { createStockWriteOffs, updateStockWriteOffs } from '~/services/hooks/stockWriteOffs';
import { useTranslation } from 'react-i18next';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import { SelectAntd } from '~/components/Pagination/styles';
import Row from '~/components/Row';
import { getItems } from '~/services/hooks/items';
import { useCheckAuth } from '~/components/Can/checkAuth';

const initial = {
  justification: null,
  stock_write_off_items: [],
};

export default function StockWriteOffsModal({ visible, onClose, stockWriteOffsEdit, isMobile }) {
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const [loading, setLoading] = useState(false);
  const [fetchedItems, setFetchedItems] = useState([]);
  const [initialValues, setInitialValues] = useState(initial);

  const fetchItems = async () => {
    setLoading(true);
    try {
      const response = await getItems('', 'no-paginated');
      setFetchedItems(response);
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao carregar os items. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const handleSave = async (values) => {
    setLoading(true);
    try {
      if (stockWriteOffsEdit) {
        await updateStockWriteOffs(values.id, values);
        message.success(t('atualizado com sucesso!'));
      } else {
        await createStockWriteOffs(values);
        message.success(t('criado com sucesso!'));
      }
      onClose();
    } catch (error) {
      console.log(error);
      message.error(t('Erro ao salvar a baixa. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const fluidPermissions = () => {
    if (stockWriteOffsEdit) {
      return authCheck('@lowStock/edit');
    } else {
      return authCheck('@lowStock/create');
    }
  };

  useEffect(() => {
    if (visible) {
      if (stockWriteOffsEdit) {
        setInitialValues(stockWriteOffsEdit);
      }
      fetchItems();
    }
  }, [visible]);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSave}
    >
      {({ isSubmitting, submitForm, values, setFieldValue }) => (
        <Modal
          title={stockWriteOffsEdit ? t('Editando baixa - ') + stockWriteOffsEdit.id : t('Criando baixa')}
          onCancel={onClose}
          style={{ maxWidth: isMobile ? '100%' : '1500px' }}
          afterClose={() => setInitialValues(initial)}
          open={visible}
          centered
          width={isMobile ? '100%' : '1200px'}
          footer={<ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} />}
        >
          <Spin spinning={loading}>
            <Card>
              <Row style={{ marginBottom: '10px' }}>
                <FormControl cols={{ xs: 24 }} field="justification" label={t('fields:stockWriteOffs.justification')}>
                  <Input.TextArea name="justification" rows={4} disabled={!fluidPermissions()} />
                </FormControl>
              </Row>

              <FieldArray
                name="stock_write_off_items"
                render={(arrayHelpers) => (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                      }}
                    >
                      {values.stock_write_off_items?.map((stockItem, index) => (
                        <Card
                          key={index}
                          style={{ marginBottom: '16px', width: isMobile ? '100%' : '49%' }}
                          title={stockItem.item_name ?? ''}
                          extra={
                            <Button
                              color="danger"
                              title="Excluir"
                              onClick={() => arrayHelpers.remove(index)}
                              style={{
                                padding: '5px 10px',
                                borderRadius: '4px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              disabled={!fluidPermissions()}
                            >
                              <FaRegTimesCircle color="red" />
                            </Button>
                          }
                        >
                          <Row>
                            <FormControl
                              cols={{ sm: 18, xs: 24 }}
                              field={`stock_write_off_items.${index}.item_id`}
                              label={t('fields:stockWriteOffs.typeProduct')}
                            >
                              <SelectAntd
                                style={{ width: '100%' }}
                                placeholder={t('fields:stockWriteOffs.selectProduct')}
                                value={stockItem.item_id}
                                onSelect={(value, options) => {
                                  setFieldValue(`stock_write_off_items.${index}.item_id`, value);
                                  setFieldValue(`stock_write_off_items.${index}.item_name`, options.children);
                                }}
                                loading={loading}
                                disabled={!fluidPermissions()}
                              >
                                <SelectAntd.Option value="">{t('screens:default.select')}</SelectAntd.Option>
                                {Array.isArray(fetchedItems) &&
                                  fetchedItems.map((item) => (
                                    <SelectAntd.Option key={item.id} value={item.id}>
                                      {item.name}
                                    </SelectAntd.Option>
                                  ))}
                              </SelectAntd>
                            </FormControl>

                            <FormControl
                              cols={{ sm: 6, xs: 24 }}
                              field={`stock_write_off_items.${index}.amount`}
                              label={t('fields:stockWriteOffs.amount')}
                            >
                              <Input
                                name={`stock_write_off_items.${index}.amount`}
                                type="number"
                                disabled={!fluidPermissions()}
                              />
                            </FormControl>
                          </Row>
                        </Card>
                      ))}
                    </div>
                    <Row style={{ marginTop: '16px', marginLeft: 0 }}>
                      <Button
                        color="green"
                        onClick={() =>
                          arrayHelpers.push({
                            item_id: null,
                            item_name: null,
                            amount: null,
                          })
                        }
                        disabled={!fluidPermissions()}
                      >
                        <FaPlusCircle color="green" />
                      </Button>
                    </Row>
                  </>
                )}
              />
            </Card>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
