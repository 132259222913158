import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Modal, message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
// import { useDispatch } from 'react-redux';
import { useCheckAuth } from '~/components/Can/checkAuth';
import { createUsers, updateUsers } from '~/services/hooks/users';
import { getMe } from '~/services/hooks/me';

const initial = {
  name: null,
  email: null,
  password: null,
  phone: null,
};

export default function UserForm({ visible, onClose, user, me = false, isMobile }) {
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const authCheck = useCheckAuth();
  const [initialValues, setInitialValues] = useState(initial);
  const [loading, setLoading] = useState(false);

  const fetchMe = async () => {
    setLoading(true);
    try {
      const data = await getMe('');
      setInitialValues(data);
    } catch (error) {
      console.log(error);
      message.error(t('Não foi possível carregar o usuário. Erro: ') + error.message);
    }
    setLoading(false);
  };

  const handleSave = async (values) => {
    setLoading(true);
    try {
      if (values.id) {
        await updateUsers();
      } else {
        await createUsers(values);
      }
      onClose();
    } catch (error) {
      console.log(error);
      message.error(`Não foi possível salvar o usuário. Erro: ${error.message}`);
    }
    setLoading(false);
  };

  const fluidPermissions = () => {
    if (user) {
      return authCheck('@users/edit');
    } else {
      return authCheck('@usuers/create');
    }
  };

  const userSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    password: Yup.string().when('id', { is: null, then: Yup.string().required() }),
    phone: Yup.string().required(),
  });

  useEffect(() => {
    if (visible) {
      if (user) {
        setInitialValues(user);
      } else if (me) {
        fetchMe();
      } else {
        setInitialValues(initialValues);
      }
    }
  }, [visible]);

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSave} validationSchema={userSchema}>
      {({ values, errors, isSubmitting, submitForm, touched }) => (
        <Modal
          width={isMobile ? '100%' : '580px'}
          title={t(user ? 'Editando usuário - ' : 'Criando usuário - ') + values.name}
          onCancel={onClose}
          afterClose={() => setInitialValues(initial)}
          open={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <FormControl
                  cols={{ xs: 12 }}
                  field="name"
                  label={t('Nome')}
                  required
                  error={(touched.name && errors.name) || errors.name}
                >
                  <Input name="name" disabled={!fluidPermissions()} />
                </FormControl>
                <FormControl
                  cols={{ xs: 12 }}
                  field="email"
                  label={t('Email')}
                  required
                  error={(touched.email && errors.email) || errors.email}
                >
                  <Input type="email" name="email" disabled={!fluidPermissions()} />
                </FormControl>
              </Row>
              <Row>
                <FormControl
                  cols={{ xs: 12 }}
                  field="phone"
                  label={t('Telefone')}
                  required
                  error={(touched.password && errors.password) || errors.password}
                >
                  <Input name="password" type="password" disabled={!fluidPermissions()} />
                </FormControl>
                <FormControl
                  cols={{ xs: 12 }}
                  field="password"
                  label={t('Senha')}
                  required
                  error={(touched.password && errors.password) || errors.password}
                >
                  <Input name="password" type="password" disabled={!fluidPermissions()} />
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
